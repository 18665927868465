import moment from "moment";
import { atom } from "recoil";
import { msalInstance } from "../../..";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  RetrieveRecord,
  isRAFMaxDate,
  isRAFMinDate,
} from "../../../RAFComponents/helpers/AppHelper";
import { removeFromFocusList } from "../../../RAFComponents/helpers/RAFMenuHelper";
import {
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  getDate,
  getSaveRequest,
  getTimeDifferenceInMinutes,
  getUTCDateValue,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf,
  setZeroHours,
} from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import {
  Constants,
  ContentType,
  RAFActionStatus,
  RAFTaskType,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { RAFActivityAction } from "../Activity/ActivityHelper";
import { RAFActivityVisibility } from "../Activity/ActivityRow";
import { getTaskRemindersRow } from "../Reminder/ReminderHelper";
import {
  ConvertTaskRowToITaskReminderRow,
  generateTaskReminderRow,
} from "../Reminder/ReminderMappingRegister";
import { ReminderRow } from "../Reminder/ReminderRow";
import { TaskReminderRow } from "../Reminder/TaskReminderRow";
import {
  ConvertTaskDtoToTaskRow,
  ConvertTaskRowToTaskDto,
} from "./TaskMappingRegister";
import {
  ITaskDto,
  MyTaskCount,
  MyTaskOptions,
  TaskDropdownItems,
  TaskFilterValue,
  TaskRow,
} from "./TaskRow";

export const selectedTaskView = atom({
  key: "selectedTaskView", // unique ID (with respect to other atoms/selectors)
  default: `${TaskDropdownItems.MyTask}`, // default value (aka initial value)
});

const myTaskFilteredValueDefalutValue: string[] = [TaskFilterValue.All];
export const myTaskFilteredValue = atom({
  key: "myTaskFilteredValue", // unique ID (with respect to other atoms/selectors)
  default: myTaskFilteredValueDefalutValue, // default value (aka initial value)
});

export const completedTaskFilteredValue = atom({
  key: "completedTaskFilteredValue", // unique ID (with respect to other atoms/selectors)
  default: `${TaskFilterValue.CompletedByMe}`, // default value (aka initial value)
});

export function getTaskDueDate(
  taskRow: TaskRow | ITaskDto,
  taskDateValue?: Date
): string {
  let taskDueDate = "Custom";
  let taskRowDateValue = isNotNullAndUndefined(taskRow)
    ? taskRow.TaskDate
    : taskDateValue;
  if (isNotNullAndUndefined(taskRowDateValue)) {
    let dateValue = convertUTCDateToLocalTimezone(taskRowDateValue);
    let taskDate = moment(dateValue).format("DD/MM/YYYY");

    let today = setZeroHours(moment().toDate());
    if (isRAFMaxDate(dateValue, taskRowDateValue)) {
      taskDueDate = "NoDate";
    } else if (isRAFMinDate(dateValue, taskRowDateValue)) {
      taskDueDate = "ASAP";
    } else if (moment(today).format("DD/MM/YYYY") === taskDate) {
      taskDueDate = "0";
    } else if (moment(today).add(1, "days").format("DD/MM/YYYY") === taskDate) {
      taskDueDate = "1";
    } else if (moment(today).add(3, "days").format("DD/MM/YYYY") === taskDate) {
      taskDueDate = "3";
    } else if (moment(today).add(7, "days").format("DD/MM/YYYY") === taskDate) {
      taskDueDate = "7";
    } else if (
      moment(today).add(15, "days").format("DD/MM/YYYY") === taskDate
    ) {
      taskDueDate = "15";
    } else if (
      moment(today).add(30, "days").format("DD/MM/YYYY") === taskDate
    ) {
      taskDueDate = "30";
    } else if (
      moment(today).add(60, "days").format("DD/MM/YYYY") === taskDate
    ) {
      taskDueDate = "60";
    } else if (
      moment(today).add(90, "days").format("DD/MM/YYYY") === taskDate
    ) {
      taskDueDate = "90";
    } else if (
      moment(today).add(180, "days").format("DD/MM/YYYY") === taskDate
    ) {
      taskDueDate = "180";
    } else if (
      moment(today).add(365, "days").format("DD/MM/YYYY") === taskDate
    ) {
      taskDueDate = "365";
    }
  } else {
    taskDueDate = "NoDate";
  }

  return taskDueDate;
}

export function getTaskDueDateDisplayText(taskDueDate?: string): string {
  let text = "Custom";
  if (taskDueDate === "NoDate") {
    text = "Unplanned";
  }
  if (taskDueDate === "ASAP") {
    text = "ASAP";
  } else if (taskDueDate === "0") {
    text = "Today";
  } else if (taskDueDate === "1") {
    text = "Tomorrow";
  } else if (taskDueDate === "3") {
    text = "+3 days";
  } else if (taskDueDate === "7") {
    text = "+7 days";
  } else if (taskDueDate === "15") {
    text = "+15 days";
  } else if (taskDueDate === "30") {
    text = "+1 month";
  } else if (taskDueDate === "60") {
    text = "+2 months";
  } else if (taskDueDate === "90") {
    text = "+3 months";
  } else if (taskDueDate === "180") {
    text = "+6 months";
  }

  return text;
}

export function saveTaskWithReminders(taskRow: TaskRow) {
  return new Promise<string>((resolve) => {
    if (isNotNullAndUndefined(taskRow)) {
      let taskToSubmit: TaskRow;

      if (
        isNotNullAndUndefined(taskRow.Reminders) &&
        taskRow.Reminders.length > 0
      ) {
        let taskToCreateDto: TaskReminderRow = generateTaskReminderRow(taskRow);
        taskToCreateDto = ConvertTaskRowToITaskReminderRow(taskToCreateDto);
        taskToSubmit = taskToCreateDto;
      } else {
        let taskToCreateDto: ITaskDto = ConvertTaskRowToTaskDto(taskRow);
        taskToSubmit = taskToCreateDto;
      }

      if (isNullOrUndefined(taskToSubmit.IsApprovalTask)) {
        taskToSubmit.IsApprovalTask = false;
      }

      if (
        isNullOrUndefined(taskRow.TaskDate) &&
        taskRow.TaskType !== RAFTaskType.Note
      ) {
        taskToSubmit.TaskDate = Constants.MaxDate;
      }

      if (
        taskToSubmit.RelatedToUID === null ||
        taskToSubmit.RelatedToType === null
      ) {
        taskToSubmit.RelatedToType = null;
        taskToSubmit.RelatedToUID = null;
        taskToSubmit.RelatedTo = null;
      }

      if (
        isNotNullAndUndefined(taskToSubmit.Priority) &&
        taskToSubmit.Priority === "Normal"
      ) {
        taskToSubmit.Priority = null;
      }

      const duration =
        taskToSubmit.TaskType === RAFTaskType.Note
          ? taskToSubmit.Duration
          : getTimeDifferenceInMinutes(
            getUTCDateValue(taskToSubmit.TaskDate),
            getUTCDateValue(taskToSubmit.EndDate)
          );
      if (
        IsNotNullOrWhiteSpace(duration) &&
        duration > 0 &&
        duration < 2111111111
      ) {
        //2111111111 is max value of duration in db
        taskToSubmit.Duration = duration;
      } else {
        taskToSubmit.Duration = null;
      }

      if (isNullOrUndefined(taskToSubmit.Visibility)) {
        taskToSubmit.Visibility = RAFActivityVisibility.Public;
      }

      if (taskToSubmit.TaskType === RAFTaskType.ToDo) {
        taskToSubmit.EndDate = null;
        taskToSubmit.Duration = null;
        taskToSubmit.SubType = null;
      } else if (taskToSubmit.TaskType === RAFTaskType.Appointment) {
        taskToSubmit.SubType = null;
      } else if (taskToSubmit.TaskType === RAFTaskType.Action) {
        taskToSubmit.EndDate = null;
        taskToSubmit.Duration = null;
      } else if (taskToSubmit.TaskType === RAFTaskType.Call) {
        taskToSubmit.TaskStatus = RAFActionStatus.Completed;
      } else if (taskToSubmit.TaskType === RAFTaskType.Note) {
        taskToSubmit.SubType = null;
        taskToSubmit.TaskDate =
          isNotNullAndUndefined(taskToSubmit.UID) &&
            isNotNullAndUndefined(taskToSubmit.TaskDate)
            ? taskToSubmit.TaskDate
            : new Date();
        taskToSubmit.EndDate = null;
        taskToSubmit.TaskStatus = RAFActionStatus.Completed;
        taskToSubmit.Action = isNotNullAndUndefined(taskToSubmit.UID)
          ? RAFActivityAction.Modified
          : RAFActivityAction.Added;
        taskToSubmit.AssignTeam = null;
        taskToSubmit.AssignTeamUID = null;
        taskToSubmit.Assignee = isNotNullAndUndefined(taskToSubmit.Assignee)
          ? taskToSubmit.Assignee
          : msalInstance.currentUserName;
        taskToSubmit.AssigneeUID = isNotNullAndUndefined(
          taskToSubmit.AssigneeUID
        )
          ? taskToSubmit.AssigneeUID
          : msalInstance.currentUserId;
      }

      if (
        taskToSubmit.TaskType === RAFTaskType.ToDo ||
        taskToSubmit.TaskType === RAFTaskType.Appointment ||
        taskToSubmit.TaskType === RAFTaskType.Action
      ) {
        if (
          IsNullOrWhiteSpace(taskToSubmit.AssigneeUID) &&
          IsNullOrWhiteSpace(taskToSubmit.AssignTeamUID)
        ) {
          taskToSubmit.TaskStatus = RAFActionStatus.Unassigned;
        } else if (
          taskToSubmit.TaskStatus === RAFActionStatus.Unassigned &&
          (!IsNullOrWhiteSpace(taskToSubmit.AssigneeUID) ||
            !IsNullOrWhiteSpace(taskToSubmit.AssignTeamUID))
        ) {
          taskToSubmit.TaskStatus = RAFActionStatus.Planned;
        }
      }

      repositoryActions
        .postDataAndGetResponse(
          "Task/SaveTaskWithReminders",
          getSaveRequest(taskToSubmit, taskToSubmit.UID),
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            resolve(response.data.EntityId);
          } else {
            resolve(null);
          }
        })
        .catch((error) => resolve(null));
    } else {
      resolve(null);
    }
  });
}

export const getTaskReminderListByTaskUID = (listServiceRequest: ListServiceRequest) => {
  return new Promise<TaskReminderRow[]>((resolve) => {
    if (isNotNullAndUndefined(listServiceRequest)) {
      repositoryActions
        .postDataAndGetResponse(
          "Reminder/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((reminderResponse) => {
          const reminderList: ReminderRow[] =
            isNotNullAndUndefined(reminderResponse) &&
              isNotNullAndUndefined(reminderResponse.data) &&
              isNotNullAndUndefined(reminderResponse.data.Entities)
              ? reminderResponse.data.Entities
              : [];
          resolve(reminderList);
        });
    } else {
      resolve(null);
    }
  });
};

export function retrieveTaskWithReminders(taskUID: string) {
  return new Promise<TaskRow>(async (resolve) => {
    if (IsNotNullOrWhiteSpace(taskUID)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push(taskUID);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = propertyOf<ReminderRow>("RelatedToUID");
      relatedFilter.Rules.push(filter);

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      const [responseEntity, reminderList] = await Promise.all([
        RetrieveRecord(taskUID, RAFEntityName.Task),
        getTaskReminderListByTaskUID(listServiceRequest)
      ]);

      if (
        isNotNullAndUndefined(responseEntity) &&
        isNotNullAndUndefined(responseEntity.UID)
      ) {
        const taskRow: TaskRow =
          ConvertTaskDtoToTaskRow(responseEntity);
        taskRow.Reminders = getTaskRemindersRow(reminderList);
        if (isNullOrUndefined(taskRow.Priority)) {
          taskRow.Priority = "Normal";
        }
        if (isNullOrUndefined(taskRow.HasMentions)) {
          taskRow.HasMentions = true;
        }
        if (isNullOrUndefined(taskRow.Visibility)) {
          taskRow.Visibility = RAFActivityVisibility.Public;
        }

        if (taskRow.TaskType === RAFTaskType.Note) {
          if (isNullOrUndefined(taskRow.SortOrder)) {
            taskRow.SortOrder = 99;
          }
          if (isNullOrUndefined(taskRow.ColourCode)) {
            taskRow.ColourCode = "#FFF9B1";
          }
        }
        resolve(taskRow);
      } else {
        resolve(null);
      }
    } else {
      resolve(null);
    }
  });
}

export function retrieveTaskWithoutReminders(taskUID: string) {
  return new Promise<TaskRow>((resolve) => {
    if (IsNotNullOrWhiteSpace(taskUID)) {
      RetrieveRecord(taskUID, RAFEntityName.Task).then((response: TaskRow) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.UID)
        ) {
          const taskRow: TaskRow = ConvertTaskDtoToTaskRow(response);
          if (isNotNullAndUndefined(taskRow) && taskRow.Priority === null)
            taskRow.Priority = "Normal";
          resolve(taskRow);
        } else {
          resolve(null);
        }
      });
    } else {
      resolve(null);
    }
  });
}

export function completeTaskAndRemoveFromFocusList(taskRow: TaskRow) {
  return new Promise<boolean>((resolve, reject) => {
    if (isNotNullAndUndefined(taskRow) && isNotNullAndUndefined(taskRow.UID)) {
      taskRow.CompletedDate = new Date();
      taskRow.CompletedBy = msalInstance.currentUserName;
      taskRow.CompletedByUID = msalInstance.currentUserId;

      repositoryActions
        .postDataAndGetResponse(
          "Task/Complete",
          taskRow,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response) && response.data === "Success") {
            removeFromFocusList(taskRow.UID).then((focusListResponse) => {
              showSuccessToast("Successfully  Completed");
              resolve(true);
            });
          } else {
            showWarningToast("Sorry something went wrong !");
            resolve(false);
          }
        })
        .catch((error) => error);
    } else {
      showWarningToast("Sorry something went wrong !");
      resolve(false);
    }
  });
}

export function completeTask(taskRow: TaskRow) {
  return new Promise<boolean>((resolve, reject) => {
    if (isNotNullAndUndefined(taskRow) && isNotNullAndUndefined(taskRow.UID)) {
      taskRow.CompletedDate = new Date();
      taskRow.CompletedBy = msalInstance.currentUserName;
      taskRow.CompletedByUID = msalInstance.currentUserId;

      repositoryActions
        .postDataAndGetResponse(
          "Task/Complete",
          taskRow,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response) && response.data === "Success") {
            showSuccessToast("Successfully  Completed");
            resolve(true);
          } else {
            showWarningToast("Sorry something went wrong !");
            resolve(false);
          }
        })
        .catch((error) => error);
    } else {
      showWarningToast("Sorry something went wrong !");
      resolve(false);
    }
  });
}

export const getRelatedNextActionTask = (
  relatedToUID: string,
  taskSummaryData: MyTaskCount
) => {
  return new Promise<TaskRow>(async (resolve, reject) => {
    const upComingCount = isNotNullAndUndefined(taskSummaryData)
      ? taskSummaryData.Upcoming
      : 0;

    const todayTaskCount =
      isNotNullAndUndefined(taskSummaryData) &&
        isNotNullAndUndefined(taskSummaryData.Today)
        ? taskSummaryData.Today
        : 0;

    const todaysTask =
      todayTaskCount > 0
        ? await getRelatedMyTaskbyUITab(relatedToUID, [MyTaskOptions.Today])
        : null;
    const upCommingTask =
      upComingCount > 0
        ? await getRelatedMyTaskbyUITab(relatedToUID, [MyTaskOptions.Upcoming])
        : null;

    if (isNotEmptyArray(todaysTask)) {
      const todaysTaskRow = isNotNullAndUndefined(todaysTask[0])
        ? todaysTask[0]
        : null;
      resolve(todaysTaskRow);
    } else if (isNotEmptyArray(upCommingTask)) {
      const upCommingTaskRow = isNotNullAndUndefined(upCommingTask[0])
        ? upCommingTask[0]
        : null;
      resolve(upCommingTaskRow);
    } else {
      resolve(null);
    }
  });
};

export const getRelatedMyTaskbyUITab = (
  relatedToUID?: string,
  UITabs?: string[],
  secondaryRelatedToUID?: string
) => {
  return new Promise<TaskRow[]>((resolve) => {
    if (isNotNullAndUndefined(relatedToUID) && isNotEmptyArray(UITabs)) {
      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];

      let relatedToFilter: RAFCustomFilter = {};
      let relatedToFilterVal: string[] = [];

      relatedToFilterVal.push(relatedToUID);
      relatedToFilter.Operator = RAFCustomOperator.Equal;
      relatedToFilter.Value = relatedToFilterVal;
      relatedToFilter.Field = propertyOf<TaskRow>("RelatedToUID");
      customFilter.Rules.push(relatedToFilter);

      if (isNotNullAndUndefined(secondaryRelatedToUID)) {
        let secondaryRelatedToFilter: RAFCustomFilter = {};
        let secondaryRelatedToFilterVal: string[] = [];

        secondaryRelatedToFilterVal.push(secondaryRelatedToUID);
        secondaryRelatedToFilter.Operator = RAFCustomOperator.Equal;
        secondaryRelatedToFilter.Value = secondaryRelatedToFilterVal;
        secondaryRelatedToFilter.Field = propertyOf<TaskRow>(
          "SecondaryRelatedToUID"
        );
        customFilter.Rules.push(secondaryRelatedToFilter);
      }

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = customFilter;
      listServiceRequest.Take = 10;
      listServiceRequest.Skip = 0;
      listServiceRequest["UITabs"] = UITabs;

      repositoryActions
        .postDataAndGetResponse(
          "Task/AllTasks",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entities)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
};

export const getRequiredIncompletedTasksbyTaskParentID = (
  parentUID: string
) => {
  return new Promise<[]>((resolve, reject) => {
    if (IsNotNullOrWhiteSpace(parentUID)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push(parentUID);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = propertyOf<TaskRow>("ParentUID");
      relatedFilter.Rules.push(filter);

      let taskStatusFilter: RAFCustomFilter = {};
      let taskStatusFilterVal: string[] = [];
      taskStatusFilterVal.push(RAFActionStatus.Completed);
      taskStatusFilter.Operator = RAFCustomOperator.NotEqual;
      taskStatusFilter.Value = taskStatusFilterVal;
      taskStatusFilter.Field = propertyOf<TaskRow>("TaskStatus");
      relatedFilter.Rules.push(taskStatusFilter);

      let isRequiredFilter: RAFCustomFilter = {};
      let isRequiredFilterVal: string[] = [];
      isRequiredFilterVal.push("true");
      isRequiredFilter.Operator = RAFCustomOperator.Equal;
      isRequiredFilter.Value = isRequiredFilterVal;
      isRequiredFilter.Field = propertyOf<TaskRow>("IsRequired");
      relatedFilter.Rules.push(isRequiredFilter);

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      repositoryActions
        .postDataAndGetResponse(
          "Task/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entities)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export const getTaskbyTaskParentID = (parentUID: string) => {
  return new Promise<[]>((resolve, reject) => {
    if (IsNotNullOrWhiteSpace(parentUID)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push(parentUID);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = propertyOf<TaskRow>("ParentUID");
      relatedFilter.Rules.push(filter);
      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      repositoryActions
        .postDataAndGetResponse(
          "Task/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entities)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export const getTaskDisplayDateAndBadge = (
  taskDate: Date,
  taskType: string,
  badgeStyle?: boolean
): {
  taskDate: string;
  taskDueDate: string;
  dateBadge: string;
  taskTextColor: string;
} => {
  let taskDateValue: string;
  let dateBadgeValue: string;
  let taskDueDateValue: string;
  let taskTextColor: string;

  if (taskDate != null) {
    const objTaskDate: Date = convertUTCDateToLocalTimezone(taskDate);

    if (isRAFMaxDate(taskDate, objTaskDate)) {
      dateBadgeValue = badgeStyle
        ? "raf_badge_neutral"
        : "grey lighten-1 badge-grey";
      taskDateValue = "Unplanned";
      taskDueDateValue = "Unplanned";
      taskTextColor = "neutral";
    } else if (isRAFMinDate(taskDate, objTaskDate)) {
      dateBadgeValue = badgeStyle ? "raf_badge_danger" : "danger badge-danger";
      taskDateValue = "ASAP";
      taskDueDateValue = "ASAP";
      taskTextColor = "state_danger";
    } else {
      //taskDateValue = getDate(objTaskDate, "DD MMM YYYY");
      taskDateValue = getDate(objTaskDate, "MMM DD");
      if (taskType === RAFTaskType.Appointment) {
        taskDueDateValue = moment(new Date(taskDate))
          .format("DD/MM/YYYY  h:mm a")
          .toString();
      } else {
        taskDueDateValue = moment(new Date(taskDate))
          .format("DD/MM/YYYY")
          .toString();
      }
      if (moment(objTaskDate).isAfter(new Date(), "day")) {
        dateBadgeValue = badgeStyle
          ? "raf_badge_primary"
          : "badge-info lighten-1";
        taskTextColor = "brand_primary";
      } else if (moment(objTaskDate).isBefore(new Date(), "day")) {
        dateBadgeValue = badgeStyle
          ? "raf_badge_danger"
          : "danger badge-danger";
        taskTextColor = "state_danger";
      } else if (moment(objTaskDate).isSame(new Date(), "day")) {
        dateBadgeValue = badgeStyle
          ? "raf_badge_warning"
          : "orange lighten-1 badge-orange";
        taskTextColor = "state_warning";
      }
    }
  } else {
    dateBadgeValue = badgeStyle
      ? "raf_badge_neutral"
      : "grey lighten-1 badge-grey";
    //taskDate = 'Queued';
    taskDateValue = "Unplanned";
    taskDueDateValue = "Unplanned";
    taskTextColor = "neutral";
  }

  let dateAndBadge: {
    taskDate: string;
    taskDueDate: string;
    dateBadge: string;
    taskTextColor: string;
  } = {
    taskDate: taskDateValue,
    taskDueDate: taskDueDateValue,
    dateBadge: dateBadgeValue,
    taskTextColor: taskTextColor,
  };

  return dateAndBadge;
};

export const getMyTaskCount = (relatedToUID: string) => {
  return new Promise<MyTaskCount>((resolve /*, reject*/) => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];
    let relatedToFilter: RAFCustomFilter = {};
    let relatedToFilterVal: string[] = [];

    if (isNotNullAndUndefined(relatedToUID)) {
      relatedToFilterVal.push(relatedToUID);
      relatedToFilter.Operator = RAFCustomOperator.Equal;
      relatedToFilter.Value = relatedToFilterVal;
      relatedToFilter.Field = propertyOf<TaskRow>("RelatedToUID");
      customFilter.Rules.push(relatedToFilter);
    }
    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = customFilter;
    repositoryActions
      .postDataAndGetResponse(
        "Task/Summary",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entity)
        ) {
          resolve(response.data.Entity);
        } else {
          resolve(new MyTaskCount());
        }
      });
  });
};

export const getMyTask = (Take) => {
  return new Promise<TaskRow[]>((resolve) => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    let filterParentID: RAFCustomFilter = {};
    filterParentID.Operator = RAFCustomOperator.IsNull;
    filterParentID.Field = propertyOf<TaskRow>("ParentUID");
    customFilter.Rules.push(filterParentID);

    //const taskTypes = [RAFTaskType.Appointment, RAFTaskType.Event];
    const taskTypes = [RAFTaskType.Event];
    let customTaskFilter: RAFCustomFilter = {};
    customTaskFilter.Condition = "or";
    customTaskFilter.Rules = [];
    taskTypes.forEach((taskType) => {
      let taskTypeFilter: RAFCustomFilter = {};
      let taskTypeFilterVal: string[] = [];
      taskTypeFilterVal.push(taskType);
      taskTypeFilter.Operator = RAFCustomOperator.NotEqual;
      taskTypeFilter.Value = taskTypeFilterVal;
      taskTypeFilter.Field = propertyOf<TaskRow>("TaskType");
      customFilter.Rules.push(taskTypeFilter);
    });
    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = customFilter;
    listServiceRequest.Skip = 0;
    let additionalParams = [
      {
        key: "UITabs",
        value: [MyTaskOptions.All],
      },
    ];
    additionalParams.forEach((objProp) => {
      listServiceRequest[objProp.key] = objProp.value;
    });
    repositoryActions
      .postDataAndGetResponse(
        "Task/MyTasks",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotEmptyArray(response.data.Entities)
        ) {
          let responseValue = response;
          resolve(responseValue.data.Entities);
        } else {
          resolve(null);
        }
      });
  });
};

export const getRelatedTaskCount = (
  relatedToUID?: string,
  secondaryRelatedToUID?: string
) => {
  return new Promise<MyTaskCount>((resolve /*, reject*/) => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    if (isNotNullAndUndefined(relatedToUID)) {
      let relatedToFilter: RAFCustomFilter = {};
      let relatedToFilterVal: string[] = [];
      relatedToFilterVal.push(relatedToUID);
      relatedToFilter.Operator = RAFCustomOperator.Equal;
      relatedToFilter.Value = relatedToFilterVal;
      relatedToFilter.Field = propertyOf<TaskRow>("RelatedToUID");
      customFilter.Rules.push(relatedToFilter);
    }

    if (isNotNullAndUndefined(secondaryRelatedToUID)) {
      let secondaryRelatedToFilter: RAFCustomFilter = {};
      let secondaryRelatedToFilterVal: string[] = [];
      secondaryRelatedToFilterVal.push(secondaryRelatedToUID);
      secondaryRelatedToFilter.Operator = RAFCustomOperator.Equal;
      secondaryRelatedToFilter.Value = secondaryRelatedToFilterVal;
      secondaryRelatedToFilter.Field = propertyOf<TaskRow>(
        "SecondaryRelatedToUID"
      );
      customFilter.Rules.push(secondaryRelatedToFilter);
    }

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = customFilter;
    repositoryActions
      .postDataAndGetResponse(
        "Task/AllSummary",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entity)
        ) {
          resolve(response.data.Entity);
        } else {
          resolve(new MyTaskCount());
        }
      });
  });
};

export const getPlannedShiftTasksByRelatedToUID = (relatedToUID: string) => {
  return new Promise<TaskRow[]>((resolve) => {
    if (isNotNullAndUndefined(relatedToUID)) {
      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];

      let relatedToFilter: RAFCustomFilter = {};
      let relatedToFilterVal: string[] = [];

      relatedToFilterVal.push(relatedToUID);
      relatedToFilter.Operator = RAFCustomOperator.Equal;
      relatedToFilter.Value = relatedToFilterVal;
      relatedToFilter.Field = propertyOf<TaskRow>("RelatedToUID");
      customFilter.Rules.push(relatedToFilter);

      let relatedToFilter1: RAFCustomFilter = {};
      relatedToFilter1.Operator = RAFCustomOperator.IsNull;
      relatedToFilter1.Field = propertyOf<TaskRow>("SecondaryRelatedToUID");
      customFilter.Rules.push(relatedToFilter1);

      const taskTypes = [RAFTaskType.ToDo, RAFTaskType.Appointment];

      let customTaskTypeFilter: RAFCustomFilter = {};
      customTaskTypeFilter.Condition = "or";
      customTaskTypeFilter.Rules = [];

      taskTypes.forEach((taskType) => {
        let taskTypeFilter: RAFCustomFilter = {};
        let taskTypeFilterVal: string[] = [];
        taskTypeFilterVal.push(taskType);
        taskTypeFilter.Operator = RAFCustomOperator.Equal;
        taskTypeFilter.Value = taskTypeFilterVal;
        taskTypeFilter.Field = propertyOf<TaskRow>("TaskType");
        customTaskTypeFilter.Rules.push(taskTypeFilter);
      });
      customFilter.Rules.push(customTaskTypeFilter);

      let taskStatusFilter: RAFCustomFilter = {};
      let taskStatusFilterVal: string[] = [];
      taskStatusFilterVal.push(RAFActionStatus.Planned);
      taskStatusFilter.Operator = RAFCustomOperator.Equal;
      taskStatusFilter.Value = taskStatusFilterVal;
      taskStatusFilter.Field = propertyOf<TaskRow>("TaskStatus");
      customFilter.Rules.push(taskStatusFilter);

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = customFilter;
      listServiceRequest.Take = 10;
      listServiceRequest.Skip = 0;

      repositoryActions
        .postDataAndGetResponse(
          "Task/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entities)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
};

export const getAllRelatedTasks = (
  relatedToUID: string,
  secondaryRelatedToUID: string
) => {
  return new Promise<TaskRow[]>((resolve) => {
    if (isNotNullAndUndefined(relatedToUID)) {
      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];

      let relatedToFilter: RAFCustomFilter = {};
      let relatedToFilterVal: string[] = [];

      relatedToFilterVal.push(relatedToUID);
      relatedToFilter.Operator = RAFCustomOperator.Equal;
      relatedToFilter.Value = relatedToFilterVal;
      relatedToFilter.Field = propertyOf<TaskRow>("RelatedToUID");
      customFilter.Rules.push(relatedToFilter);

      if (isNotNullAndUndefined(secondaryRelatedToUID)) {
        let relatedToFilter1: RAFCustomFilter = {};
        let relatedToFilterVal1: string[] = [];

        relatedToFilterVal1.push(secondaryRelatedToUID);
        relatedToFilter1.Operator = RAFCustomOperator.Equal;
        relatedToFilter1.Value = relatedToFilterVal1;
        relatedToFilter1.Field = propertyOf<TaskRow>("SecondaryRelatedToUID");
        customFilter.Rules.push(relatedToFilter1);
      }

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = customFilter;
      listServiceRequest.Take = 10;
      listServiceRequest.Skip = 0;

      repositoryActions
        .postDataAndGetResponse(
          "Task/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entities)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
};

export const getAllRelatedTasksCount = (
  relatedToUID: string,
  secondaryRelatedToUID: string
) => {
  return new Promise<number>((resolve) => {
    if (isNotNullAndUndefined(relatedToUID)) {
      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];

      let relatedToFilter: RAFCustomFilter = {};
      let relatedToFilterVal: string[] = [];

      relatedToFilterVal.push(relatedToUID);
      relatedToFilter.Operator = RAFCustomOperator.Equal;
      relatedToFilter.Value = relatedToFilterVal;
      relatedToFilter.Field = propertyOf<TaskRow>("RelatedToUID");
      customFilter.Rules.push(relatedToFilter);

      if (isNotNullAndUndefined(secondaryRelatedToUID)) {
        let relatedToFilter1: RAFCustomFilter = {};
        let relatedToFilterVal1: string[] = [];

        relatedToFilterVal1.push(secondaryRelatedToUID);
        relatedToFilter1.Operator = RAFCustomOperator.Equal;
        relatedToFilter1.Value = relatedToFilterVal1;
        relatedToFilter1.Field = propertyOf<TaskRow>("SecondaryRelatedToUID");
        customFilter.Rules.push(relatedToFilter1);
      }

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = customFilter;
      listServiceRequest.Take = 10;
      listServiceRequest.Skip = 0;

      repositoryActions
        .postDataAndGetResponse(
          "Task/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entities)
          ) {
            let responseCount = response.data.Entities.length;
            resolve(responseCount);
          } else {
            resolve(0);
          }
        });
    } else {
      resolve(null);
    }
  });
};

export const getAllRelatedTasksByBPStepUID = (bpStepUID: string) => {
  return new Promise<TaskRow[]>((resolve) => {
    if (isNotNullAndUndefined(bpStepUID)) {
      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];

      let relatedToFilter: RAFCustomFilter = {};
      let relatedToFilterVal: string[] = [];

      relatedToFilterVal.push(bpStepUID);
      relatedToFilter.Operator = RAFCustomOperator.Equal;
      relatedToFilter.Value = relatedToFilterVal;
      relatedToFilter.Field = propertyOf<TaskRow>("BPStepUID");
      customFilter.Rules.push(relatedToFilter);

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = customFilter;
      listServiceRequest.Take = 10;
      listServiceRequest.Skip = 0;

      repositoryActions
        .postDataAndGetResponse(
          "Task/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entities)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
};

export function saveTaskListWithReminders(taskRows: TaskRow[]) {
  return new Promise<string>((resolve) => {
    let saveListRequest = [];

    if (isNotEmptyArray(taskRows)) {
      taskRows.forEach((taskRow) => {
        if (isNotNullAndUndefined(taskRow)) {
          let taskToSubmit: TaskRow;

          if (
            isNotNullAndUndefined(taskRow.Reminders) &&
            taskRow.Reminders.length > 0
          ) {
            let taskToCreateDto: TaskReminderRow =
              generateTaskReminderRow(taskRow);
            taskToCreateDto = ConvertTaskRowToITaskReminderRow(taskToCreateDto);
            taskToSubmit = taskToCreateDto;
          } else {
            let taskToCreateDto: ITaskDto = ConvertTaskRowToTaskDto(taskRow);
            taskToSubmit = taskToCreateDto;
          }

          if (isNullOrUndefined(taskToSubmit.IsApprovalTask)) {
            taskToSubmit.IsApprovalTask = false;
          }

          if (
            isNullOrUndefined(taskRow.TaskDate) &&
            taskRow.TaskType !== RAFTaskType.Note
          ) {
            taskToSubmit.TaskDate = Constants.MaxDate;
          }

          if (
            taskToSubmit.RelatedToUID === null ||
            taskToSubmit.RelatedToType === null
          ) {
            taskToSubmit.RelatedToType = null;
            taskToSubmit.RelatedToUID = null;
            taskToSubmit.RelatedTo = null;
          }

          if (
            isNotNullAndUndefined(taskToSubmit.Priority) &&
            taskToSubmit.Priority === "Normal"
          ) {
            taskToSubmit.Priority = null;
          }

          const duration =
            taskToSubmit.TaskType === RAFTaskType.Note
              ? taskToSubmit.Duration
              : getTimeDifferenceInMinutes(
                getUTCDateValue(taskToSubmit.TaskDate),
                getUTCDateValue(taskToSubmit.EndDate)
              );
          if (
            IsNotNullOrWhiteSpace(duration) &&
            duration > 0 &&
            duration < 2111111111
          ) {
            //2111111111 is max value of duration in db
            taskToSubmit.Duration = duration;
          } else {
            taskToSubmit.Duration = null;
          }

          if (isNullOrUndefined(taskToSubmit.Visibility)) {
            taskToSubmit.Visibility = RAFActivityVisibility.Public;
          }

          if (taskToSubmit.TaskType === RAFTaskType.ToDo) {
            taskToSubmit.EndDate = null;
            taskToSubmit.Duration = null;
            taskToSubmit.SubType = null;
          } else if (taskToSubmit.TaskType === RAFTaskType.Appointment) {
            taskToSubmit.SubType = null;
          } else if (taskToSubmit.TaskType === RAFTaskType.Action) {
            taskToSubmit.EndDate = null;
            taskToSubmit.Duration = null;
          } else if (taskToSubmit.TaskType === RAFTaskType.Call) {
            taskToSubmit.TaskStatus = RAFActionStatus.Completed;
          } else if (taskToSubmit.TaskType === RAFTaskType.Note) {
            taskToSubmit.SubType = null;
            taskToSubmit.TaskDate =
              isNotNullAndUndefined(taskToSubmit.UID) &&
                isNotNullAndUndefined(taskToSubmit.TaskDate)
                ? taskToSubmit.TaskDate
                : new Date();
            taskToSubmit.EndDate = null;
            taskToSubmit.TaskStatus = RAFActionStatus.Completed;
            taskToSubmit.Action = isNotNullAndUndefined(taskToSubmit.UID)
              ? RAFActivityAction.Modified
              : RAFActivityAction.Added;
            taskToSubmit.AssignTeam = null;
            taskToSubmit.AssignTeamUID = null;
            taskToSubmit.Assignee = isNotNullAndUndefined(taskToSubmit.Assignee)
              ? taskToSubmit.Assignee
              : msalInstance.currentUserName;
            taskToSubmit.AssigneeUID = isNotNullAndUndefined(
              taskToSubmit.AssigneeUID
            )
              ? taskToSubmit.AssigneeUID
              : msalInstance.currentUserId;
          }

          if (
            taskToSubmit.TaskType === RAFTaskType.ToDo ||
            taskToSubmit.TaskType === RAFTaskType.Appointment ||
            taskToSubmit.TaskType === RAFTaskType.Action
          ) {
            if (
              IsNullOrWhiteSpace(taskToSubmit.AssigneeUID) &&
              IsNullOrWhiteSpace(taskToSubmit.AssignTeamUID)
            ) {
              taskToSubmit.TaskStatus = RAFActionStatus.Unassigned;
            } else if (
              taskToSubmit.TaskStatus === RAFActionStatus.Unassigned &&
              (!IsNullOrWhiteSpace(taskToSubmit.AssigneeUID) ||
                !IsNullOrWhiteSpace(taskToSubmit.AssignTeamUID))
            ) {
              taskToSubmit.TaskStatus = RAFActionStatus.Planned;
            }
          }

          let entity = { Entity: taskToSubmit };
          saveListRequest.push(entity);
        }
      });

      repositoryActions
        .postDataAndGetResponse(
          "Task/SaveTaskListWithReminders",
          saveListRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            resolve(response.data.EntityId);
          } else {
            resolve(null);
          }
        })
        .catch((error) => resolve(null));
    } else {
      resolve(null);
    }
  });
}

export const getAllRelatedTasksByBPStep = (bpStep: string) => {
  return new Promise<TaskRow[]>((resolve) => {
    if (isNotNullAndUndefined(bpStep)) {
      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];

      let relatedToFilter: RAFCustomFilter = {};
      let relatedToFilterVal: string[] = [];

      relatedToFilterVal.push(bpStep);
      relatedToFilter.Operator = RAFCustomOperator.Equal;
      relatedToFilter.Value = relatedToFilterVal;
      relatedToFilter.Field = propertyOf<TaskRow>("BPStep");
      customFilter.Rules.push(relatedToFilter);

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = customFilter;
      listServiceRequest.Take = 10;
      listServiceRequest.Skip = 0;

      repositoryActions
        .postDataAndGetResponse(
          "Task/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entities)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
};
