import { DialogUtility } from "@syncfusion/ej2-popups";
import React, { PropsWithChildren, Reducer, useEffect, useReducer } from "react";
import RAFEmptyState from "../../../../../RAFComponents/Navigation/RAFEmptyState";
import { showSuccessToast } from "../../../../../RAFComponents/Utility/RAFToastComponent";
import { DeleteRecordRemoveFocus, getAllUsers, hideProgress, showProgress } from "../../../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../../../RAFComponents/helpers/PermissionHelper";
import { isNotEmptyArray, isNotNullAndUndefined, propertyOf } from "../../../../../RAFComponents/helpers/utils";
import { LookUpRow } from "../../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import { RAFTaskType } from "../../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../../../constants/Common/PermissionConstants";
import { getHeaderHeight } from "../../../../../helpers/ACutils";
import { getPlannerRelatedRecords } from "../../../../CareESIO/CareShiftLog/PlannerTask/PlannerLogHelper";
import { saveTaskWithReminders } from "../../TaskHelper";
import { TaskRow } from "../../TaskRow";
import TaskActivityView from "./TaskActivityView";

interface IProps {
    relatedToUID?: string;
    relatedType?: string;
    showRelatedTo?: boolean;
    showEmptyState?: boolean;
    showEmptyStateIcon?: boolean;
    displayStyle?: 'ImageOnly' | 'TextOnly' | 'Default';
    secondaryRelatedTo?: string;
    secondaryRelatedToUID?: string;
    secondaryRelatedToType?: string;

    parentRelatedToUID?: string;
    parentRelatedTo?: string;

    modulesOnclick?: (rowData: Object) => void;
    relatedCommentsUpdated?: (isRelatedCommentsUpdated?: boolean) => void;
    taskItemClassname?: string;
    excludeRelatedFilter?: boolean;
    uiMode?: "table";
    showSecondaryRelatedTo?: boolean;
}

interface IState {
    isLoading: boolean;
    taskListRow: TaskRow[];
    usersList: LookUpRow[];
    taskListRowItemsDivKey: number;
}

function TaskActivitySummary({ ...props }: PropsWithChildren<IProps>) {
    let deleteDialog: any;

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isLoading: false,
            taskListRow: [],
            usersList: [],
            taskListRowItemsDivKey: Math.random(),
        }
    );

    useEffect(() => {
        fetchData();
    }, [props.relatedToUID, props.secondaryRelatedToUID, props.parentRelatedToUID]);

    const fetchData = async () => {
        const {
            parentRelatedToUID,
            secondaryRelatedToUID,
            relatedToUID,
        } = props;

        let progressDiv = showProgress(".activitiesItems");

        const excludeRelatedFilter = isNotNullAndUndefined(
            props.excludeRelatedFilter
        )
            ? true
            : false;


        const sortQuery: string[] = [
            propertyOf<TaskRow>("Pinned"),
            propertyOf<TaskRow>("SortOrder"),
            `${propertyOf<TaskRow>("TaskDate")} desc`,
        ];

        const [
            taskItemsRow,
            usersList,
        ] = await Promise.all([getPlannerRelatedRecords(
            excludeRelatedFilter === true ? null : relatedToUID,
            null,
            RAFTaskType.Note,
            sortQuery,
            secondaryRelatedToUID,
            parentRelatedToUID
        ), getAllUsers()]);

        hideProgress(progressDiv);
        getHeaderHeight();

        setState({
            taskListRow: taskItemsRow,
            usersList: usersList,
            taskListRowItemsDivKey: Math.random(),
        });

    };

    //delete start
    const DeleteClicked = (id) => {
        deleteDialog = DialogUtility.confirm({
            animationSettings: { effect: "Fade" },
            cancelButton: { text: "No" },
            closeOnEscape: false,
            content: "Are you sure want to delete?",
            okButton: { text: "Yes", click: DeleteTask.bind(this, id) },
            title: " Delete Note",
            position: { X: "center", Y: "center" },
            cssClass: "raf-delete_alert_dialog alert-dialog",
        });
    };

    const DeleteTask = async (id) => {
        let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
        const response = await DeleteRecordRemoveFocus(id, RAFEntityName.Task);
        deleteDialog.hide();
        hideProgress(progressDiv);
        if (props.relatedCommentsUpdated) {
            props.relatedCommentsUpdated(true);
        }
        if (isNotNullAndUndefined(response)) {
            fetchData();
        }
    };
    //delete end

    const EditTask = async (task: TaskRow) => {
        let taskToCreate: TaskRow = task as TaskRow;
        let progressDiv = showProgress("body", true);
        const response = await saveTaskWithReminders(taskToCreate);
        hideProgress(progressDiv);

        if (isNotNullAndUndefined(response)) {
            showSuccessToast("Comment updated", "Success!");
            if (props.relatedCommentsUpdated) {
                props.relatedCommentsUpdated(true);
            }
            fetchData();
            let editDiv = document.querySelector(
                "#editableTask_" + task.UID + "" + props.relatedToUID
            );
            let summaryDiv = document.querySelector(
                "#summaryTask_" + task.UID + "" + props.relatedToUID
            );
            let editDeletebtn = document.querySelector(
                "#editDeletebtn_" + task.UID + "" + props.relatedToUID
            );
            if (
                isNotNullAndUndefined(editDiv) &&
                isNotNullAndUndefined(editDeletebtn) &&
                isNotNullAndUndefined(summaryDiv)
            ) {
                editDiv.classList.add("hidden");
                summaryDiv.classList.remove("hidden");
                editDeletebtn.classList.remove("hidden");
            } else if (isNotNullAndUndefined(editDiv) &&
                isNotNullAndUndefined(summaryDiv)) {
                editDiv.classList.remove("hidden");
                summaryDiv.classList.add("hidden");
            }
        }
    };

    const getContent = () => {
        const { usersList, taskListRow } = state;

        const taskListRowRows: Array<TaskRow> = new Array<TaskRow>();

        if (isNotEmptyArray(taskListRow)) {
            taskListRow.forEach((task) => {
                let objRow = usersList.find((x) => x.UID === task.AssigneeUID);
                if (isNotNullAndUndefined(objRow)) {
                    task.Assignee = objRow && objRow.Value;
                    taskListRowRows.push(task);
                }
            });
        }

        return (
            <RAFPermissionRender
                permissionName={PermissionConstants.TaskRead}
            >
                {isNotEmptyArray(taskListRowRows)
                    ? taskListRowRows.map((item, index) => {
                        return (
                            <TaskActivityView
                                key={index}
                                taskRow={item}
                                editTask={(taskRow) =>
                                    EditTask(taskRow)
                                }
                                isEidtable
                                deleteTask={(id) => DeleteClicked(id)}
                                cssClassname={props.taskItemClassname}
                                uiMode={props.uiMode}
                                secondaryRelatedToUID={item.SecondaryRelatedToUID}
                                secondaryRelatedTo={item.SecondaryRelatedTo}
                                secondaryRelatedToType={item.SecondaryRelatedToType}
                                {...(isNotNullAndUndefined(
                                    item.SecondaryRelatedToType
                                ) &&
                                    isNotNullAndUndefined(
                                        item.SecondaryRelatedToUID
                                    ) &&
                                    isNotNullAndUndefined(
                                        item.SecondaryRelatedTo
                                    ) && {
                                    showSecondaryRelatedTo:
                                        props.showSecondaryRelatedTo,
                                })}
                            />
                        );
                    })
                    : props.showEmptyState && (
                        <div className="pb-3">
                            <RAFEmptyState
                                title="You don’t have any notes"
                                displayStyle={props.displayStyle}
                                iconClass={
                                    props.showEmptyStateIcon && "fa fa-Comments"
                                }
                            />
                        </div>
                    )}
            </RAFPermissionRender>
        );
    };

    return (
        <div>
            <ul
                className={
                    isNotNullAndUndefined(props.uiMode) && props.uiMode === "table"
                        ? "activitiesItems activitiesTable"
                        : "activitiesItems"
                }
                key={state.taskListRowItemsDivKey}
            >
                {getContent()}
            </ul>
        </div>
    );
}

export default React.memo(TaskActivitySummary);