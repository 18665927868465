import React, { PropsWithChildren, useContext } from "react";
import { FormRenderProps } from "react-final-form";
import { useNavigate } from "react-router-dom";
import RAFFieldLabel from "../../../../RAFComponents/Inputs/RAFFieldLabel";
import { RAFFormContext, getFormValue } from "../../../../RAFComponents/Inputs/RFFUtils";
import RAFRelatedToWithIcon from "../../../../RAFComponents/Navigation/RAFRelatedToWithIcon";
import { hideProgress, showProgress } from "../../../../RAFComponents/helpers/AppHelper";
import { getURLNameByModuleName } from "../../../../RAFComponents/helpers/RAFMenuHelper";
import { isNotNullAndUndefined, propertyOf } from "../../../../RAFComponents/helpers/utils";
import { getEntityByName } from "../../../../RAFMaster/helpers/RMutils";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { RAFEntityType } from "../../../../constants/Common/RMConstants";
import RAFRelatedLookupSelector from "../TaskInput/RAFRelatedLookupSelector";
import { TaskRow } from "../TaskRow";

interface IProps {
    contextModuleName: string;
    onUpdateRelatedTo: () => void;
    displayMode: "Edit" | "View";
}

function ManageTaskActivityRelatedToInput({ ...props }: PropsWithChildren<IProps>) {

    let navigate = useNavigate();

    let moduleName: string = RAFEntityName.Task;
    const outerDivId = `Outer_Div_${moduleName}`;

    const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

    const onClickNavigateInToRelatedTo = async (objectid: string, relatedToType: string) => {
        if (
            isNotNullAndUndefined(relatedToType) &&
            isNotNullAndUndefined(objectid)
        ) {
            const entityName = getURLNameByModuleName(relatedToType);
            let progressDiv = showProgress(`#${outerDivId}`);
            const selectedEntity = await getEntityByName(relatedToType);
            hideProgress(progressDiv);
            if (selectedEntity.EntityType === RAFEntityType.DataList) {
                if (
                    entityName === RAFEntityName.Document ||
                    entityName === RAFEntityName.ContentLibrary
                ) {
                } else {
                    navigate("/" + entityName + "/view/" + objectid);
                }
            } else {
                navigate("/RAF/View/" + relatedToType + "/" + objectid);
            }
        }
    };

    const renderRelatedToContent = () => {
        if (props.displayMode === 'Edit') {
            return (
                <div
                    className="e-card pointer"
                >
                    <div className="e-card-content p-3 py-2">
                        <RAFRelatedLookupSelector
                            field="RelatedTo"
                            relatedField="RelatedToType"
                            moduleName={moduleName}
                        />
                    </div>
                </div>
            );
        } else {
            const relatedToUID = getFormValue(rafFormContextValue, propertyOf<TaskRow>("RelatedToUID"));
            const relatedToType = getFormValue(rafFormContextValue, propertyOf<TaskRow>("RelatedToType"));
            const relatedTo = getFormValue(rafFormContextValue, propertyOf<TaskRow>("RelatedTo"));
            if (isNotNullAndUndefined(relatedToUID)) {
                return (
                    <div
                        className="e-card pointer"
                    >
                        <div className="e-card-content p-3 py-2">
                            <div className="d-flex align-items-center justify-content-between">
                                <div
                                    onClick={() =>
                                        onClickNavigateInToRelatedTo(
                                            relatedToUID,
                                            relatedToType
                                        )
                                    }
                                >
                                    <RAFRelatedToWithIcon
                                        relatedToType={relatedToType}
                                        relatedTo={relatedTo}
                                        showTextAsLink
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        }
    };

    if (props.contextModuleName === RAFEntityName.Dashboard) {
        return (
            <div className="col-md-12">
                <div className="row">
                    <RAFFieldLabel
                        field="RelatedTo"
                        label="Related To"
                        labelClassName="col-12"
                    />
                    <div className="col-12">{renderRelatedToContent()}</div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default React.memo(ManageTaskActivityRelatedToInput);