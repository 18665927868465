import React, { PropsWithChildren, Reducer, useContext, useEffect, useReducer } from "react";
import { FormRenderProps } from "react-final-form";
import RAFChoiceOption from "../../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDropdownCC from "../../../../RAFComponents/Inputs/RAFDropdownCC";
import {
  ConditionNotNullOrWhiteSpace,
  ConditionNullOrWhiteSpace,
} from "../../../../RAFComponents/Inputs/RAFForm";
import RAFLookUpMUI from "../../../../RAFComponents/Inputs/RAFLookUpMUI";
import RAFTextBox from "../../../../RAFComponents/Inputs/RAFTextBox";
import {
  RAFFormContext,
  getFormValue,
  setFormValue,
} from "../../../../RAFComponents/Inputs/RFFUtils";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { getFields } from "../../../../RAFComponents/helpers/AppHelper";
import {
  getDisplayNameByModuleName,
} from "../../../../RAFComponents/helpers/RAFMenuHelper";
import {
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined
} from "../../../../RAFComponents/helpers/utils";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { getURLPrefixByModuleName } from "../../../../helpers/Common/URLPrefixByModuleName";

//const RAFTextBox = ({ field, label, required }) => {

interface IProps {
  field: string;
  relatedField: string;
  moduleName: string;
}

class RelatedToModuleList {
  label?: string;
  value?: string;
  category?: string;
}

interface IState {
  lookupUrl: string;
  lookupModule: string;
  relatedModuleList: RelatedToModuleList[];
}

function RAFRelatedLookupSelector({ ...props }: PropsWithChildren<IProps>) {
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      lookupUrl: null,
      lookupModule: null,
      relatedModuleList: [],
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    const relatedModuleList: RelatedToModuleList[] = [];
    const fieldList = await getFields(props.moduleName);

    const relatedField =
      isNotEmptyArray(fieldList) &&
        isNotNullAndUndefined(props.field)
        ? fieldList.find((x) => x.PropertyName === props.field)
        : null;
    const relatedFieldValue = isNotNullAndUndefined(relatedField)
      ? relatedField.RelatedEntities
      : null;

    const relatedEntities =
      relatedFieldValue && relatedFieldValue.split(",");

    relatedEntities &&
      relatedEntities.forEach((item) => {
        if (item !== RAFEntityName.Task)
          relatedModuleList.push({
            label: getDisplayNameByModuleName(item),
            value: item,
            category: "System Form",
          });
      });

    const relatedToType = getFormValue(rafFormContextValue, props.relatedField);
    const selectedItem = isNotEmptyArray(relatedModuleList) ? relatedModuleList.find((x) => x.value === relatedToType) : null;

    if (isNotNullAndUndefined(selectedItem) && selectedItem.category === "System Form") {
      setState({
        relatedModuleList,
        lookupModule: relatedToType,
        lookupUrl: relatedToType === CareEsioEntity.care_recipient.EntityName ? "CareRecipient/LookUp" : getURLPrefixByModuleName(relatedToType) + "/Lookup",
      });
    } else {
      setState({
        relatedModuleList,
        lookupModule: relatedToType,
        lookupUrl: relatedToType === CareEsioEntity.care_recipient.EntityName ? "CareRecipient/LookUp" : "DataList/Lookup",
      });
    }
  };

  const onDropdownChanged = (label, value) => {
    const { relatedModuleList } = state;
    const selectedItem = (isNotEmptyArray(relatedModuleList) && IsNotNullOrWhiteSpace(value)) ? relatedModuleList.find((x) => x.value === value) : null;
    setFormValue(rafFormContextValue, "RelatedTo", null);
    setFormValue(rafFormContextValue, "RelatedToUID", null);

    if (selectedItem && selectedItem.category === "System Form") {
      setState({
        lookupModule: value,
        lookupUrl: value === CareEsioEntity.care_recipient.EntityName ? "CareRecipient/LookUp" : getURLPrefixByModuleName(value) + "/Lookup",
      });
    } else {
      setState({
        lookupModule: value, lookupUrl: value === CareEsioEntity.care_recipient.EntityName ? "CareRecipient/LookUp" : "DataList/Lookup",
      });
    }
  };

  const { relatedModuleList, lookupModule, lookupUrl } = state;
  return (
    <div className="row g-0 gy-2 align-items-center">
      {isNotEmptyArray(relatedModuleList) && (
        <div className="col-md-12">
          <RAFDropdownCC
            field={props.relatedField}
            label="Select Type"
            onChanged={onDropdownChanged}
            formGroupClassName="m-md-0"
            showLabel
            showClearButton
            allowFiltering
          >
            {relatedModuleList.map(item => {
              return <RAFChoiceOption key={item.value} label={item.label} value={item.value} category={item.category} />;
            })}
          </RAFDropdownCC>
        </div>
      )}
      <div className="col-12">
        <ConditionNotNullOrWhiteSpace when={props.relatedField}>
          <div key={lookupModule}>
            <RAFAttributeRelatedListProvider moduleName={lookupModule}>
              <RAFAttributesContext.Consumer>
                {({ queryAttributes }) => {
                  const defaultField = isNotEmptyArray(queryAttributes) ? queryAttributes.find(x => x.IsDefault === true) : null;
                  const defaultSelectedField = isNotNullAndUndefined(defaultField) ? defaultField.PropertyName : null;
                  return (
                    <RAFLookUpMUI
                      field={props.field.toString()}
                      showLabel={true}
                      label={"Select " + getDisplayNameByModuleName(lookupModule)}
                      url={lookupUrl}
                      placeholder={"Select " + getDisplayNameByModuleName(lookupModule)}
                      moduleName={lookupModule}
                      selectedField={defaultSelectedField}
                      SearchCreateOptionMode={"Footer"}
                      required
                    />
                  );
                }}
              </RAFAttributesContext.Consumer>
            </RAFAttributeRelatedListProvider>
          </div>
        </ConditionNotNullOrWhiteSpace>
        <ConditionNullOrWhiteSpace when={props.relatedField}>
          <div>
            <RAFTextBox field={props.field.toString()} showLabel={true} label="Select Record" placeholder={"Select Record"} disabled />
          </div>
        </ConditionNullOrWhiteSpace>
      </div>
    </div>
  );
}

export default React.memo(RAFRelatedLookupSelector);
