import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, { PropsWithChildren, Reducer, useEffect, useReducer } from "react";
import { FormRenderProps } from "react-final-form";
import { msalInstance } from "../../../../..";
import RAFForm from "../../../../../RAFComponents/Inputs/RAFForm";
import RAFTextArea from "../../../../../RAFComponents/Inputs/RAFTextArea";
import { showSuccessToast } from "../../../../../RAFComponents/Utility/RAFToastComponent";
import { hideProgress, showProgress } from "../../../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../../../RAFComponents/helpers/PermissionHelper";
import { Guid, isNotNullAndUndefined, propertyOf } from "../../../../../RAFComponents/helpers/utils";
import { RAFLayout, RAFTaskType } from "../../../../../constants/Common/Constants";
import { PermissionConstants } from "../../../../../constants/Common/PermissionConstants";
import { saveTaskWithReminders } from "../../TaskHelper";
import { TaskRow } from "../../TaskRow";

interface IProps {
    relatedType: string;
    relatedToUID?: string;
    relatedTo?: string;
    onUpdate: (objectId?: string) => void;

    parentRelatedToUID?: string;
    parentRelatedTo?: string;

    secondaryRelatedToUID?: string;
    secondaryRelatedTo?: string;
    secondaryRelatedToType?: string;

    addBtnText?: string;
    unsetMinWidth?: boolean;
    isActive: boolean;
}

interface IState {
    taskRow: TaskRow;
}

function CreateTaskActivityNotes
    ({ ...props }: PropsWithChildren<IProps>) {
    const outerDivID = `create__notes__outter__${Guid.newGuid()}`;
    let rafForm: FormRenderProps | null;

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            taskRow: null,
        }
    );

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        setState({ taskRow: null });
        let taskRow: TaskRow = new TaskRow();
        taskRow.TaskType = RAFTaskType.Note;
        taskRow.TaskDate = new Date();

        taskRow.Assignee = msalInstance.currentUserName;
        taskRow.AssigneeUID = msalInstance.currentUserId;

        taskRow.RelatedTo = props.relatedTo;
        taskRow.RelatedToUID = props.relatedToUID;
        taskRow.RelatedToType = props.relatedType;

        taskRow.SecondaryRelatedTo = props.secondaryRelatedTo;
        taskRow.SecondaryRelatedToUID = props.secondaryRelatedToUID;
        taskRow.SecondaryRelatedToType = props.secondaryRelatedToType;

        taskRow.Parent = props.parentRelatedTo;
        taskRow.ParentUID = props.parentRelatedToUID;

        setState({
            taskRow,
        });
    };

    const onSubmitTask = async (value) => {
        let progressDiv = showProgress(
            `#${outerDivID}`,
            false
        );
        const taskToCreate: TaskRow = value as TaskRow;

        const response = await saveTaskWithReminders(taskToCreate);
        hideProgress(progressDiv);
        if (
            isNotNullAndUndefined(response)
        ) {
            if (isNotNullAndUndefined(response)) {
                let actionBtnDiv = document.querySelector("#actionBtnDiv");
                if (isNotNullAndUndefined(actionBtnDiv)) {
                    actionBtnDiv.classList.add("hidden");
                }
                showSuccessToast("Comment added", "Success!");
                if (props.onUpdate) {
                    props.onUpdate();
                }
                refresh();
            }
        }
    };

    const onFocus = () => {
        let actionBtnDiv = document.querySelector("#actionBtnDiv");
        let actionTextAreaDiv = document.querySelector("#actionTextAreaDiv");
        if (isNotNullAndUndefined(actionBtnDiv)) {
            actionBtnDiv.classList.remove("hidden");
        }
        if (isNotNullAndUndefined(actionTextAreaDiv)) {
            actionTextAreaDiv.classList.add("actionTextAreaDiv");
        }
    };

    if (props.isActive && isNotNullAndUndefined(state.taskRow)) {
        return (
            <div className="" id={outerDivID}>
                <RAFForm
                    type={TaskRow}
                    initialValues={state.taskRow}
                    submitOnEnterKey={false}
                    formRef={(g) => (rafForm = g)}
                    layout={RAFLayout.TwoColumnLayout}
                    onSubmit={onSubmitTask}
                >
                    <div
                        className={`row g-0 createActivity singleLine createActivity-border-0 align-items-center ${props.unsetMinWidth ? "unset-min-width" : ""
                            }`}
                    >
                        <div
                            className="col overflow-auto createTask-col"
                            id="actionTextAreaDiv"
                        >
                            <RAFTextArea
                                field={propertyOf<TaskRow>('Description')}
                                showLabel={false}
                                required
                                placeholder="Add notes, comments, or @ to mention a person"
                                rows={5}
                                onChanged={(e) => onFocus()}
                                useMentions
                                mentionsField={propertyOf<TaskRow>('Mentions')}
                                rowClassName="g-0"
                                hideRequiredMessage
                            ></RAFTextArea>
                        </div>
                        <div className="col-auto ms-auto mt-auto">
                            <RAFPermissionRender
                                permissionName={PermissionConstants.TaskManage}
                            >
                                <ButtonComponent
                                    type="button"
                                    id={`buttonCreateTaskSave_${outerDivID}`}
                                    cssClass="e-info m-1"
                                    content="Add"
                                    onClick={() => {
                                        rafForm && rafForm.form.submit();
                                    }}
                                ></ButtonComponent>
                            </RAFPermissionRender>
                        </div>
                    </div>
                </RAFForm>
            </div>
        );
    } else {
        return (
            null
        );
    }
}

export default React.memo(CreateTaskActivityNotes);