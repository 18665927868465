import React, { useContext } from "react";
import { Field, FormRenderProps } from "react-final-form";
import RAFDatePickerCustom from "../../../../RAFComponents/Inputs/RAFDatePickerCustom";
import { ConditionIncludes } from "../../../../RAFComponents/Inputs/RAFForm";
import RAFStartDateEndDatePicker from "../../../../RAFComponents/Inputs/RAFStartDateEndDatePicker";
import {
  RAFFormContext,
  setFormValue,
} from "../../../../RAFComponents/Inputs/RFFUtils";
import { propertyOf } from "../../../../RAFComponents/helpers/utils";
import { RAFTaskType } from "../../../../constants/Common/Constants";
import RAFUserTeamLookUpMUI from "../RAFUserTeamLookUpMUI";
import { getTaskDueDate } from "../TaskHelper";
import { TaskRow } from "../TaskRow";

function MangeTaskActivityInputs() {
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

  const onChangeTaskDate = (value) => {
    let taskDueDate = getTaskDueDate(null, value);
    setFormValue(rafFormContextValue, "DueDate", taskDueDate);
  };

  return (
    <>
      <ConditionIncludes
        when={propertyOf<TaskRow>("TaskType")}
        is={[RAFTaskType.ToDo, RAFTaskType.Action]}
      >
        <div className="col-md-12 cssradio-mb-0">
          <RAFDatePickerCustom
            field={propertyOf<TaskRow>("TaskDate")}
            secondaryField={propertyOf<TaskRow>("DueDate")}
            label="Due date"
            displayStyle="splitButton"
            showLabel
            dropdownDateOptions={[
              //{ id: 0, text: "ASAP", value: "ASAP" },
              // { id: 1, text: 'Today', value: '0' },
              { id: 2, text: "1D", value: "1" },
              { id: 3, text: "3D", value: "3" },
              { id: 4, text: "7D", value: "7" },
              { id: 5, text: "15D", value: "15" },
              { id: 6, text: "1M", value: "30" },
              { id: 7, text: "3M", value: "60" },
              { id: 7, text: "6M", value: "180" },
            ]}
            allowMaxValue
            hideMinAndMaxValue
            showClearTextBtn
            onChanged={(value) => onChangeTaskDate(value)}
          />
        </div>
      </ConditionIncludes>
      <ConditionIncludes
        when={propertyOf<TaskRow>("TaskType")}
        is={[RAFTaskType.Appointment, RAFTaskType.Call]}
      >
        <div className="col-md-12">
          <Field name={propertyOf<TaskRow>("TaskType")}>
            {({ input }) => {
              const taskType = input.value;
              return (
                <RAFStartDateEndDatePicker
                  field={propertyOf<TaskRow>("TaskDate")}
                  endDateField={propertyOf<TaskRow>("EndDate")}
                  label={
                    taskType === RAFTaskType.Appointment
                      ? "Start Date"
                      : "Call Start Time"
                  }
                  endDateLabel={
                    taskType === RAFTaskType.Appointment
                      ? "End Date"
                      : "Call End Time"
                  }
                  required
                  interval={5}
                  formGroupClassName="mb-0"
                  durationHrMinsLabel={"fullText"}
                />
              );
            }}
          </Field>
        </div>
      </ConditionIncludes>
      <ConditionIncludes
        when={propertyOf<TaskRow>("TaskType")}
        is={[RAFTaskType.ToDo, RAFTaskType.Appointment, RAFTaskType.Action]}
      >
        <div className="col-12">
          <RAFUserTeamLookUpMUI
            label="Assignee"
            showLabel={true}
            formGroupClassName="mb-0"
          />
        </div>
      </ConditionIncludes>
    </>
  );
}

export default React.memo(MangeTaskActivityInputs);
