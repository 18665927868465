import React, { PropsWithChildren, Reducer, useContext, useEffect, useReducer } from "react";
import { Field, FormRenderProps } from "react-final-form";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";
import { RAFDefaultFieldProps, RAFFormContext, getFormValue, setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import RAFTabGroupBtn from "../../../RAFComponents/Navigation/RAFTabGroupBtn";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { IsNotNullOrWhiteSpace } from "../../../RAFComponents/helpers/utils";
import { RAFEntityName, RAFModuleName } from "../../../constants/Common/EntityConstants";

interface IProps {
    required?: boolean;
    showLabel?: boolean;
    disabled?: boolean;
    showClearButton?: boolean;
    label?: string;
    formGroupClassName?: string;
    onChanged?: (...args) => void;//label,value_for_lookup&dropdown -- color picker dropdown Value,label
}

interface IState {
    selectedUserTeam: string;
}

function RAFUserTeamLookUpMUI(
    {
        label,
        onChanged,
        children,
        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
        disabled = RAFDefaultFieldProps.disabled,
        showClearButton = RAFDefaultFieldProps.showClearButton,
        ...props
    }: PropsWithChildren<IProps>,
) {

    const assigneeField = 'Assignee';
    const assigneeFieldUID = assigneeField + 'UID';
    const assignTeamField = 'AssignTeam';
    const assignTeamFieldUID = assignTeamField + 'UID';

    const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

    const teamUID = getFormValue(rafFormContextValue, assignTeamFieldUID);
    const userUID = getFormValue(rafFormContextValue, assigneeFieldUID);

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            selectedUserTeam: IsNotNullOrWhiteSpace(teamUID) ? RAFEntityName.Team : RAFEntityName.User,
        }
    );

    useEffect(() => {
        updateStateValue();
    }, [teamUID, userUID]);

    const updateStateValue = () => {
        if (IsNotNullOrWhiteSpace(teamUID)) {
            setState({ selectedUserTeam: RAFEntityName.Team });
        } else if (IsNotNullOrWhiteSpace(userUID)) {
            setState({ selectedUserTeam: RAFEntityName.User });
        } else {
            setState({ selectedUserTeam: state.selectedUserTeam });
        }
    };

    const onChangedTeamField = () => {
        setFormValue(rafFormContextValue, assigneeField, null);
        setFormValue(rafFormContextValue, assigneeFieldUID, null);
    };

    const onSelectGroubBtn = (selectedItem: {
        id: RAFEntityName;
        title: string;
    }) => {
        setFormValue(rafFormContextValue, assigneeField, null);
        setFormValue(rafFormContextValue, assigneeFieldUID, null);

        setFormValue(rafFormContextValue, assignTeamField, null);
        setFormValue(rafFormContextValue, assignTeamFieldUID, null);

        setState({ selectedUserTeam: selectedItem.id });
    };

    const labelRightSection = (selectedItem) => {
        const groupBtnItems: {
            id: RAFEntityName;
            title: string;
        }[] = [
                {
                    id: RAFEntityName.User,
                    title: "User",
                },
                {
                    id: RAFEntityName.Team,
                    title: "Team",
                },
            ];
        return (
            <div className="ms-auto">
                <RAFTabGroupBtn
                    items={groupBtnItems}
                    mode="TextOnly"
                    selectedItem={selectedItem}
                    onSelect={onSelectGroubBtn}
                    uiType="RadioBtn"
                    buttonStyle="secondary"
                />
            </div>
        );
    };

    const getUserDropdown = () => {
        if (state.selectedUserTeam === RAFEntityName.User) {
            return (
                <div className="col-md-12">
                    <RAFLookUpMUI
                        field={assigneeField}
                        label={label}
                        showLabel={showLabel}
                        required={required}
                        disabled={disabled}
                        formGroupClassName={props.formGroupClassName}
                        url={'User/LookUp'}
                        moduleName={RAFEntityName.User}
                        labelRightSection={labelRightSection(RAFEntityName.User)}
                        placeholder="Select User"
                        showClearButton={showClearButton}
                    />
                </div>
            );
        } else {
            return null;
        }
    };

    const getTeamDropdown = () => {
        if (state.selectedUserTeam === RAFEntityName.Team) {
            return (
                <>
                    <div className="col-md-12">
                        <RAFLookUpMUI
                            field={assignTeamField}
                            label={label}
                            showLabel={showLabel}
                            required={required}
                            disabled={disabled}
                            formGroupClassName={props.formGroupClassName}
                            url={'Team/LookUp'}
                            moduleName={RAFEntityName.Team}
                            labelRightSection={labelRightSection(RAFEntityName.Team)}
                            placeholder="Select Team"
                            showClearButton={showClearButton}
                            onChanged={onChangedTeamField}
                        />
                    </div>
                    <Field name={assignTeamFieldUID}>
                        {({ input }) => {
                            const teamUID = input.value;
                            return (
                                <div className="col-md-12" id='user_team_lookup'>
                                    <RAFLookUpMUI
                                        field={assigneeField}
                                        label={'Select User'}
                                        showLabel={showLabel}
                                        required={required}
                                        disabled={disabled === true ? true : IsNotNullOrWhiteSpace(teamUID) ? false : true}
                                        formGroupClassName={props.formGroupClassName}
                                        url={'Team/MembersLookup'}
                                        moduleName={RAFEntityName.Team}
                                        placeholder="Any team member"
                                        showClearButton={showClearButton}
                                        additionalParams={[
                                            {
                                                key: "EntityId",
                                                value: teamUID,
                                            },
                                        ]}
                                    />
                                </div>
                            );
                        }}
                    </Field>
                </>
            );
        } else {
            return null;
        }
    };

    return (
        <div className="row gx-2 gy-3">
            {getUserDropdown()}
            {getTeamDropdown()}
        </div>
    );
}

export default React.memo(RAFUserTeamLookUpMUI);