import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import React, { Reducer, useContext, useEffect, useReducer } from "react";
import { Field, FormRenderProps } from "react-final-form";
import { msalInstance } from "../../../..";
import RAFCheckBox from "../../../../RAFComponents/Inputs/RAFCheckBox";
import RAFChoiceOption from "../../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFMultiSelectDropdownMUI from "../../../../RAFComponents/Inputs/RAFMultiSelectDropdownMUI";
import { RAFFormContext, getFormValue, setFormValue } from "../../../../RAFComponents/Inputs/RFFUtils";
import { isNotEmptyArray, isNotNullAndUndefined, propertyOf } from "../../../../RAFComponents/helpers/utils";
import { LookUpGroupRow } from "../../../../RAFComponents/models/CompositeTypes/LookUpGroupRow";
import { RelatedTo } from "../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import { getRelatedCareTeamByRecipientID } from "../../../CareESIO/CareTeam/CareTeamHelper";
import { RAFActivityVisibility } from "../../Activity/ActivityRow";
import { TaskRow } from "../TaskRow";

interface IState {
    careTeamList: LookUpGroupRow[];
}

function ManageTaskActivityNotesInputs() {

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            careTeamList: null,
        }
    );

    const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
    const relatedToType = getFormValue(rafFormContextValue, propertyOf<TaskRow>("RelatedToType"));
    const relatedToUID = getFormValue(rafFormContextValue, propertyOf<TaskRow>("RelatedToUID"));

    useEffect(() => {
        refresh();
    }, [relatedToType, relatedToUID]);

    const refresh = async () => {
        setState({
            careTeamList: []
        });
        if (isNotNullAndUndefined(relatedToType) && isNotNullAndUndefined(relatedToUID) && relatedToType === CareEsioEntity.CareRecipient.EntityName) {
            const allCareTeamList = await getRelatedCareTeamByRecipientID(relatedToUID);
            const careTeamList: LookUpGroupRow[] = isNotEmptyArray(allCareTeamList)
                ? allCareTeamList.map((item) => ({
                    UID: item.RelatedToUID,
                    Value: item.RelatedTo,
                    Catagory: item.RelatedToType,
                }))
                : [];
            const visibility = getFormValue(rafFormContextValue, propertyOf<TaskRow>("Visibility"));
            if (visibility === RAFActivityVisibility.Public) {
                const careTeamListItemUIDs = isNotEmptyArray(careTeamList) ? careTeamList.map((item) => item.UID) : [];
                setFormValue(rafFormContextValue, propertyOf<TaskRow>("ActivityTeamMembers"), careTeamListItemUIDs);
                onChangeActivityTeamMembers(careTeamListItemUIDs);
            };
            setState({ careTeamList });
        }
    };

    const onChangeVisibilityCheckBox = (isChecked: boolean) => {
        const visibility = isChecked ? RAFActivityVisibility.Public : RAFActivityVisibility.Private;
        if (visibility === RAFActivityVisibility.Public) {
            const careTeamListItemUIDs = isNotEmptyArray(state.careTeamList) ? state.careTeamList.map((item) => item.UID) : [];
            setFormValue(rafFormContextValue, propertyOf<TaskRow>("ActivityTeamMembers"), careTeamListItemUIDs);
            onChangeActivityTeamMembers(careTeamListItemUIDs);
        };
        setFormValue(rafFormContextValue, propertyOf<TaskRow>("Visibility"), visibility);
    };

    const onChangeActivityTeamMembers = (selectedItems: string[]) => {
        const visibility = getFormValue(rafFormContextValue, propertyOf<TaskRow>("Visibility"));
        if (visibility === RAFActivityVisibility.Private) {
            const teamMembers: RelatedTo[] = [];
            const { careTeamList } = state;
            //if share with is users, then push all users as team members
            isNotEmptyArray(selectedItems) &&
                selectedItems.forEach((item) => {
                    const selectedUser: LookUpGroupRow = isNotEmptyArray(careTeamList)
                        ? careTeamList.find((x) => x.UID === item)
                        : null;
                    if (isNotNullAndUndefined(selectedUser)) {
                        const selectedUserUID = selectedUser.UID;
                        teamMembers.push({
                            UID: selectedUserUID.toString(),
                            Value: selectedUser.Value,
                            Type: selectedUser.Catagory,
                        });
                    }
                });
            if (
                teamMembers.some((x) => x.UID === msalInstance.currentUserId) ===
                false
            ) {
                teamMembers.push({
                    UID: msalInstance.currentUserId,
                    Value: msalInstance.currentUserName,
                    Type: "User",
                });
            }
            setFormValue(rafFormContextValue, propertyOf<TaskRow>("TeamMembers"), teamMembers);
        } else {
            setFormValue(rafFormContextValue, propertyOf<TaskRow>("TeamMembers"), []);
        }
    };

    const getVisibilityCheckBoxInput = (isChecked: boolean) => {
        return (
            <div className="ms-auto d-flex justify-content-end align-items-center">
                <CheckBoxComponent
                    name={`${propertyOf<TaskRow>("Visibility")}_Everyone`}
                    value={'Visibility'}
                    className="custom-control-input"
                    change={(e) => onChangeVisibilityCheckBox(e.checked)}
                    checked={isChecked}
                    label={'Everyone'}
                />
            </div>
        );
    };

    const getVisibilityInput = () => {
        const { careTeamList } = state;
        const loggedinUserId = msalInstance.currentUserId;
        const disableItemsValue = [loggedinUserId];

        return (
            <>
                {isNotEmptyArray(careTeamList) && (
                    <div>
                        <Field
                            name={propertyOf<TaskRow>("Visibility")}>
                            {({ input }) => {

                                const inputValue = input.value;
                                const isDisabled = inputValue === RAFActivityVisibility.Private ? false : true;

                                return (
                                    <RAFMultiSelectDropdownMUI
                                        field={propertyOf<TaskRow>("ActivityTeamMembers")}
                                        label="Who can see this note?"
                                        placeholder="Select User(s)"
                                        disableItems={disableItemsValue}
                                        showClearButton={false}
                                        allowFiltering
                                        // uitype="checkbox"
                                        dropdownCheckboxDivClass="col-sm-6 col-md-4"
                                        formGroupClassName="mb-0"
                                        rowClassName="gy-2"
                                        labelRightSection={getVisibilityCheckBoxInput(isDisabled)}
                                        disabled={isDisabled}
                                        onChanged={onChangeActivityTeamMembers}
                                    >
                                        {careTeamList.map((item) => {
                                            return (
                                                <RAFChoiceOption
                                                    key={item.UID}
                                                    value={item.UID}
                                                    label={item.Value}
                                                />
                                            );
                                        })}
                                    </RAFMultiSelectDropdownMUI>
                                );
                            }}
                        </Field>
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            {getVisibilityInput()}
            <div className="col-md-12">
                <div className="custom__card__separator custom__card__separator__light"></div>
            </div>
            <div className="col-md-12 mt-3">
                <RAFCheckBox
                    field={propertyOf<TaskRow>("Pinned")}
                    label="Pin Note"
                    validate={false}
                    //labelPosition="right"
                    formGroupClassName="mb-0"
                    //uitype={"switch"}
                    hideRequiredMessage
                    inputFieldClassName="col-auto"
                    labelClassName="col"
                    rowClassName="row gx-2 align-items-center"
                    uitype="switch"
                ></RAFCheckBox>
            </div>
            <div className="col-md-12 mt-3">
                <div className="custom__card__separator custom__card__separator__light"></div>
            </div>
            <div className="col-md-12 mt-3">
                <RAFCheckBox
                    field={propertyOf<TaskRow>("PostFeed")}
                    label="Publish the note to the feed"
                    validate={false}
                    //labelPosition="right"
                    formGroupClassName="mb-0"
                    //uitype={"switch"}
                    hideRequiredMessage
                    inputFieldClassName="col-auto"
                    labelClassName="col"
                    rowClassName="row gx-2 align-items-center"
                    uitype="switch"
                ></RAFCheckBox>
            </div>
        </>
    );
}

export default React.memo(ManageTaskActivityNotesInputs);