import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
  useRef
} from "react";
import { useSetRecoilState } from "recoil";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFSummaryCount from "../../../../RAFComponents/Navigation/RAFSummaryCount";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import {
  RAFActionStatus,
  RAFButtonConstant,
} from "../../../../constants/Common/Constants";
import PlannerListTimelineContent from "../../../CareESIO/CareShiftLog/PlannerTask/PlannerListTimelineContent";
import {
  getAllRelatedTasks,
  getRelatedMyTaskbyUITab,
  getTaskDisplayDateAndBadge,
  myTaskFilteredValue,
  selectedTaskView,
} from "../TaskHelper";
import {
  MyTaskCount,
  MyTaskOptions,
  TaskDropdownItems,
  TaskRow,
  relatedTaskTabItems,
} from "../TaskRow";

interface IProps {
  taskSummaryData?: MyTaskCount;
  taskDialogOpen?: () => void;
  onClickManageTask?: (taskRow) => void;
  relatedToUID?: string;
  secondaryRelatedToUID?: string;
  hideTabMenu?: boolean;
  showAllTasks?: boolean;
}

interface IState {
  relatedTaskTabItem: TaskRow[];
  allTaskItem: TaskRow[];
  asapTaskItem: TaskRow[];
  overdueTaskItem: TaskRow[];
  unPlannedTaskItem: TaskRow[];
  selectedTabItem: {
    id: MyTaskOptions;
    text: MyTaskOptions;
  };
  isLoading: boolean;
}

function RAFRelatedTaskTab({ ...props }: PropsWithChildren<IProps>) {
  let taskTabObj = useRef(null);

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;
  const hideTabMenu = isNotNullAndUndefined(props.hideTabMenu)
    ? props.hideTabMenu
    : false;
  useEffect(() => {
    getAllRelatedTask(state.selectedTabItem.text, false);
  }, [props.relatedToUID, props.taskSummaryData]);

  const setSelectedTaskView = useSetRecoilState(selectedTaskView);
  const setMyTaskTaskFilteredValue = useSetRecoilState(myTaskFilteredValue);

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      selectedTabItem: relatedTaskTabItems[0],
      relatedTaskTabItem: null,
      allTaskItem: null,
      asapTaskItem: null,
      overdueTaskItem: null,
      unPlannedTaskItem: null,
      isLoading: false,
    }
  );

  const getAllTask1 = async () => {
    const [allTaskItem, asapTaskItem, overdueTaskItem, unPlannedTaskItem] =
      await Promise.all([
        getRelatedMyTaskbyUITab(
          props.relatedToUID,
          [MyTaskOptions.All],
          props.secondaryRelatedToUID
        ),
        getRelatedMyTaskbyUITab(
          props.relatedToUID,
          [MyTaskOptions.ASAP],
          props.secondaryRelatedToUID
        ),
        getRelatedMyTaskbyUITab(props.relatedToUID, [
          MyTaskOptions.Overdue,
          props.secondaryRelatedToUID,
        ]),
        getRelatedMyTaskbyUITab(props.relatedToUID, [
          MyTaskOptions.Unplanned,
          props.secondaryRelatedToUID,
        ]),
      ]);

    setState({ allTaskItem, asapTaskItem, overdueTaskItem, unPlannedTaskItem });
  };

  const getAllRelatedTask = async (
    UITabFilter: MyTaskOptions,
    checkValueExistInState: boolean
  ) => {
    if (checkValueExistInState === false) {
      setState({
        isLoading: true,
        allTaskItem: null,
        asapTaskItem: null,
        overdueTaskItem: null,
        unPlannedTaskItem: null,
      });
    } else {
      setState({ isLoading: true });
    }
    const relatedTaskTabItem = await getRelatedTaskItems(
      UITabFilter,
      checkValueExistInState
    );
    setTimeout(() => {
      setState({ relatedTaskTabItem, isLoading: false });
    }, 500);
  };

  const getRelatedTaskItems = (
    UITabFilter: MyTaskOptions,
    checkValueExistInState: boolean
  ) => {
    return new Promise<TaskRow[]>(async (resolve, reject) => {
      if (UITabFilter === MyTaskOptions.All) {
        if (
          checkValueExistInState &&
          isNotNullAndUndefined(state.allTaskItem)
        ) {
          resolve(state.allTaskItem);
        } else {
          let allTaskItem;
          if (hideTabMenu === true) {
            allTaskItem = await getAllRelatedTasks(
              props.relatedToUID,
              props.secondaryRelatedToUID
            );
            setState({ allTaskItem: allTaskItem ?? [] });
            resolve(allTaskItem);
          } else {
            if (
              isNotNullAndUndefined(props.showAllTasks) &&
              props.showAllTasks === true
            ) {
              allTaskItem = await getAllRelatedTasks(
                props.relatedToUID,
                props.secondaryRelatedToUID
              );
              setState({ allTaskItem: allTaskItem ?? [] });
              resolve(allTaskItem);
            } else {
              allTaskItem = await getRelatedMyTaskbyUITab(
                props.relatedToUID,
                [MyTaskOptions.All],
                props.secondaryRelatedToUID
              );
              setState({ allTaskItem: allTaskItem ?? [] });
              resolve(allTaskItem);
            }
          }
        }
      } else if (UITabFilter === MyTaskOptions.ASAP) {
        if (
          checkValueExistInState &&
          isNotNullAndUndefined(state.asapTaskItem)
        ) {
          resolve(state.asapTaskItem);
        } else {
          const asapTaskItem = await getRelatedMyTaskbyUITab(
            props.relatedToUID,
            [MyTaskOptions.ASAP],
            props.secondaryRelatedToUID
          );
          setState({ asapTaskItem: asapTaskItem ?? [] });
          resolve(asapTaskItem);
        }
      } else if (UITabFilter === MyTaskOptions.Overdue) {
        if (
          checkValueExistInState &&
          isNotNullAndUndefined(state.overdueTaskItem)
        ) {
          resolve(state.overdueTaskItem);
        } else {
          const overdueTaskItem = await getRelatedMyTaskbyUITab(
            props.relatedToUID,
            [MyTaskOptions.Overdue],
            props.secondaryRelatedToUID
          );
          setState({ overdueTaskItem: overdueTaskItem ?? [] });
          resolve(overdueTaskItem);
        }
      } else if (UITabFilter === MyTaskOptions.Unplanned) {
        if (
          checkValueExistInState &&
          isNotNullAndUndefined(state.unPlannedTaskItem)
        ) {
          resolve(state.unPlannedTaskItem);
        } else {
          const unPlannedTaskItem = await getRelatedMyTaskbyUITab(
            props.relatedToUID,
            [MyTaskOptions.Unplanned],
            props.secondaryRelatedToUID
          );
          setState({ unPlannedTaskItem: unPlannedTaskItem ?? [] });
          resolve(unPlannedTaskItem);
        }
      } else {
        resolve(null);
      }
    });
  };

  const expandCollapseTaskPanel = (item: string) => {
    let myTaskTabSelectedFilter: string[] = [MyTaskOptions.All];
    let selectedDropdownItem: string = TaskDropdownItems.MyTask;
    if (isNotNullAndUndefined(item)) {
      selectedDropdownItem = item;
      if (
        item === MyTaskOptions.All ||
        item === MyTaskOptions.Upcoming ||
        item === MyTaskOptions.Overdue ||
        item === MyTaskOptions.ASAP ||
        item === MyTaskOptions.Today ||
        item === MyTaskOptions.Unplanned
      ) {
        selectedDropdownItem = TaskDropdownItems.MyTask;
        myTaskTabSelectedFilter = [item];
      }
    }
    if (isNotNullAndUndefined(props.taskDialogOpen)) {
      setSelectedTaskView(selectedDropdownItem);
      setMyTaskTaskFilteredValue(myTaskTabSelectedFilter);
      props.taskDialogOpen();
    }
  };

  const onClickManageTask = (taskRow) => {
    if (props.onClickManageTask) {
      props.onClickManageTask(taskRow);
    }
  };

  //UpcomingTaskDiv end
  const getTaskDiv = (upcomingTaskRow: TaskRow) => {
    const taskDisplayDateAndBadge = getTaskDisplayDateAndBadge(
      upcomingTaskRow.TaskDate,
      upcomingTaskRow.TaskType
    );
    let taskDate = taskDisplayDateAndBadge.taskDate;
    let taskDueDate = taskDisplayDateAndBadge.taskDueDate;
    let dateBadge = taskDisplayDateAndBadge.dateBadge;

    let titleTooltipContent;
    if (upcomingTaskRow.TaskDate !== null) {
      let titleTooltipDescription = isNotNullAndUndefined(
        upcomingTaskRow.Description
      )
        ? upcomingTaskRow.Description
        : "";
      titleTooltipContent =
        "<h6>" +
        upcomingTaskRow.Title +
        "</h6>" +
        "<p>" +
        titleTooltipDescription +
        "</p>";
    }

    // let hasPermissionToEdit = false;
    if (isNotNullAndUndefined(permissionValue)) {
      //hasPermissionToEdit = hasPermission(permissionValue, ACPermissionConstants.TaskManage);
      return (
        <div
          className="activityItems border-0 flex-row px-2"
          onClick={() => {
            onClickManageTask(upcomingTaskRow);
          }}
        >
          <div className="w-100 d-flex align-items-center justify-space-around">
            <div className="text-nowrap px-2">
              <TooltipComponent content={taskDueDate} position="BottomCenter">
                <span
                  className={
                    upcomingTaskRow.TaskStatus !== RAFActionStatus.Planned
                      ? "activityDateSpanSection ms-2 badge grey lighten-1 badge-grey"
                      : "activityDateSpanSection badge " + dateBadge
                  }
                >
                  {taskDate}
                </span>
              </TooltipComponent>
            </div>
            <TooltipComponent
              content={titleTooltipContent}
              position="BottomCenter"
            >
              <span className="itemHeader ecllipseFirstLine text-decoration-none px-1">
                {upcomingTaskRow.Title}
              </span>
            </TooltipComponent>
          </div>
        </div>
      );
    }
  };
  //UpcomingTaskDiv end

  const renderTabContent = (selectedItemText: string) => {
    if (selectedItemText === MyTaskOptions.All) {
      return (
        <div>{relatedTaskContent(state.allTaskItem, MyTaskOptions.All)}</div>
      );
    } else if (selectedItemText === MyTaskOptions.ASAP) {
      return (
        <div>{relatedTaskContent(state.asapTaskItem, MyTaskOptions.ASAP)}</div>
      );
    } else if (selectedItemText === MyTaskOptions.Overdue) {
      return (
        <div>
          {relatedTaskContent(state.overdueTaskItem, MyTaskOptions.Overdue)}
        </div>
      );
    } else if (selectedItemText === MyTaskOptions.Unplanned) {
      return (
        <div>
          {relatedTaskContent(state.unPlannedTaskItem, MyTaskOptions.Unplanned)}
        </div>
      );
    }
  };

  const relatedTaskContent = (
    relatedTasks: TaskRow[],
    UITab: MyTaskOptions
  ) => {
    return (
      <div className="d-flex align-items-center flex-column" key={UITab}>
        <div className="w-100">
          {state.isLoading === false ? (
            <PlannerListTimelineContent
              plannerList={relatedTasks}
              rowClick={(taskRow) => onClickManageTask(taskRow)}
            ></PlannerListTimelineContent>
          ) : (
            // <Fragment>
            //   {isNotEmptyArray(relatedTasks) ? (
            //     relatedTasks.map((item, index) => {
            //       return (
            //         <RAFTaskCardContent
            //           key={index}
            //           taskRow={item}
            //           onClickEditTask={() => onClickManageTask(item)}
            //         />
            //       );
            //     })
            //   ) : (
            //     <RAFEmptyState
            //       title="You don’t have any tasks or appointments"
            //       displayStyle="TextOnly"
            //     />
            //   )}
            // </Fragment>
            <div className="container-fluid px-0">
              <ACLoadingPanel loadingText="Preparing Data..." />
            </div>
          )}
        </div>
        {isNotEmptyArray(state.allTaskItem) && hideTabMenu === false && (
          <RAFButtonComponent
            action={RAFButtonConstant.ShowMore}
            onClick={() => expandCollapseTaskPanel(UITab)}
            showIcon={false}
            className="link-button mb-2"
          />
        )}
      </div>
    );
  };

  const selectedItemIndex = isNotNullAndUndefined(state.selectedTabItem)
    ? relatedTaskTabItems.findIndex(
      (x) => x.text === state.selectedTabItem.text
    )
    : 0;

  const onSelectTab = (e) => {
    if (isNotNullAndUndefined(e) && isNotNullAndUndefined(e.selectedIndex)) {
      const selectedIndex = e.selectedIndex;

      const selectedItem = isNotNullAndUndefined(selectedIndex)
        ? relatedTaskTabItems[selectedIndex]
        : relatedTaskTabItems[0];

      getAllRelatedTask(selectedItem.text, true);
      setState({ selectedTabItem: selectedItem });
      taskTabObj.current.refresh();
    }
  };

  return (
    <div className="section__container">
      <div className="section__div">
        <TabComponent
          ref={taskTabObj}
          cssClass={
            hideTabMenu === true
              ? "e-fill header-tab custom-button light-primary transparent_tabHeader fixedHeight_tabHeader borderBottom_tabHeader raf-md hide-tab-menu"
              : "e-fill header-tab custom-button light-primary transparent_tabHeader fixedHeight_tabHeader borderBottom_tabHeader raf-md"
          }
          // cssClass='e-fill custom-tab-footer-border active-bg-gray active-bg-white borderBottom_tabHeader'
          animation={{
            previous: {
              effect: "FadeIn",
              duration: 600,
              easing: "ease",
            },
            next: {
              effect: "FadeIn",
              duration: 600,
              easing: "ease",
            },
          }}
          selected={(e) => onSelectTab(e)}
          selectedItem={selectedItemIndex}
        >
          <TabItemsDirective>
            {relatedTaskTabItems.map((x) => {
              const itemCount =
                isNotNullAndUndefined(props.taskSummaryData) &&
                  isNotNullAndUndefined(props.taskSummaryData[x.text])
                  ? props.taskSummaryData[x.text]
                  : 0;

              return (
                <TabItemDirective
                  key={x.id}
                  header={{
                    text: `<div class='d-flex align-items-center justify-content-center'>${x.text
                      } ${isNotNullAndUndefined(itemCount) && itemCount > 0
                        ? `<span class="e-badge e-badge-circle e-badge-border ms-1">${itemCount}</span>`
                        : ""
                      }</div>`,
                  }}
                  content={renderTabContent.bind(this, x.text)}
                />
              );
            })}
          </TabItemsDirective>
        </TabComponent>
      </div>
      {false && (
        <div className="row g-2">
          {relatedTaskTabItems.map((item, index) => {
            const itemCount =
              isNotNullAndUndefined(props.taskSummaryData) &&
                isNotNullAndUndefined(props.taskSummaryData[item.text])
                ? props.taskSummaryData[item.text]
                : 0;
            return (
              <div
                key={`${index}`}
                id={`${index}_${item.text}`}
                className={
                  itemCount > 0
                    ? `col-4`
                    : // ? `col-4 ${isNotNullAndUndefined(item.cssClass) && item.cssClass}`
                    "col-4 summary-count-grey"
                }
                style={{ cursor: "pointer" }}
                onClick={() => expandCollapseTaskPanel(item.text)}
              >
                <RAFSummaryCount label={item.text} value={itemCount} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default React.memo(RAFRelatedTaskTab);
