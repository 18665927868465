import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { RecurrenceEditorChangeEventArgs, RecurrenceEditorComponent } from '@syncfusion/ej2-react-schedule';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import moment from 'moment';
import * as React from 'react';
import { Fragment, PropsWithChildren } from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { RAFLayout } from '../../../constants/Common/Constants';
import { isNotNullAndUndefined, isNullOrUndefined } from '../../../RAFComponents/helpers/utils';
import RAFForm, { composeValidators } from "../../../RAFComponents/Inputs/RAFForm";
import { checkAndRenderContent, getFormValue, isRequired, RAFDefaultFieldProps, RAFDropdownFieldProps, RAFFormContext, setFormValue } from '../../../RAFComponents/Inputs/RFFUtils';
import { ConvertSchedulerToCronExpression } from '../../../RAFMaster/helpers/RMutils';

export enum RepeatProcesssItems {
    Never = 'Never',
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly',
    Yearly = 'Yearly',
    Custom = 'Custom',
}

interface IProps {
    rRuleExpressionPropertyName: string;
}

interface IState {
    showRecurringContent: boolean;
}

class RAFRepeatProcesssDropdown<T> extends React.Component<PropsWithChildren<RAFDropdownFieldProps<T> & IProps>, IState>{
    static defaultProps: Partial<IProps>;

    _isMounted = false;
    private rafFormContextValue: FormRenderProps;
    private recObject: RecurrenceEditorComponent;
    public rafRecurringForm: FormRenderProps | null;

    constructor(props) {
        super(props);
        this.state = {
            showRecurringContent: false,
        };
    }

    componentDidMount = () => {
        this._isMounted = true;
        //this.setClickEvents();
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    dropdownClick = (btnId, containerId) => {
        let btnDiv = document.querySelector("#" + btnId);
        let containerDiv = document.getElementById(containerId);
        let btnDivWidth = btnDiv.clientWidth;
        containerDiv.style.width = btnDivWidth + "px";
    };

    setClickEvents(btnId, containerId) {
        let dropDownItems = [`divRepeatProcess_0`, `divRepeatProcess_1`, `divRepeatProcess_2`, `divRepeatProcess_3`, `divRepeatProcess_4`, `divRepeatProcess_5`];
        let dateValue = [RepeatProcesssItems.Never, RepeatProcesssItems.Daily, RepeatProcesssItems.Weekly, RepeatProcesssItems.Monthly, RepeatProcesssItems.Yearly, RepeatProcesssItems.Custom];
        for (let i = 0; i < dropDownItems.length; i++) {
            let drodownBtnComponentReminder = document.getElementById(dropDownItems[i]);
            if (isNotNullAndUndefined(drodownBtnComponentReminder)) {
                drodownBtnComponentReminder.onclick = () => {
                    this.onClickDropDownItem(dateValue[i]);
                };
            }
        }
        this.dropdownClick(btnId, containerId);
    }

    repeatProcessTextValue = (field) => {
        const fieldValue = getFormValue(this.rafFormContextValue, field);
        let outValue = fieldValue;
        const cronExpressionValue = getFormValue(this.rafFormContextValue, 'CronExpression');
        if (isNullOrUndefined(fieldValue)) {
            if (isNotNullAndUndefined(cronExpressionValue)) {
                outValue = RepeatProcesssItems.Custom;
                setTimeout(() => {
                    setFormValue(this.rafFormContextValue, field, RepeatProcesssItems.Custom);
                }, 100);
            } else {
                outValue = RepeatProcesssItems.Never;
            }
        }
        return outValue;
    };

    onClickDropDownItem = (value: string) => {
        setFormValue(this.rafFormContextValue, this.props.field.toString(), value);
        if (value === RepeatProcesssItems.Never) {
            setFormValue(this.rafFormContextValue, 'CronExpression', null);
            setFormValue(this.rafFormContextValue, this.props.rRuleExpressionPropertyName, null);
            if (this.props.onChanged)
                this.props.onChanged(value);
        } else if (value === RepeatProcesssItems.Daily) {
            //FREQ=DAILY;INTERVAL=1;
            let rruleExpression = 'FREQ=DAILY;INTERVAL=1;';
            setFormValue(this.rafFormContextValue, 'CronExpression', ConvertSchedulerToCronExpression(rruleExpression));
            setFormValue(this.rafFormContextValue, this.props.rRuleExpressionPropertyName, rruleExpression);
            if (this.props.onChanged)
                this.props.onChanged(value);
        } else if (value === RepeatProcesssItems.Custom) {
            let fieldValue = this.repeatProcessTextValue(this.props.field.toString());
            setFormValue(this.rafFormContextValue, this.props.field.toString(), fieldValue);
            this.recurringDialogOpen();
        } else {
            let date = new Date();
            let month = parseInt(moment(date).format('MM'));
            let monthDay = parseInt(moment(date).format('DD'));
            let rruleExpression = null;
            if (value === RepeatProcesssItems.Weekly) {
                //FREQ=WEEKLY;BYDAY=WE;INTERVAL=1;
                let daysValue = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
                let FREQ = 'FREQ=WEEKLY;';
                let BYDAY = 'BYDAY=' + daysValue[date.getDay()] + ';';
                let INTERVAL = 'INTERVAL=1;';

                rruleExpression = FREQ + BYDAY + INTERVAL;
            } else if (value === 'Monthly') {
                //FREQ=MONTHLY;BYMONTHDAY=14;INTERVAL=1;
                let FREQ = 'FREQ=MONTHLY;';
                let BYMONTHDAY = 'BYMONTHDAY=' + monthDay + ';';
                let INTERVAL = 'INTERVAL=1;';

                rruleExpression = FREQ + BYMONTHDAY + INTERVAL;
            } else if (value === 'Yearly') {
                //FREQ=YEARLY;BYMONTHDAY=14;BYMONTH=9;INTERVAL=1;
                let FREQ = 'FREQ=YEARLY;';
                let BYMONTHDAY = 'BYMONTHDAY=' + monthDay + ';';
                let BYMONTH = 'BYMONTH=' + month + ';';
                let INTERVAL = 'INTERVAL=1;';

                rruleExpression = FREQ + BYMONTHDAY + BYMONTH + INTERVAL;
            }
            setFormValue(this.rafFormContextValue, 'CronExpression', ConvertSchedulerToCronExpression(rruleExpression));
            setFormValue(this.rafFormContextValue, this.props.rRuleExpressionPropertyName, rruleExpression);
            if (this.props.onChanged)
                this.props.onChanged(value);
        }
        let repeatProcessBtn = document.getElementsByClassName("repeatProcessBtn");
        for (let i = 0; i < repeatProcessBtn.length; i++) {
            repeatProcessBtn[i].classList.remove("e-active");
            repeatProcessBtn[i].classList.remove("e-popup-open");
            repeatProcessBtn[i].classList.add("e-popup-close");
        }
    };

    recurringDialogOpen = () => {
        this.setState({ showRecurringContent: true }, () => {
            if (isNotNullAndUndefined(this.rafFormContextValue) && isNotNullAndUndefined(this.rafFormContextValue.values)) {
                let rrExpression = this.rafFormContextValue.values[this.props.rRuleExpressionPropertyName];
                setTimeout(() => {
                    this.recObject && this.recObject.setRecurrenceRule(rrExpression);
                }, 100);
            }
        });
    };

    private onSchedulerChange(args: RecurrenceEditorChangeEventArgs): void {
        if (args.value !== '') {
            setFormValue(this.rafRecurringForm, 'CronExpression', ConvertSchedulerToCronExpression(args.value));
            setFormValue(this.rafRecurringForm, this.props.rRuleExpressionPropertyName, args.value);
            //this.rafForm.form.mutators.setValue("IsRecurring", true);
        }
        else {
            setFormValue(this.rafRecurringForm, 'CronExpression', null);
            setFormValue(this.rafRecurringForm, this.props.rRuleExpressionPropertyName, null);
            //this.rafForm.form.mutators.setValue("IsRecurring", false);
        }
    }

    recurringDialogContent = () => {
        if (isNotNullAndUndefined(this.rafFormContextValue) && isNotNullAndUndefined(this.rafFormContextValue.values) && isNotNullAndUndefined(this.state.showRecurringContent) && this.state.showRecurringContent === true) {
            let rafFormContextValue = { ...this.rafFormContextValue.values };
            return (
                <RAFForm className="h-100" initialValues={rafFormContextValue} formRef={g => this.rafRecurringForm = g} layout={RAFLayout.TwoColumnLayout} primaryKey={rafFormContextValue.UID} onSubmit={this.onSubmitCronExpression}>
                    <div className="e-dlg-body-content" id="bpStepInsertContent">
                        <div className="row g-2">
                            <div className="col">
                                <RecurrenceEditorComponent ref={r => this.recObject = r} change={this.onSchedulerChange.bind(this)}></RecurrenceEditorComponent>
                            </div>
                        </div>

                        <div className="e-dlg-footerContent ">
                            <ButtonComponent className="e-info e-small">Ok</ButtonComponent>
                            <ButtonComponent type="button" cssClass='e-flat ms-2' onClick={() => this.recurringDialogClose()}>Cancel</ButtonComponent>
                        </div>
                    </div>
                </RAFForm>
            );
        }
        else {
            return (<div></div>);
        }
    };

    onSubmitCronExpression = () => {
        this.setState({ showRecurringContent: false }, () => {
            let rafFormContextValue = { ...getFormValue(this.rafRecurringForm) };
            setFormValue(this.rafFormContextValue, 'CronExpression', rafFormContextValue.CronExpression);
            setFormValue(this.rafFormContextValue, this.props.rRuleExpressionPropertyName, rafFormContextValue.RRuleExpression);
            if (isNullOrUndefined(rafFormContextValue.CronExpression)) {
                setFormValue(this.rafFormContextValue, this.props.field.toString(), RepeatProcesssItems.Never);
                if (this.props.onChanged)
                    this.props.onChanged(RepeatProcesssItems.Never);
            } else {
                setFormValue(this.rafFormContextValue, this.props.field.toString(), RepeatProcesssItems.Custom);
                if (this.props.onChanged)
                    this.props.onChanged(RepeatProcesssItems.Custom);
            }
        });
    };

    recurringDialogClose = () => {
        this.setState({ showRecurringContent: false });
    };

    render() {
        let { field, initialValue, label, required, showLabel, validate, validators, } = this.props;

        return (
            <Fragment>
                <RAFFormContext.Consumer>
                    {(rafFormContextValue) => {
                        this.rafFormContextValue = rafFormContextValue;
                        return (
                            <div>
                                <div className="row" id={"rafdiv" + field.toString()} >
                                    <div className={isNotNullAndUndefined(this.props.formGroupClassName) ? this.props.formGroupClassName + " col-md-12 form-group align-items-center py-0" : "col-md-12 form-group align-items-center py-0"}>
                                        <div className="row">
                                            <div className={this.props.labelClassName ? this.props.labelClassName : "col-12"}>
                                                {showLabel && showLabel === true &&
                                                    <label className={(required ? "form-label required" : "form-label")}><>{checkAndRenderContent(label) || checkAndRenderContent(field)}</></label>
                                                }
                                            </div>
                                            <div className={this.props.inputFieldClassName ? this.props.inputFieldClassName : "col-12"}>
                                                <Field name={field.toString()}
                                                    {...initialValue ? { initialValue: initialValue } : {}}
                                                    {...validators ?
                                                        { validate: validate === true ? composeValidators(required === true ? isRequired : null, ...validators) : null }
                                                        :
                                                        { validate: validate === true ? composeValidators(required === true ? isRequired : null) : null }
                                                    }
                                                    allowNull parse={value => (value === "" ? null : value)}>
                                                    {({ }) => {
                                                        return (
                                                            <div>
                                                                <div className="col-12" >
                                                                    <div className="row align-items-center g-2 phoneSection">
                                                                        <div className="col-12 inputFieldContent position-relative">
                                                                            <div className="customViewDropdownMenu" id={`divRepeatProcessOuter`} >
                                                                                <div className="dropdown-item" id={`divRepeatProcess_0`}>
                                                                                    <span>Never</span>
                                                                                </div>
                                                                                <div className="dropdown-item" id={`divRepeatProcess_1`}>
                                                                                    <span>{RepeatProcesssItems.Daily}</span>
                                                                                </div>
                                                                                <div className="dropdown-item" id={`divRepeatProcess_2`}>
                                                                                    <span>{RepeatProcesssItems.Weekly}</span>
                                                                                </div>
                                                                                <div className="dropdown-item" id={`divRepeatProcess_3`}>
                                                                                    <span>{RepeatProcesssItems.Monthly}</span>
                                                                                </div>
                                                                                <div className="dropdown-item" id={`divRepeatProcess_4`}>
                                                                                    <span>{RepeatProcesssItems.Yearly}</span>
                                                                                </div>
                                                                                <div className="border-top">
                                                                                </div>
                                                                                <div className="dropdown-item" id={`divRepeatProcess_5`}>
                                                                                    <span>{RepeatProcesssItems.Custom}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="w-100">
                                                                                <DropDownButtonComponent
                                                                                    id="divRepeatProcessBtn"
                                                                                    target={`#divRepeatProcessOuter`} cssClass={'repeatProcessBtn unset-custom-button-md customDropdownBtn customScrollBar'}
                                                                                    onClick={() => this.setClickEvents("divRepeatProcessBtn", "divRepeatProcessOuter")}
                                                                                >
                                                                                    {this.repeatProcessTextValue(field.toString())}
                                                                                </DropDownButtonComponent>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.showRecurringContent && (
                                        <DialogComponent
                                            visible={this.state.showRecurringContent}
                                            header="Set Recurring" showCloseIcon
                                            cssClass="centerDialog-sm createEditForm form-center-dialog"
                                            id="recurringContent"
                                            content={this.recurringDialogContent.bind(this)}
                                            isModal
                                            target="body"
                                            closeOnEscape={false}
                                            close={this.recurringDialogClose.bind(this)}
                                        ></DialogComponent>
                                    )}
                                </div>
                            </div>
                        );
                    }
                    }
                </RAFFormContext.Consumer>
            </Fragment>
        );
    }
}

RAFRepeatProcesssDropdown.defaultProps = {
    ...RAFDefaultFieldProps,
    rRuleExpressionPropertyName: 'RruleExpression'
};

export default RAFRepeatProcesssDropdown;