import { Browser } from "@syncfusion/ej2-base";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  AnimationSettingsModel,
  DialogComponent,
  DialogUtility,
  TooltipComponent
} from "@syncfusion/ej2-react-popups";
import {
  DropDownButtonComponent,
  MenuEventArgs
} from "@syncfusion/ej2-react-splitbuttons";
import React, { Component } from "react";
import { Field, FormRenderProps } from "react-final-form";
import { RRule } from "rrule";
import { msalInstance } from "../../..";
import ACComboBox from "../../../components/shared/ACFormFields/ACComboBox";
import { PermissionConstants } from "../../../constants/Common/PermissionConstants";
import {
  addToFocusList,
  initUserAndTeam
} from "../../../helpers/ACutils";
import { DeleteRecordRemoveFocus, hideProgress, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import {
  Constants,
  ContentType,
  RAFActionStatus,
  RAFInputType,
  RAFLayout,
  RAFTaskType
} from "../../../constants/Common/Constants";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import { getFocusListByRelatedUID, removeFromFocusList } from "../../../RAFComponents/helpers/RAFMenuHelper";
import {
  convertUTCDateToLocalTimezone,
  deepEqual,
  getSaveRequest,
  IDialogProps,
  isNotNullAndUndefined,
  isNullOrUndefined,
  IsNullOrWhiteSpace,
  propertyOf,
  RAFFormComponentProps
} from "../../../RAFComponents/helpers/utils";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDatePicker from "../../../RAFComponents/Inputs/RAFDatePicker";
import RAFDatePickerCustom from "../../../RAFComponents/Inputs/RAFDatePickerCustom";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFForm, { Condition } from "../../../RAFComponents/Inputs/RAFForm";
import RAFTextArea from "../../../RAFComponents/Inputs/RAFTextArea";
import RAFTextBox from "../../../RAFComponents/Inputs/RAFTextBox";
import { setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { LookUpGroupRow } from "../../../RAFComponents/models/CompositeTypes/LookUpGroupRow";
import RAFCollapse from "../../../RAFComponents/Navigation/RAFCollapse";
import { RAFDetailFieldEditable } from "../../../RAFComponents/Navigation/RAFDetailFieldEditable";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import RAFSummaryDetailInlineEdit from "../../../RAFComponents/Navigation/RAFSummaryDetailInlineEdit";
import {
  RAFCustomFilter,
  RAFCustomOperator
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { createInstance } from "../../../RAFComponents/Utility/FormUtility";
import { showSuccessToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { NavigateParamsProps } from "../../../router";
import { MyTeamsContext } from "../../Common/Providers/MyTeamsContextProvider";
import ActivitySummary from "../Activity/Controller/ActivitySummary";
import CreateActivity from "../Activity/CreateActivity";
import RAFAddToFocusList from "../Dashboards/TaskDashboard/RAFAddToFocusList";
import DocumentSummary from "../Document/DocumentSummary";
import RAFReminderDropdown from "../Reminder/RAFReminderDropdown";
import { getTaskRemindersRow } from "../Reminder/ReminderHelper";
import {
  ConvertTaskRowToITaskReminderRow,
  generateTaskReminderRow
} from "../Reminder/ReminderMappingRegister";
import { ReminderRow } from "../Reminder/ReminderRow";
import { TaskReminderRow } from "../Reminder/TaskReminderRow";
import RAFUserTeamDropdown from "../Task/RAFUserTeamDropdown";
import { UserGroupRow } from "../UserGroup/UserGroupRow";
import RAFRepeatProcesssDropdown from "./RAFRepeatProcesssDropdown";
import { getTaskDueDate } from "./TaskHelper";
import RAFRelatedLookupSelector from "./TaskInput/RAFRelatedLookupSelector";
import {
  ConvertTaskDtoToTaskRow,
  ConvertTaskRowToTaskDto
} from "./TaskMappingRegister";
import { ITaskDto, TaskRow } from "./TaskRow";
import { RAFEntityName, RAFModuleName } from "../../../constants/Common/EntityConstants";

interface IProps {
  complete?: (taskRow: TaskRow, taskStatus: string) => void;
  completeNextTask?: (taskRow: TaskRow) => void;
  onUpdate?: (taskRow?: TaskRow) => void;
  onDelete?: (taskRow?: TaskRow) => void;
  isDashboard?: boolean;
  isEditTask?: boolean;
  taskUID?: string;
  moduleName?: string;
  onFocusClicked?: (relatedTo: string, relatedUID: string) => void;
  relatedCommentsUpdated?: (isRelatedCommentsUpdated?: boolean) => void;
  isDetailsMode?: boolean;
}

interface IState {
  isActive: boolean;
  taskRow: TaskRow;
  usersAndTeams: LookUpGroupRow[];
  taskUID: string;
  isEditTask?: boolean;
  updatedTaskRow?: TaskRow;
  isFocused?: boolean;
  showAddToFocusListDialog?: boolean;
  documentSummaryKey?: number;
  activitySummaryKey?: number;
  taskDetailsSectionKey?: number;
}

class TaskDetails extends Component<
  IDialogProps & RAFFormComponentProps & NavigateParamsProps & IProps,
  IState
> {
  public rafForm: FormRenderProps | null;
  public rafRecurringForm: FormRenderProps | null;
  public submitButton: ButtonComponent;
  animationSettings: AnimationSettingsModel;
  private deleteDialog: any;
  _isMounted = false;

  tooltipObj: TooltipComponent;
  dueDateDialog: DialogComponent;
  deleteFocusListDialog: any;
  colmpleteTaskAlertDialog: any;
  private myTeams: UserGroupRow[] = [];
  private moduleName: string = createInstance(TaskRow).getClassName();

  constructor(props) {
    super(props);
    this.animationSettings = { effect: "Fade" };
    this.state = {
      isActive: false,
      isEditTask: false,
      isFocused: false,
      showAddToFocusListDialog: false,
      updatedTaskRow: null,
      taskRow: {},
      usersAndTeams: [],
      taskUID: isNotNullAndUndefined(this.props.taskUID)
        ? this.props.taskUID
        : null,
      documentSummaryKey: Math.random(),
      activitySummaryKey: Math.random(),
      taskDetailsSectionKey: Math.random(),
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.refresh(
      this.props.taskUID,
      this.props.isEditTask,
      this.props.isActive
    );
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate = (prevProps /*, prevState*/) => {
    if (
      !deepEqual(prevProps.isActive, this.props.isActive) ||
      !deepEqual(prevProps.taskUID, this.props.taskUID) ||
      !deepEqual(prevProps.isEditTask, this.props.isEditTask)
    ) {
      this.refresh(
        this.props.taskUID,
        this.props.isEditTask,
        this.props.isActive
      );
    }
  };

  enableSubmitButton = (g: FormRenderProps | null) => {
    if (g) {
      if (isNotNullAndUndefined(this.submitButton)) {
        this.submitButton.disabled = !g.valid;
      }
    }
  };

  onSubmitTask = (value) => {
    let taskRow: TaskRow = value as TaskRow;
    let taskToSubmit;

    if (
      isNotNullAndUndefined(taskRow.Reminders) &&
      taskRow.Reminders.length > 0
    ) {
      let taskToCreateDto: TaskReminderRow = generateTaskReminderRow(taskRow);
      taskToCreateDto = ConvertTaskRowToITaskReminderRow(taskToCreateDto);
      taskToSubmit = taskToCreateDto;
    } else {
      let taskToCreateDto: ITaskDto = ConvertTaskRowToTaskDto(taskRow);
      taskToSubmit = taskToCreateDto;
    }

    if (isNullOrUndefined(taskToSubmit.IsApprovalTask)) {
      taskToSubmit.IsApprovalTask = false;
    }

    if (
      taskToSubmit.RelatedToUID === null ||
      taskToSubmit.RelatedToType === null
    ) {
      taskToSubmit.RelatedToType = null;
      taskToSubmit.RelatedToUID = null;
      taskToSubmit.RelatedTo = null;
    }

    if (isNullOrUndefined(taskToSubmit.TaskDate)) {
      taskToSubmit.TaskDate = Constants.MaxDate;
    }

    if (
      isNotNullAndUndefined(taskToSubmit.Priority) &&
      taskToSubmit.Priority === "Normal"
    )
      taskToSubmit.Priority = null;

    let progressDiv = showProgress("#TaskDetailsSection");
    repositoryActions
      .postDataAndGetResponse(
        "Task/SaveTaskWithReminders",
        getSaveRequest(taskToSubmit, taskToSubmit.UID),
        { ...this.props },
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          showSuccessToast("Saved successfully");
          //if (this._isMounted) {
          //    this.setState({ isActive: false }, () => {
          //        if (this.props.onUpdate) {
          //            this.props.onUpdate(taskToCreateDto);
          //        }
          //    })
          //}
          if (
            isNotNullAndUndefined(this.props.isEditTask) &&
            this.props.isEditTask === true
          ) {
            hideProgress(progressDiv);
            if (this.props.onUpdate) {
              this.props.onUpdate(taskToSubmit);
            }
          } else {
            if (this._isMounted) {
              this.setState(
                { isEditTask: false, taskRow: taskToSubmit },
                () => {
                  this.getTaskonSubmit(taskToSubmit.UID, progressDiv);
                }
              );
            }
          }
        } else {
          hideProgress(progressDiv);
        }
      })
      .catch((error) => error);
  };

  getTaskonSubmit = (uid, progressDiv) => {
    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];

    let filter: RAFCustomFilter = {};
    let filterVal: string[] = [];
    filterVal.push(uid);
    filter.Operator = RAFCustomOperator.Equal;
    filter.Value = filterVal;
    filter.Field = propertyOf<ReminderRow>("RelatedToUID");
    relatedFilter.Rules.push(filter);

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = relatedFilter;
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;

    if (this._isMounted) {
      repositoryActions
        .postDataAndGetResponse(
          "Task/Retrieve",
          { EntityId: uid },
          { ...this.props },
          ContentType.applicationJson
        )
        .then((response) => {
          repositoryActions
            .postDataAndGetResponse(
              "Reminder/List",
              listServiceRequest,
              { ...this.props },
              ContentType.applicationJson
            )
            .then((reminderResponse) => {
              let reminderList: ReminderRow[] =
                reminderResponse &&
                reminderResponse.data &&
                reminderResponse.data.Entities;
              if (
                isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.data)
              ) {
                const taskRowDto: TaskRow = response.data.Entity;
                const taskRow: TaskRow = ConvertTaskDtoToTaskRow(taskRowDto);
                taskRow.Reminders = getTaskRemindersRow(reminderList);
                getFocusListByRelatedUID(taskRow.UID).then(() => {
                  if (
                    isNotNullAndUndefined(taskRow) &&
                    taskRow.Priority === null
                  )
                    taskRow.Priority = "Normal";
                  if (this._isMounted) {
                    this.setState(
                      {
                        updatedTaskRow: taskRow,
                        taskRow: taskRow,
                        taskDetailsSectionKey: Math.random(),
                      },
                      () => {
                        //this.toggleEditDetails();
                        hideProgress(progressDiv);
                      }
                    );
                  }
                });
              } else {
                hideProgress(progressDiv);
              }
            });
        });
    }
  };

  refresh = (id, isEditTask, isActive) => {
    if (isActive) {
      let progressDiv = showProgress("#TaskDetailsSection");

      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push(id);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = propertyOf<ReminderRow>("RelatedToUID");
      relatedFilter.Rules.push(filter);

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      initUserAndTeam()
        .then((usersAndTeams) => {
          repositoryActions
            .postDataAndGetResponse(
              "Task/Retrieve",
              { EntityId: id },
              { ...this.props },
              ContentType.applicationJson
            )
            .then((response) => {
              repositoryActions
                .postDataAndGetResponse(
                  "Reminder/List",
                  listServiceRequest,
                  { ...this.props },
                  ContentType.applicationJson
                )
                .then((reminderResponse) => {
                  let reminderList: ReminderRow[] =
                    reminderResponse &&
                    reminderResponse.data &&
                    reminderResponse.data.Entities;
                  if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data)
                  ) {
                    const taskRowDto: TaskRow = response.data.Entity;
                    const taskRow: TaskRow =
                      ConvertTaskDtoToTaskRow(taskRowDto);
                    taskRow.Reminders = getTaskRemindersRow(reminderList);
                    getFocusListByRelatedUID(taskRow.UID).then((isFocused) => {
                      hideProgress(progressDiv);
                      if (
                        isNotNullAndUndefined(taskRow) &&
                        taskRow.Priority === null
                      )
                        taskRow.Priority = "Normal";
                      if (isNotNullAndUndefined(usersAndTeams)) {
                        if (this._isMounted) {
                          this.setState({
                            usersAndTeams: usersAndTeams,
                            taskRow,
                            isActive,
                            isFocused,
                            isEditTask,
                          });
                        }
                      }
                    });
                  }
                });
            });
        })
        .catch((error) => error);
    } else {
      if (this._isMounted) {
        this.setState({ taskRow: null, isActive });
      }
    }
  };

  onModifyQuickDateSelected = (days, date) => {
    this.rafForm.form.mutators.setValue("TaskDate", date);
  };

  DeleteTaskClicked(taskRow: TaskRow) {
    this.deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: this.DeleteTask.bind(this, taskRow) },
      showCloseIcon: false,
      title: " Delete Task",
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
      // cssClass: "alert-dialog",
    });
  }

  DeleteTask = (taskRow: TaskRow) => {
    // let progressDiv = showProgress(".alert-dialog");
    let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
    DeleteRecordRemoveFocus(taskRow.UID, this.moduleName).then((response) => {
      hideProgress(progressDiv);
      if (isNotNullAndUndefined(response)) {
        this.deleteDialog.hide();
        if (this._isMounted) {
          this.setState({ isActive: false }, () => {
            if (this.props.onDelete) {
              this.props.onDelete();
            } else if (this.props.onUpdate) {
              this.props.onUpdate();
            }
          });
        }
      }
    });
  };

  onClickCompleteTask = (taskRow: TaskRow, taskStatus: string) => {
    let isMyTeamExist = isNotNullAndUndefined(taskRow.AssignTeamUID)
      ? this.myTeams.find((x) => x.UID === taskRow.AssignTeamUID)
      : null;

    if (
      (isNotNullAndUndefined(taskRow.AssigneeUID) &&
        taskRow.AssigneeUID === msalInstance.currentUserId) ||
      (isNotNullAndUndefined(taskRow.AssignTeamUID) &&
        isNotNullAndUndefined(isMyTeamExist))
    ) {
      if (this.props.complete) this.props.complete(taskRow, taskStatus);
    } else {
      this.ColmpleteTaskAlert(taskRow, taskStatus);
    }
  };

  completeTask = (taskRow: TaskRow, taskStatus: string) => {
    if (this._isMounted) {
      this.colmpleteTaskAlertDialog && this.colmpleteTaskAlertDialog.hide();
      this.setState({ isActive: false }, () => {
        if (this.props.complete) this.props.complete(taskRow, taskStatus);
      });
    }
  };

  ColmpleteTaskAlert(taskRow: TaskRow, taskStatus: string) {
    this.colmpleteTaskAlertDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content:
        "Are you sure you want to complete this task assigned to another user?",
      okButton: {
        text: "Yes",
        click: this.completeTask.bind(this, taskRow, taskStatus),
      },
      showCloseIcon: false,
      title: " Complete Task",
      position: { X: "center", Y: "center" },
      cssClass: "alert-dialog",
    });
  }

  onMoreMenuClicked(args: MenuEventArgs) {
    let taskRow: TaskRow = this.state.taskRow as TaskRow;
    if (args.item.id === "focuslist") {
      this.onFocusClicked(taskRow.Title, taskRow.UID);
    }
    if (args.item.id === "Delete") {
      this.DeleteTaskClicked(taskRow);
    }
    if (args.item.id === "Edit") {
      this.toggleEditDetails();
    }
  }

  toggleEditDetails = () => {
    if (
      isNotNullAndUndefined(this.props.isEditTask) &&
      this.props.isEditTask === true
    ) {
      if (this.props.onClose) {
        this.props.onClose();
      }
    } else {
      if (this.state.isEditTask === true) {
        this.setState({
          isEditTask: false,
          taskDetailsSectionKey: Math.random(),
        });
      } else {
        this.setState({
          isEditTask: true,
          taskDetailsSectionKey: Math.random(),
        });
      }
    }
  };

  onCloseDialogClick = () => {
    if (
      isNotNullAndUndefined(this.props.isEditTask) &&
      this.props.isEditTask === true
    ) {
      if (this.props.onClose) {
        this.props.onClose();
      }
    } else {
      if (isNotNullAndUndefined(this.state.updatedTaskRow)) {
        if (this.props.onUpdate) {
          this.props.onUpdate(this.state.updatedTaskRow);
        }
      } else {
        if (this.props.onClose) {
          this.props.onClose();
        }
      }
    }
  };

  onFocusClicked = (relatedTo: string, relatedUID: string) => {
    /*if (this.props.onFocusClicked) {
                this.props.onFocusClicked(relatedTo, relatedUID);*/
    //getFocusListByRelatedUID(relatedUID).then(isFocused => {
    if (this.state.isFocused) {
      this.RemoveFromFocusList(relatedUID);
    } else {
      this.AddToFocusList(relatedTo, relatedUID);
    }
    //});
    this.showAddToFocusListDialog();
  };

  showAddToFocusListDialog = () => {
    if (this._isMounted) {
      this.setState({ showAddToFocusListDialog: true });
    }
  };

  closeAddToFocusListDialog = () => {
    if (this._isMounted) {
      this.setState({ showAddToFocusListDialog: false });
    }
  };

  AddToFocusList = (relatedTo: string, relatedUID: string) => {
    if (isNotNullAndUndefined(relatedTo) && isNotNullAndUndefined(relatedUID)) {
      let progressDiv = showProgress("#taskDetailsFooter");
      addToFocusList(relatedTo, this.props.moduleName, relatedUID).then(
        (response) => {
          hideProgress(progressDiv);
          if (isNotNullAndUndefined(response)) {
            showSuccessToast("Added to my focus list");
            this.setState({ isFocused: true });
          }
        }
      );
    }
  };

  RemoveFromFocusList(relatedUID: string) {
    this.deleteFocusListDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to remove from my focus list?",
      okButton: {
        text: "Yes",
        click: this.onRemoveFocusList.bind(this, relatedUID),
      },
      showCloseIcon: false,
      title: "Remove Item",
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
      // cssClass: "alert-dialog",
    });
  }

  onRemoveFocusList = (relatedUID: string) => {
    // let progressDiv = showProgress(".alert-dialog");
    let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
    removeFromFocusList(relatedUID).then((response) => {
      hideProgress(progressDiv);
      if (isNotNullAndUndefined(response)) {
        showSuccessToast("Removed from my focus list");
        this.deleteFocusListDialog.hide();
        this.setState({ isFocused: false });
      }
    });
  };

  updateactivitySummaryKey = () => {
    this.setState({ activitySummaryKey: Math.random() }, () => {
      if (this.props.relatedCommentsUpdated) {
        this.props.relatedCommentsUpdated(true);
      }
    });
  };

  onChangeTaskDate = (value) => {
    let taskDueDate = getTaskDueDate(null, value);
    setFormValue(this.rafForm, "DueDate", taskDueDate);
  };

  render() {
    let { taskRow, isActive, isEditTask, showAddToFocusListDialog } =
      this.state;
    let moduleName = isNotNullAndUndefined(this.props.moduleName)
      ? this.props.moduleName
      : null;
    let loggedinUserId = msalInstance.currentUserId;
    let isFocused: boolean = this.state.isFocused;
    let iconName = "";
    let text = "";
    let className = "";
    let url = "";
    let avatarClassName = "";

    let currentTenantName = msalInstance.currentTenantName;

    switch (taskRow.RelatedToType) {
      case "contact":
        iconName = "address-card";
        text = "Contact";
        className = "contact-badge";
        avatarClassName = "contact-avatar";
        url = "/Contact/View/";
        break;
      case "account":
        iconName = "building";
        text = "Company";
        className = "company-badge";
        avatarClassName = "company-avatar";
        url = "/Company/View/";
        break;
      case "deal":
        iconName = "circle-dollar-to-slot";
        text = "Deal";
        className = "deal-badge";
        avatarClassName = "deal-avatar";
        url = "/Deal/View/";
        break;
      case "ticket":
        iconName = "ticket-alt";
        text = "Ticket";
        className = "ticket-badge";
        avatarClassName = "ticket-avatar";
        url = "/Ticket/View/";
        break;
      case "business_process":
        iconName = "server";
        text = "Process";
        className = "ticket-badge";
        avatarClassName = "bp-avatar avatar-text";
        url = "/Process/Manage/";
        break;
      case "complaint":
        iconName = "exclamation-triangle";
        text = "Complaint";
        className = "grey lighten-1 badge-grey";
        avatarClassName = "company-avatar";
        url = "/Complaint/View/";
        break;
      default:
        iconName = "";
        text = "Not set";
        className = "grey lighten-1 badge-grey";
        avatarClassName = "";
        url = "";
        break;
    }

    let relatedTo = (
      <div>
        <div className="d-flex align-items-center">
          <TooltipComponent content={text} position="BottomCenter">
            {iconName !== "" && (
              <span
                className={
                  "avatar-text-circle transparent-avatar " + avatarClassName
                }
                style={{
                  width: "24px",
                  height: "24px",
                  minWidth: "24px",
                  maxWidth: "24px",
                  marginRight: "5px",
                  fontSize: "12px",
                }}
              >
                <i className={"fa fa-" + iconName + " "}></i>
              </span>
            )}
          </TooltipComponent>
        </div>
      </div>
    );

    return (
      <MyTeamsContext.Consumer>
        {({ teams }) => {
          this.myTeams = teams;

          if (
            isActive === true &&
            taskRow !== null &&
            taskRow !== undefined &&
            !IsNullOrWhiteSpace(taskRow.UID) &&
            isEditTask === false
          ) {
            return (
              <div
                className="taskDetails h-100"
                id="TaskDetailsSection"
                key={this.state.taskDetailsSectionKey}
              >
                {/*<div className="e-dlg-headerContent" id="TaskDetailsSection_dialog-header">
                        <div className="d-flex justify-content-between w-100">
                            <div className="e-dlg-header" id="_title">Task Details</div>
                        </div>
                        
                        <button type="button" className="btn-close" onClick={() => this.onCloseDialogClick()}></button>
                    </div>*/}
                <div
                  className={
                    this.props.isDetailsMode
                      ? "h-100 d-flex flex-column bg-white"
                      : "dlg-new-style"
                  }
                >
                  {this.props.isDetailsMode !== true && (
                    <div
                      className={
                        this.props.isDetailsMode
                          ? "sectionHeader border-bottom d-flex align-items-center justify-content-between"
                          : "e-dlg-headerContent justify-content-between"
                      }
                      id="taskUpdateDialog_dialog-header"
                    >
                      <div
                        className={
                          this.props.isDetailsMode
                            ? "header-text"
                            : "e-dlg-header"
                        }
                        id="_title"
                      >
                        {" "}
                        {taskRow.Title}
                      </div>
                      {this.props.isDetailsMode ? (
                        <ButtonComponent
                          type="button"
                          cssClass="e-small link-button grey-link-button unset-custom-button-md"
                          iconCss="fas fa-xmark"
                          onClick={() => {
                            this.onCloseDialogClick();
                          }}
                        ></ButtonComponent>
                      ) : (
                        <ButtonComponent
                          type="button"
                          cssClass="primary-custom-button"
                          iconCss="fas fa-xmark"
                          onClick={() => this.onCloseDialogClick()}
                        ></ButtonComponent>
                      )}
                    </div>
                  )}

                  <div
                    className={
                      this.props.isDetailsMode
                        ? "p-3 h-100 overflow-auto customScrollBar"
                        : "e-dlg-body-content taskUpdateForm pt-3 mt-0 h-100"
                    }
                    style={{ paddingBottom: "0px" }}
                  >
                    <div className="additionalInfoDiv d-flex flex-column text-align-center mb-3 border">
                      <RAFDetailFieldEditable
                        title="Title"
                        disabled
                        value={
                          isNotNullAndUndefined(taskRow.Title)
                            ? taskRow.Title
                            : "Not set"
                        }
                        valueClassName="labelPrimartyText"
                        type={RAFInputType.Text}
                        name="Title"
                      ></RAFDetailFieldEditable>
                      <RAFDetailFieldEditable
                        title="Due date"
                        disabled
                        value={
                          isNotNullAndUndefined(taskRow.TaskDate) &&
                            Constants.MaxDate.valueOf() !==
                            convertUTCDateToLocalTimezone(
                              taskRow.TaskDate
                            ).valueOf()
                            ? taskRow.TaskDate
                            : "Unplanned"
                        }
                        valueClassName="labelPrimartyText"
                        type={
                          isNotNullAndUndefined(taskRow.TaskDate) &&
                            Constants.MaxDate.valueOf() !==
                            convertUTCDateToLocalTimezone(
                              taskRow.TaskDate
                            ).valueOf()
                            ? RAFInputType.Date
                            : RAFInputType.Text
                        }
                        name="TaskDate"
                      ></RAFDetailFieldEditable>
                      {isNotNullAndUndefined(taskRow.AssigneeUID) &&
                        taskRow.AssigneeUID !== msalInstance.currentUserId && (
                          <RAFDetailFieldEditable
                            title="ASSIGNED TO"
                            disabled
                            value={
                              isNotNullAndUndefined(taskRow.Assignee)
                                ? taskRow.Assignee
                                : "Not set"
                            }
                            valueClassName="labelPrimartyText"
                            type={RAFInputType.Text}
                            name="Assignee"
                          ></RAFDetailFieldEditable>
                        )}
                      <div className="row">
                        <div className="col-12">
                          <RAFDetailFieldEditable
                            title="Priority"
                            disabled
                            value={
                              isNotNullAndUndefined(taskRow.Priority)
                                ? taskRow.Priority
                                : "Not set"
                            }
                            valueClassName="labelPrimartyText"
                            type={RAFInputType.Text}
                            name="Priority"
                          ></RAFDetailFieldEditable>
                        </div>
                        {currentTenantName !== "skytrust" && (
                          <div className="col-12">
                            <RAFDetailFieldEditable
                              title="Recurring"
                              disabled
                              value={
                                !IsNullOrWhiteSpace(taskRow.RRuleExpression)
                                  ? RRule.fromString(
                                    taskRow.RRuleExpression.slice(
                                      0,
                                      taskRow.RRuleExpression.length - 1
                                    )
                                  ).toText()
                                  : "Never"
                              }
                              valueClassName="labelPrimartyText"
                              type={RAFInputType.Text}
                              name="CronExpression"
                            ></RAFDetailFieldEditable>
                          </div>
                        )}
                      </div>
                      <RAFDetailFieldEditable
                        title="Related To"
                        disabled
                        customValue={
                          <div className="g-0 w-100 row p-2 text-wrap">
                            <span className="m-0">
                              <div className="swapTextwithHyperlink d-flex align-items-center">
                                {relatedTo}
                                {isNotNullAndUndefined(taskRow.RelatedTo) ? (
                                  <div className="d-flex">
                                    <span className="m-0 taskPrimartyText">
                                      {taskRow.RelatedTo}
                                    </span>
                                  </div>
                                ) : (
                                  <div className="d-flex">
                                    <span className="m-0 ms-1 taskPrimartyText">
                                      Not set
                                    </span>
                                  </div>
                                )}
                              </div>
                            </span>
                          </div>
                        }
                        valueClassName="labelPrimartyText"
                        type={RAFInputType.Text}
                        name="RelatedTo"
                      ></RAFDetailFieldEditable>
                      <RAFDetailFieldEditable
                        title="Description"
                        disabled
                        value={
                          isNotNullAndUndefined(taskRow.Description)
                            ? taskRow.Description
                            : "Not set"
                        }
                        valueClassName="labelPrimartyText"
                        type={RAFInputType.Text}
                        name="Description"
                      ></RAFDetailFieldEditable>
                      {isNotNullAndUndefined(taskRow.Reminders) &&
                        taskRow.Reminders.length > 0 ? (
                        taskRow.Reminders.map((item, index) => {
                          return (
                            <RAFDetailFieldEditable
                              key={item.UID}
                              title={item.ReminderType}
                              disabled
                              value={item.RemindDate}
                              valueClassName="labelPrimartyText"
                              type={RAFInputType.Date}
                              name="Reminders"
                            ></RAFDetailFieldEditable>
                          );
                        })
                      ) : (
                        <RAFDetailFieldEditable
                          title="Reminders"
                          disabled
                          value={"Not set"}
                          valueClassName="labelPrimartyText"
                          type={RAFInputType.Text}
                          name="Reminders"
                        ></RAFDetailFieldEditable>
                      )}

                      {/*<RAFDetailField title="TITLE" value={isNotNullAndUndefined(taskRow.Title) ? taskRow.Title : "Not set"} valueClassName="taskPrimartyText" rowClassName="detailsFieldDiv" labelDivClassName="col-sm-5 col-md-4 col-xl-3 editable-detailsField-label" valueDivClassName="col-sm-7 col-md-8 col-xl-9 p-0 d-flex align-items-center editable-detailsField-value" />
                                    <RAFDetailField title="Due date" value={taskDueDate} valueClassName="taskPrimartyText" labelDivClassName="col-md-4 py-2" valueDivClassName="col-md-8 py-2" />
                                    {isNotNullAndUndefined(taskRow.AssigneeUID) && taskRow.AssigneeUID !== msalInstance.currentUserId &&
                                        <RAFDetailField title="ASSIGNED TO" value={isNotNullAndUndefined(taskRow.Assignee) ? taskRow.Assignee : "Not set"} valueClassName="taskPrimartyText" labelDivClassName="col-md-4 py-2" valueDivClassName="col-md-8 py-2" />
                                    }
                                    <RAFDetailField title="PRIORITY" value={isNotNullAndUndefined(taskRow.Priority) ? taskRow.Priority : "Not set"} valueClassName="taskPrimartyText" labelDivClassName="col-md-4 py-2" valueDivClassName="col-md-8 py-2" />
                                    <RAFDetailField title="Related To" valueClassName="taskPrimartyText" labelDivClassName="col-md-4 py-2" valueDivClassName="col-md-8 py-2"
                                        customValue={
                                            <div className="g-0 w-100 row pe-1 text-wrap">
                                                <span className="m-0">
                                                    <div className="swapTextwithHyperlink d-flex align-items-center">
                                                        {relatedTo}
                                                        {isNotNullAndUndefined(taskRow.RelatedTo) ?
                                                            <div>
                                                                <span className="m-0 taskPrimartyText">{taskRow.RelatedTo}</span>
                                                            </div>
                                                            :
                                                            <div>
                                                                <span className="m-0 taskPrimartyText">Not set</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </span>
                                            </div>
                                        } />
                                    <RAFDetailField title="Description" value={isNotNullAndUndefined(taskRow.Description) ? taskRow.Description : "Not set"} valueClassName="taskPrimartyText" labelDivClassName="col-md-4 py-2" valueDivClassName="col-md-8 py-2" />*/}
                    </div>
                    <div className="mt-0 comments-details-page">
                      <div>
                        <div className="e-card mb-3" id="comments">
                          <div className="e-card-content py-0 px-0">
                            <RAFCollapse
                              title="Comments"
                              iconName="Comments"
                              contentCardClassName="relatedListOuterDiv p-0"
                              layout={RAFLayout.OneColumnLayout}
                              IsCollapsed={false}
                              removeContentCardPadding
                            >
                              <div
                                className="pt-3 px-3 pb-2"
                                style={{ background: "#fff" }}
                              >
                                <CreateActivity
                                  onUpdate={() => {
                                    this.updateactivitySummaryKey();
                                  }}
                                  relatedToUID={taskRow.RelatedToUID}
                                  relatedTo={taskRow.RelatedTo}
                                  entityName={taskRow.RelatedToType}
                                  secondaryRelatedToUID={taskRow.UID}
                                  secondaryRelatedTo={taskRow.Title}
                                  secondaryRelatedToType={RAFEntityName.Task}
                                />
                              </div>
                              <div className="px-3">
                                <ActivitySummary
                                  key={this.state.activitySummaryKey}
                                  //relatedToUID={taskRow.RelatedToUID}
                                  relatedCommentsUpdated={() => {
                                    if (this.props.relatedCommentsUpdated) {
                                      this.props.relatedCommentsUpdated(true);
                                    }
                                  }}
                                  entityName={taskRow.RelatedToType}
                                  secondaryRelatedToUID={taskRow.UID}
                                  uiMode="table"
                                  {...this.props}
                                />
                              </div>
                            </RAFCollapse>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-0 document-details-page">
                      <div>
                        <div className="e-card" id="documents">
                          <div className="e-card-content py-0 px-0">
                            <DocumentSummary
                              key={this.state.documentSummaryKey}
                              allowFiltering={false}
                              isRelatedSection
                              relatedUID={taskRow.UID}
                              relatedTo={taskRow.Title}
                              relatedToType={this.moduleName}
                              moduleName={RAFModuleName.Task}
                              showFilterBtn={false}
                              isRelatedDocument
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      this.props.isDetailsMode
                        ? "p-2 border-top d-flex align-items-center justify-content-end"
                        : "e-dlg-footerContent"
                    }
                    id="taskDetailsFooter"
                  >
                    <div className="row g-2">
                      {taskRow.TaskStatus === RAFActionStatus.Planned &&
                        taskRow.TaskType === RAFTaskType.ToDo && (
                          <div className="col-auto">
                            <div className="row g-2">
                              <div className="col-auto d-none d-sm-block">
                                <ButtonComponent
                                  type="button"
                                  className="e-primary"
                                  onClick={() =>
                                    this.onClickCompleteTask(
                                      taskRow,
                                      RAFActionStatus.Completed
                                    )
                                  }
                                >
                                  Complete
                                </ButtonComponent>
                              </div>
                              <div className="col-auto d-sm-none">
                                <ButtonComponent
                                  type="button"
                                  className="e-primary"
                                  iconCss="fas fa-check"
                                  onClick={() =>
                                    this.onClickCompleteTask(
                                      taskRow,
                                      RAFActionStatus.Completed
                                    )
                                  }
                                ></ButtonComponent>
                              </div>
                            </div>
                          </div>
                        )}
                      {taskRow.TaskType === RAFTaskType.Approval &&
                        taskRow.AssigneeUID === loggedinUserId && (
                          <div className="col-auto">
                            <div className="row g-2">
                              <div className="d-none d-sm-inline-block col-auto">
                                <ButtonComponent
                                  type="button"
                                  className="e-success"
                                  onClick={() =>
                                    this.onClickCompleteTask(
                                      taskRow,
                                      RAFActionStatus.Approved
                                    )
                                  }
                                >
                                  {" "}
                                  Approve
                                </ButtonComponent>
                              </div>
                              <div className="col-auto d-none d-sm-inline-block">
                                <ButtonComponent
                                  type="button"
                                  className="e-danger"
                                  onClick={() =>
                                    this.onClickCompleteTask(
                                      taskRow,
                                      RAFActionStatus.Rejected
                                    )
                                  }
                                >
                                  {" "}
                                  Reject
                                </ButtonComponent>
                              </div>
                              <div className="col-auto d-sm-none">
                                <ButtonComponent
                                  type="button"
                                  className="e-success"
                                  iconCss="fas fa-thumbs-up"
                                  onClick={() =>
                                    this.onClickCompleteTask(
                                      taskRow,
                                      RAFActionStatus.Approved
                                    )
                                  }
                                ></ButtonComponent>
                              </div>
                              <div className="col-auto d-sm-none">
                                <ButtonComponent
                                  type="button"
                                  className="e-danger"
                                  iconCss="fas fa-thumbs-down"
                                  onClick={() =>
                                    this.onClickCompleteTask(
                                      taskRow,
                                      RAFActionStatus.Rejected
                                    )
                                  }
                                ></ButtonComponent>
                              </div>
                            </div>
                          </div>
                        )}
                      {taskRow.TaskType === RAFTaskType.Review &&
                        taskRow.AssigneeUID === loggedinUserId && (
                          <div className="col-auto">
                            <div className="row g-2">
                              <div className="col-auto d-none d-sm-inline-block">
                                <ButtonComponent
                                  type="button"
                                  className="e-success"
                                >
                                  {" "}
                                  Review
                                </ButtonComponent>
                              </div>
                              <div className="col-auto d-sm-none">
                                <ButtonComponent
                                  type="button"
                                  iconCss="fal fa-pencil"
                                  className="e-success"
                                >
                                  {" "}
                                </ButtonComponent>
                              </div>
                            </div>
                          </div>
                        )}
                      {taskRow.TaskStatus === RAFActionStatus.Planned && (
                        <div className="col-auto">
                          <RAFPermissionRender
                            permissionName={PermissionConstants.TaskManage}
                          >
                            <ButtonComponent
                              type="button"
                              cssClass="primary-custom-button e-default"
                              onClick={() => this.toggleEditDetails()}
                            >
                              Edit
                            </ButtonComponent>
                          </RAFPermissionRender>
                        </div>
                      )}
                      {/*{taskRow.TaskStatus === RAFActionStatus.Planned &&
                                    <TooltipComponent content={isFocused ? 'Remove from focus list' : 'Add to focus list'} position="BottomCenter">
                                        <ButtonComponent type="button" iconCss="fas fa-bookmark" cssClass={isFocused ? 'd-sm-inline-block d-none primary-custom-button focus-icon-btn task-icon-btn e-default mx-2 imageWithoutTextBtn active' : 'd-sm-inline-block d-none primary-custom-button focus-icon-btn task-icon-btn e-default mx-2 imageWithoutTextBtn'} onClick={(e) => { e.stopPropagation(); this.onFocusClicked(taskRow.Title, taskRow.UID) }}>
                                        </ButtonComponent>
                                    </TooltipComponent>
                                }*/}
                      <div className="col-auto">
                        <RAFRecordInfo
                          createdBy={taskRow.CreatedBy}
                          createdDate={taskRow.CreatedDate}
                          modifiedDate={taskRow.ModifiedDate}
                          modifiedBy={taskRow.ModifiedBy}
                          clockIconBtn={"normal"}
                        ></RAFRecordInfo>
                      </div>
                      {taskRow.TaskStatus === RAFActionStatus.Planned && (
                        <div className="col-auto d-none">
                          <DropDownButtonComponent
                            items={[
                              {
                                id: "Delete",
                                text: "Delete",
                                iconCss: "fas fa-trash",
                              },
                            ]}
                            cssClass="primary-custom-button e-flat default e-caret-hide square-btn d-none task-more-button"
                            select={this.onMoreMenuClicked.bind(this)}
                          >
                            <div className="icon-ellipsis-horizontal icon-ellipsis-sm">
                              <span className="icon-ellipsis-dot"></span>
                            </div>
                          </DropDownButtonComponent>
                        </div>
                      )}
                      {taskRow.TaskStatus === RAFActionStatus.Planned && (
                        <div className="col-auto">
                          <div className="row g-2">
                            <div className="col-auto d-none d-sm-inline-block">
                              <DropDownButtonComponent
                                items={[
                                  {
                                    id: "focuslist",
                                    text:
                                      //isFocused ? 'Remove from focus list' :
                                      "Add to focus list",
                                    iconCss: "fas fa-bookmark",
                                  },
                                  {
                                    id: "Delete",
                                    text: "Delete",
                                    iconCss: "fas fa-trash",
                                  },
                                ]}
                                cssClass="primary-custom-button task-more-button"
                                content="More"
                                select={this.onMoreMenuClicked.bind(this)}
                              >
                                <div className="icon-ellipsis-horizontal icon-ellipsis-sm d-none">
                                  <span className="icon-ellipsis-dot"></span>
                                </div>
                              </DropDownButtonComponent>
                            </div>
                            <div className="col-auto d-sm-none">
                              <DropDownButtonComponent
                                items={[
                                  {
                                    id: "focuslist",
                                    text:
                                      //isFocused ? 'Remove from focus list' :
                                      "Add to focus list",
                                    iconCss: "fas fa-bookmark",
                                  },
                                  {
                                    id: "Delete",
                                    text: "Delete",
                                    iconCss: "fas fa-trash",
                                  },
                                ]}
                                cssClass="primary-custom-button e-flat default e-caret-hide square-btn me-2 task-more-button"
                                select={this.onMoreMenuClicked.bind(this)}
                              >
                                <div className="icon-ellipsis-horizontal icon-ellipsis-sm">
                                  <span className="icon-ellipsis-dot"></span>
                                </div>
                              </DropDownButtonComponent>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-auto">
                        {isNotNullAndUndefined(taskRow) && (
                          <RAFAddToFocusList
                            relatedTo={taskRow.Title}
                            relatedToUID={taskRow.UID}
                            relatedToType={this.moduleName}
                            showAddToFocusListDialog={showAddToFocusListDialog}
                            onClose={() => this.closeAddToFocusListDialog()}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else if (
            isActive === true &&
            taskRow !== null &&
            taskRow !== undefined &&
            !IsNullOrWhiteSpace(taskRow.UID) &&
            isEditTask === true
          ) {
            return (
              <div
                className="h-100"
                id="TaskDetailsSection"
                key={this.state.taskDetailsSectionKey}
              >
                <RAFForm
                  className="h-100"
                  type={TaskRow}
                  initialValues={taskRow}
                  formRef={(g) => {
                    this.enableSubmitButton(g);
                    return (this.rafForm = g);
                  }}
                  layout={RAFLayout.TwoColumnLayout}
                  primaryKey={taskRow.UID}
                  onSubmit={this.onSubmitTask}
                >
                  <div
                    className={
                      this.props.isDetailsMode
                        ? "h-100 d-flex flex-column bg-white"
                        : "dlg-new-style"
                    }
                  >
                    {this.props.isDetailsMode !== true && (
                      <div
                        className={
                          this.props.isDetailsMode
                            ? "sectionHeader border-bottom d-flex align-items-center justify-content-between"
                            : "e-dlg-headerContent"
                        }
                        id="TaskDetailsSection_dialog-header"
                      >
                        <div className="d-flex justify-content-between w-100">
                          <div
                            className={
                              this.props.isDetailsMode
                                ? "header-text"
                                : "e-dlg-header"
                            }
                            id="_title"
                          >
                            Modify Task
                          </div>
                        </div>
                        {this.props.isDetailsMode ? (
                          <ButtonComponent
                            type="button"
                            cssClass="e-small link-button grey-link-button unset-custom-button-md"
                            iconCss="fas fa-xmark"
                            onClick={() => {
                              this.toggleEditDetails();
                            }}
                          ></ButtonComponent>
                        ) : (
                          <ButtonComponent
                            type="button"
                            cssClass="primary-custom-button"
                            iconCss="fas fa-xmark"
                            onClick={() => this.toggleEditDetails()}
                          ></ButtonComponent>
                        )}
                        {/* <button type="button" className="btn-close" onClick={() => this.onCloseDialogClick()}></button>*/}
                      </div>
                    )}
                    <div
                      className={
                        this.props.isDetailsMode
                          ? "p-3 h-100 overflow-auto customScrollBar position-relative"
                          : "e-dlg-body-content taskUpdateForm position-relative mt-0 pt-3 h-100 overflow-auto"
                      }
                    >
                      {!Browser.isDevice === true && (
                        <ButtonComponent
                          className="primary-custom-button update-task-close"
                          iconCss="fas fa-right-to-bracket"
                          onClick={() => this.onCloseDialogClick()}
                        ></ButtonComponent>
                      )}
                      <div className="row text-align-center">
                        <div className="col-md-12 form-group align-items-center py-0 titleFieldOuter mb-3">
                          <label className="form-label required">Title</label>
                          {taskRow.RelatedToType !== RAFEntityName.Complaint ? (
                            <ACComboBox
                              field="Title"
                              placeholder="Title"
                              showLabel={false}
                              allowAdd={false}
                              moduleName={RAFEntityName.Task}
                              required
                            >
                              <RAFChoiceOption
                                label="Follow-up call"
                                value="Follow-up call"
                              />
                              <RAFChoiceOption
                                label="Follow-up email"
                                value="Follow-up email"
                              />
                              <RAFChoiceOption
                                label="Send profile"
                                value="Send profile"
                              />
                              <RAFChoiceOption
                                label="Send quote"
                                value="Send quote"
                              />
                            </ACComboBox>
                          ) : (
                            <RAFTextBox<TaskRow>
                              field="Title"
                              showLabel={false}
                              required
                            ></RAFTextBox>
                          )}
                        </div>
                        <div className="col-md-12 form-group align-items-center py-0 mb-3">
                          <label className="form-label required">
                            Due date
                          </label>
                          <RAFDatePickerCustom
                            field="DueDate"
                            label="Due date"
                            showLabel={false}
                            onChanged={(days, date) =>
                              this.onModifyQuickDateSelected(days, date)
                            }
                          ></RAFDatePickerCustom>
                          <RAFDatePicker<TaskRow>
                            field="TaskDate"
                            showLabel={false}
                            label="Due Date"
                            disabled={
                              taskRow.TaskStatus === RAFActionStatus.Planned
                                ? false
                                : true
                            }
                            onChanged={(value) => this.onChangeTaskDate(value)}
                          ></RAFDatePicker>
                        </div>
                        {taskRow.TaskStatus === RAFActionStatus.Planned ? (
                          <div className="col-md-12 d-flex flex-column mb-3">
                            <div>
                              <label className="form-label required">
                                Assigned To
                              </label>
                            </div>
                            <RAFUserTeamDropdown<TaskRow>
                              field="Assignee"
                              label="Assigned To"
                              showLabel={false}
                              usersAndTeams={this.state.usersAndTeams}
                            />
                          </div>
                        ) : null}
                        <div className="col-md-12 d-flex flex-column ">
                          <RAFDropdownCC<TaskRow>
                            field="Priority"
                            label="Priority"
                            iconName="fas fa-flag"
                            showLabel
                            placeholder="Select Priority"
                            isColorOption={false}
                            uitype="colorpicker"
                            mode="iconView"
                            moduleName={"task"}
                            addEmpty={false}
                            clearSelectionOnClick
                          ></RAFDropdownCC>
                        </div>
                        <div className="col-md-12 hidden">
                          <RAFSummaryDetailInlineEdit
                            label="Recurring"
                            fieldName="RRuleExpression"
                            customValue={
                              <div
                                className="d-flex align-items-center"
                                style={{ marginLeft: "5px" }}
                              >
                                <div>
                                  <Field
                                    name="RRuleExpression"
                                    allowNull
                                    parse={(value) =>
                                      value === "" ? null : value
                                    }
                                  >
                                    {({ input /*, meta */ }) => (
                                      <div className="g-0 w-100 row">
                                        <div
                                          className={
                                            "col-12 py-1 detailsField-value-div"
                                          }
                                        >
                                          <span className="m-0">
                                            <span
                                              className="m-0 detailsValue"
                                              style={{
                                                whiteSpace: "normal",
                                                wordBreak: "break-word",
                                              }}
                                            >
                                              {isNotNullAndUndefined(input) &&
                                                !IsNullOrWhiteSpace(input.value)
                                                ? RRule.fromString(
                                                  input.value.slice(
                                                    0,
                                                    input.value.length - 1
                                                  )
                                                ).toText()
                                                : "Never"}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                {/* <div>
                                                                    <ButtonComponent type="button" onClick={() => this.bpRecurringDialogOpen()} cssClass='e-flat e-info e-small link-button mx-2 ms-3'>Configure</ButtonComponent>
                                                                </div> */}
                              </div>
                            }
                            disabled
                          ></RAFSummaryDetailInlineEdit>
                        </div>

                        <div className="col-md-12">
                          <RAFRepeatProcesssDropdown<TaskRow>
                            field="RepeatProcesss"
                            rRuleExpressionPropertyName={propertyOf<TaskRow>('RRuleExpression')}
                            showLabel
                            label="Recurring"
                          />
                        </div>

                        {currentTenantName !== "skytrust" && (
                          <div className="col-md-12">
                            <RAFReminderDropdown<TaskRow>
                              field="Reminders"
                              showLabel
                              label="Reminder"
                              checkUnique={false}
                            />
                          </div>
                        )}
                        <Condition when="IsApprovalTask" is="No">
                          <div className="col-md-12 d-flex flex-column my-3">
                            <ButtonComponent
                              iconCss="fas fa-check"
                              cssClass="btn-muted btn-active-success"
                            >
                              Mark Complete
                            </ButtonComponent>
                          </div>
                        </Condition>
                        <Condition when="IsApprovalTask" is="Yes">
                          <div className="col-md-12 d-flex mb-3">
                            <ButtonComponent
                              iconCss="far fa-check-circle"
                              cssClass="btn-muted btn-active-primary w-100 m-2 ms-0"
                            >
                              Approve
                            </ButtonComponent>
                            <ButtonComponent
                              iconCss="far fa-times-circle"
                              cssClass="btn-muted btn-active-danger w-100 m-2"
                            >
                              Reject
                            </ButtonComponent>
                            <ButtonComponent cssClass="btn-muted btn-active-warning w-100 m-2 me-0">
                              <span className="position-relative">
                                <i className="fas fa-redo e-btn-icon e-icon-left"></i>
                                <i
                                  className="fas fa-check position-absolute"
                                  style={{
                                    top: "calc(50% - 2px)",
                                    left: "4px",
                                    fontSize: "6px",
                                  }}
                                ></i>
                              </span>
                              Request Changes
                            </ButtonComponent>
                          </div>
                        </Condition>
                        {/*{taskRow.TaskStatus === RAFActionStatus.Planned && moduleName === "dashboard" && taskRow.RelatedToType !== 'business_process' ?
                                        <div className="row g-3 mt-1">
                                            <div className="col-12 mt-0 d-flex">
                                                <label className="form-label" >Link this task to</label>
                                            </div>
                                            <div className="col-sm-4 col-xl-3 mt-0">
                                                <RAFDropdown<TaskRow> field="RelatedToType" showLabel={false} label="Link this task to" placeholder="Select Relatedto">
                                                    <RAFChoiceOption value="contact" label="Contact" />
                                                    <RAFChoiceOption value="account" label="Company" />
                                                    <RAFChoiceOption value="deal" label="Deal" />
                                                    <RAFChoiceOption value="ticket" label="Ticket" />
                                                    <RAFChoiceOption value="complaint" label="Complaint" />
                                                </RAFDropdown>
                                                <WhenFieldChangesTo field="RelatedToType" becomes="contact" set={'RelatedTo'} to={null} />
                                                <WhenFieldChangesTo field="RelatedToType" becomes="contact" set={'RelatedToUID'} to={null} />
                                                <WhenFieldChangesTo field="RelatedToType" becomes="account" set={'RelatedTo'} to={null} />
                                                <WhenFieldChangesTo field="RelatedToType" becomes="account" set={'RelatedToUID'} to={null} />
                                                <WhenFieldChangesTo field="RelatedToType" becomes="deal" set={'RelatedTo'} to={null} />
                                                <WhenFieldChangesTo field="RelatedToType" becomes="deal" set={'RelatedToUID'} to={null} />
                                                <WhenFieldChangesTo field="RelatedToType" becomes="ticket" set={'RelatedTo'} to={null} />
                                                <WhenFieldChangesTo field="RelatedToType" becomes="ticket" set={'RelatedToUID'} to={null} />
                                                <WhenFieldChangesTo field="RelatedToType" becomes="complaint" set={'RelatedTo'} to={null} />
                                                <WhenFieldChangesTo field="RelatedToType" becomes="complaint" set={'RelatedToUID'} to={null} />
                                                <WhenFieldChangesTo field="RelatedToType" becomes={null} set={'RelatedTo'} to={null} />
                                                <WhenFieldChangesTo field="RelatedToType" becomes={null} set={'RelatedToUID'} to={null} />
                                            </div>
                                            <div className="col-sm-8 col-xl-9 mt-0">
                                                <Condition when="RelatedToType" is="contact">
                                                    <RAFLookup<TaskRow> field="RelatedTo" showLabel={false} label="Link this task to" type={ContactRow} placeholder="Select Contact" selectedField={"ContactName"} url="Contact/Lookup" moduleName={RAFEntityName.Contact}></RAFLookup>
                                                </Condition>
                                                <Condition when="RelatedToType" is="account">
                                                    <RAFLookup<TaskRow> field="RelatedTo" showLabel={false} label="Link this task to" type={AccountRow} placeholder="Select Company" selectedField={"AccountName"} url="Account/Lookup" moduleName={RAFEntityName.Account}></RAFLookup>
                                                </Condition>
                                                <Condition when="RelatedToType" is="deal">
                                                    <RAFLookup<TaskRow> field="RelatedTo" showLabel={false} label="Link this task to" type={DealRow} placeholder="Select Deal" selectedField={"DealName"} url="Deal/Lookup" moduleName={RAFEntityName.Deal}></RAFLookup>
                                                </Condition>
                                                <Condition when="RelatedToType" is="ticket">
                                                    <RAFLookup<TaskRow> field="RelatedTo" showLabel={false} label="Link this task to" type={TicketRow} placeholder="Select Ticket" selectedField={"Subject"} url="Ticket/Lookup" moduleName={RAFEntityName.Ticket} ></RAFLookup>
                                                </Condition>
                                                <Condition when="RelatedToType" is="complaint">
                                                    <RAFLookup<TaskRow> field="RelatedTo" showLabel={false} label="Link this task to" type={ComplaintRow} placeholder="Select Complaint" selectedField={"ComplaintId"} url="Complaint/Lookup" moduleName={RAFEntityName.Complaint}></RAFLookup>
                                                </Condition>
                                            </div>
                                        </div>
                                        : null
                                    }*/}
                        {taskRow.TaskStatus === RAFActionStatus.Planned &&
                          moduleName === "dashboard" &&
                          taskRow.RelatedToType !== "business_process" ? (
                          <div className="col-md-12">
                            <RAFRelatedLookupSelector
                              field="RelatedTo"
                              relatedField="RelatedToType"
                              moduleName={"task"}
                            ></RAFRelatedLookupSelector>
                          </div>
                        ) : null}
                        <div className="col-md-12 d-flex flex-column mb-2 mt-1">
                          <RAFTextArea<TaskRow>
                            field="Description"
                            showLabel
                            label="Description"
                            placeholder="Description"
                            rows={3}
                          ></RAFTextArea>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        this.props.isDetailsMode
                          ? "p-2 border-top d-flex align-items-center justify-content-end"
                          : "e-dlg-footerContent"
                      }
                    >
                      {taskRow.TaskStatus === RAFActionStatus.Planned &&
                        taskRow.TaskType === RAFTaskType.ToDo && (
                          <div className="w-100">
                            <div className="row g-2">
                              <div className="col-auto d-none d-sm-block">
                                <ButtonComponent
                                  type="button"
                                  className="e-success"
                                  onClick={() =>
                                    this.onClickCompleteTask(
                                      taskRow,
                                      RAFActionStatus.Completed
                                    )
                                  }
                                >
                                  Complete
                                </ButtonComponent>
                              </div>
                              <div className="col-auto d-sm-none">
                                <ButtonComponent
                                  type="button"
                                  className="e-success"
                                  iconCss="fas fa-check"
                                  onClick={() =>
                                    this.onClickCompleteTask(
                                      taskRow,
                                      RAFActionStatus.Completed
                                    )
                                  }
                                ></ButtonComponent>
                              </div>
                            </div>
                          </div>
                        )}
                      <RAFPermissionRender
                        permissionName={PermissionConstants.TaskManage}
                      >
                        <ButtonComponent
                          className="me-2"
                          cssClass="e-flat e-info"
                        >
                          Save
                        </ButtonComponent>
                      </RAFPermissionRender>
                      <ButtonComponent
                        type="button"
                        cssClass="e-flat d-sm-block me-2"
                        onClick={() => this.toggleEditDetails()}
                      >
                        Cancel
                      </ButtonComponent>
                      {taskRow.TaskStatus === RAFActionStatus.Planned && (
                        <div className="col-auto d-none">
                          <DropDownButtonComponent
                            items={[
                              {
                                id: "Delete",
                                text: "Delete",
                                iconCss: "fas fa-trash",
                              },
                            ]}
                            cssClass="primary-custom-button e-flat default e-caret-hide square-btn d-none task-more-button"
                            select={this.onMoreMenuClicked.bind(this)}
                          >
                            <div className="icon-ellipsis-horizontal icon-ellipsis-sm">
                              <span className="icon-ellipsis-dot"></span>
                            </div>
                          </DropDownButtonComponent>
                        </div>
                      )}
                      {taskRow.TaskStatus === RAFActionStatus.Planned && (
                        <div className="col-auto">
                          <div className="row g-2">
                            <div className="col-auto d-none d-sm-inline-block">
                              <DropDownButtonComponent
                                items={[
                                  {
                                    id: "focuslist",
                                    text:
                                      //isFocused ? 'Remove from focus list' :
                                      "Add to focus list",
                                    iconCss: "fas fa-bookmark",
                                  },
                                  {
                                    id: "Delete",
                                    text: "Delete",
                                    iconCss: "fas fa-trash",
                                  },
                                ]}
                                cssClass="primary-custom-button task-more-button"
                                content="More"
                                select={this.onMoreMenuClicked.bind(this)}
                              >
                                <div className="icon-ellipsis-horizontal icon-ellipsis-sm d-none">
                                  <span className="icon-ellipsis-dot"></span>
                                </div>
                              </DropDownButtonComponent>
                            </div>
                            <div className="col-auto d-sm-none">
                              <DropDownButtonComponent
                                items={[
                                  {
                                    id: "focuslist",
                                    text:
                                      //isFocused ? 'Remove from focus list' :
                                      "Add to focus list",
                                    iconCss: "fas fa-bookmark",
                                  },
                                  {
                                    id: "Delete",
                                    text: "Delete",
                                    iconCss: "fas fa-trash",
                                  },
                                ]}
                                cssClass="primary-custom-button e-flat default e-caret-hide square-btn me-2 task-more-button"
                                select={this.onMoreMenuClicked.bind(this)}
                              >
                                <div className="icon-ellipsis-horizontal icon-ellipsis-sm">
                                  <span className="icon-ellipsis-dot"></span>
                                </div>
                              </DropDownButtonComponent>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-auto">
                        {isNotNullAndUndefined(taskRow) && (
                          <RAFAddToFocusList
                            relatedTo={taskRow.Title}
                            relatedToUID={taskRow.UID}
                            relatedToType={this.moduleName}
                            showAddToFocusListDialog={showAddToFocusListDialog}
                            onClose={() => this.closeAddToFocusListDialog()}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </RAFForm>
              </div>
            );
          } else {
            return (
              <div className="mb-1" id="TaskDetailsSection">
                <div
                  className="e-dlg-headerContent"
                  id="TaskDetailsSection_dialog-header"
                >
                  <div className="d-flex justify-content-between w-100">
                    <div className="e-dlg-header" id="_title"></div>
                  </div>
                  <ButtonComponent
                    type="button"
                    cssClass="primary-custom-button"
                    iconCss="fas fa-xmark"
                    onClick={() => this.onCloseDialogClick()}
                  ></ButtonComponent>
                  {/*<button type="button" className="btn-close" onClick={() => this.onCloseDialogClick()}></button>*/}
                </div>
                <div className="w-100">
                  <div className="e-dlg-body-content taskUpdateForm"></div>
                  <div className="e-dlg-footerContent"></div>
                </div>
              </div>
            );
          }
        }}
      </MyTeamsContext.Consumer>
    );
  }
}

export default TaskDetails;
