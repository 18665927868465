import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";
import { DialogUtility } from "@syncfusion/ej2-popups";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import * as R from "ramda";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { Field, FormRenderProps } from "react-final-form";
import { msalInstance } from "../../../..";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import RAFAutoCompleteMUITagInput from "../../../../RAFComponents/Inputs/RAFAutoCompleteMUITagInput";
import RAFForm, {
  Condition,
  ConditionIncludes,
} from "../../../../RAFComponents/Inputs/RAFForm";
import {
  getFormValue,
  setFormValue,
} from "../../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapse from "../../../../RAFComponents/Navigation/RAFCollapse";
import RAFDeletedRecordState from "../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider from "../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecordRemoveFocus,
  RAFActionMessage,
  SaveDataList,
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender, {
  hasPermission,
} from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  getCollectionNameByModuleName,
  getDisplayNameByModuleName,
} from "../../../../RAFComponents/helpers/RAFMenuHelper";
import {
  ConvertSystemName,
  IsNotNullOrWhiteSpace,
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import RAFCreateFormData from "../../../../RAFMaster/RMModules/BusinessProcess/Process/RAFCreateFormData";
import { EntityRow } from "../../../../RAFMaster/RMModules/Entity/EntityRow";
import { getEntityByObjectName } from "../../../../RAFMaster/helpers/RMutils";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import {
  BrowserIsDevice,
  RAFActionStatus,
  RAFButtonConstant,
  RAFLayout,
  RAFTaskSubType,
  RAFTaskType,
} from "../../../../constants/Common/Constants";
import {
  RAFEntityName,
  RAFModuleName,
} from "../../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../../constants/Common/PermissionConstants";
import RAFEntityProvider from "../../../Common/Providers/RAFEntityProvider";
import { RAFActivityVisibility } from "../../Activity/ActivityRow";
import DocumentSummary from "../../Document/DocumentSummary";
import { UserGroupRow } from "../../UserGroup/UserGroupRow";
import { getMyTeams } from "../../UserGroup/UserGroupServiceHelper";
import { RepeatProcesssItems } from "../RAFRepeatProcesssDropdown";
import {
  retrieveTaskWithReminders,
  saveTaskWithReminders,
} from "../TaskHelper";
import { TaskRow } from "../TaskRow";
import ManageTaskActivityCommonInputs from "./ManageTaskActivityCommonInputs";
import ManageTaskActivityNotesInputs from "./ManageTaskActivityNotesInputs";
import ManageTaskActivityRelatedToInput from "./ManageTaskActivityRelatedToInput";
import ManageTaskActivityStatusActionButton from "./ManageTaskActivityStatusActionButton";
import MangeTaskActivityInputs from "./MangeTaskActivityInputs";
import ManageTaskActivityFormDetails from "./TaskActivityNotes/ManageTaskActivityFormDetails";
import TaskNotesActivitySummary from "./TaskActivityNotes/TaskNotesActivitySummary";

interface IProps {
  isActive: boolean;
  relatedToUID?: string;
  relatedTo?: string;
  relatedToType?: string;

  secondaryRelatedTo?: string;
  secondaryRelatedToUID?: string;
  secondaryRelatedToType?: string;

  objectUID?: string;

  defaultTaskTypeValue?: string;
  contextModuleName?: string;

  showDefaultTaskTypes?: boolean;//to hide call and notes task types from task dashboard

  isTaskActionCenter?: boolean;

  onSave?: () => void;
  onDelete?: () => void;
  onClose?: () => void;
  onCompleteTaskClick?: (taskRow: TaskRow, taskStatus: string) => void;
  relatedCommentsUpdated?: (isRelatedCommentsUpdated?: boolean) => void;
}

interface IState {
  noContent: boolean;
  isLoading: boolean;
  initialFormValues: TaskRow;
  myTeams: UserGroupRow[];
  selectedManageTaskTabIndex: string;
  displayMode: "View" | "Edit";
  isAddNewFollowUpActivityForm: boolean;
  isFormUpdated: boolean;
  showCreateTicketDialog: boolean;
  ticketEntity: EntityRow;
  updatedTaskUID: string;
  showDrawerContent: boolean;
}

function ManageTaskActivity({
  showDefaultTaskTypes = true,
  ...props
}: PropsWithChildren<IProps>) {
  const moduleName: string = RAFEntityName.Task;
  const ticketModuleName: string = RAFEntityName.Ticket;

  const outerDivId = `Outer_Div_${moduleName}`;

  let rafForm: FormRenderProps | null;

  let deleteDialog: any;

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      noContent: true,
      isLoading: true,
      initialFormValues: null,
      myTeams: [],
      selectedManageTaskTabIndex: "Details",
      displayMode: "Edit",
      isAddNewFollowUpActivityForm: false,
      isFormUpdated: false,
      showCreateTicketDialog: false,
      ticketEntity: null,
      updatedTaskUID: null,
      showDrawerContent: false,
    }
  );

  useEffect(() => {
    refresh();
  }, [props.objectUID]);

  const refresh = async () => {
    if (props.isActive) {
      setState({
        isLoading: true,
        noContent: false,
        isAddNewFollowUpActivityForm: false,
        isFormUpdated: false,
      });
      const [initialFormValues, myTeams, ticketEntity] =
        await Promise.all([
          getIntitailTaskRow(),
          getMyTeams(),
          getEntityByObjectName({
            ObjectName: ConvertSystemName(ticketModuleName),
          }),
        ]);

      if (isNotNullAndUndefined(initialFormValues)) {
        const displayMode =
          isNotNullAndUndefined(initialFormValues) &&
            (initialFormValues.TaskType === RAFTaskType.Action ||
              initialFormValues.TaskType === RAFTaskType.Appointment) &&
            initialFormValues.TaskStatus === RAFActionStatus.Completed
            ? "View"
            : "Edit";
        setState({
          initialFormValues,
          myTeams,
          displayMode,
          ticketEntity,
          isLoading: false,
          noContent: false,
        });
      } else {
        setState({ isLoading: false, noContent: true });
      }
    } else {
      setState({ isLoading: false, noContent: true });
    }
  };

  const onUpdateRelatedTo = async () => {
    setState({ isLoading: true, noContent: false, isFormUpdated: true });
    const initialFormValues = await getIntitailTaskRow();
    if (isNotNullAndUndefined(initialFormValues)) {
      setState({
        initialFormValues,
        isLoading: false,
        noContent: false,
      });
    } else {
      setState({ isLoading: false, noContent: true });
    }
  };

  const addNewFollowUpActivity = (formValue: TaskRow) => {
    setState({ isLoading: true, noContent: false, isFormUpdated: true });
    let initialObject = new TaskRow();
    initialObject.Title = IsNotNullOrWhiteSpace(formValue.Title)
      ? `${formValue.Title} - Follow-up`
      : null;
    initialObject.RelatedTo = formValue.RelatedTo;
    initialObject.RelatedToUID = formValue.RelatedToUID;
    initialObject.RelatedToType = formValue.RelatedToType;
    initialObject.SecondaryRelatedTo = formValue.SecondaryRelatedTo;
    initialObject.SecondaryRelatedToUID = formValue.SecondaryRelatedToUID;
    initialObject.SecondaryRelatedToType = formValue.SecondaryRelatedToType;
    initialObject.TaskType = RAFTaskType.Action;
    initialObject.HasMentions = true;
    initialObject.SubType = RAFTaskSubType.Task;
    initialObject.TaskStatus = RAFActionStatus.Planned;
    initialObject.Priority = "Low";

    initialObject.Assignee = msalInstance.currentUserName;
    initialObject.AssigneeUID = msalInstance.currentUserId;
    initialObject.RepeatProcesss = RepeatProcesssItems.Never;

    setState({
      isLoading: false,
      initialFormValues: initialObject,
      displayMode: "Edit",
      isAddNewFollowUpActivityForm: true,
    });
  };

  async function checkScrollable() {
    const div = await document.querySelector("#raf_tab_header_manage_task");
    const leftButton = await document.getElementById(
      "btn_raf_tab_header_manage_task_left_scroll_iconBtn"
    );
    const rightButton = await document.getElementById(
      "btn_raf_tab_header_manage_task_right_scroll_iconBtn"
    );

    if (div && leftButton && rightButton) {
      // Check if the div is scrollable
      if (div.scrollWidth > div.clientWidth) {
        // The div is scrollable, show the arrow buttons
        leftButton.style.display = "block";
        rightButton.style.display = "block";

        // Add click event listeners to the arrow buttons
        leftButton.addEventListener("click", () => {
          // Scroll the div to the left
          div.scrollTo({
            left: div.scrollLeft - (div.clientWidth - 100),
            behavior: "smooth",
          });
        });

        rightButton.addEventListener("click", () => {
          // Scroll the div to the right
          div.scrollTo({
            left: div.scrollLeft + (div.clientWidth - 100),
            behavior: "smooth",
          });
        });
      } else {
        // The div is not scrollable, hide the arrow buttons
        leftButton.style.display = "none";
        rightButton.style.display = "none";
      }

      // Stop observing the DOM changes
      observer.disconnect();
    }
  }

  // Create a MutationObserver to watch for changes in the DOM
  const observer = new MutationObserver(checkScrollable);

  // Start observing the document with the configured parameters
  observer.observe(document, { childList: true, subtree: true });

  const getIntitailTaskRow = () => {
    return new Promise<TaskRow>(async (resolve) => {
      if (isNotNullAndUndefined(props.objectUID)) {
        const taskRow: TaskRow = await retrieveTaskWithReminders(
          props.objectUID
        );
        if (
          isNotNullAndUndefined(taskRow) &&
          isNotNullAndUndefined(taskRow.UID)
        ) {
          resolve(taskRow);
        } else {
          resolve(null);
        }
      } else {
        let initialObject = new TaskRow();
        initialObject.RelatedTo = props.relatedTo;
        initialObject.RelatedToUID = props.relatedToUID;
        initialObject.RelatedToType = props.relatedToType;
        initialObject.SecondaryRelatedTo = props.secondaryRelatedTo;
        initialObject.SecondaryRelatedToUID = props.secondaryRelatedToUID;
        initialObject.SecondaryRelatedToType = props.secondaryRelatedToType;
        initialObject.TaskType =
          props.defaultTaskTypeValue ?? RAFTaskType.Appointment;
        initialObject.HasMentions = true;
        initialObject.TaskStatus =
          (initialObject.TaskType === RAFTaskType.Note ||
            initialObject.TaskType === RAFTaskType.Call)
            ? RAFActionStatus.Completed
            : RAFActionStatus.Planned;
        initialObject.SubType = initialObject.TaskType === RAFTaskType.Action ? RAFTaskSubType.Task : null;
        initialObject.Priority = "Low";

        initialObject.Assignee = msalInstance.currentUserName;
        initialObject.AssigneeUID = msalInstance.currentUserId;
        initialObject.RepeatProcesss = RepeatProcesssItems.Never;
        initialObject.Visibility = RAFActivityVisibility.Public;

        if (initialObject.TaskType === RAFTaskType.Note) {
          initialObject.SortOrder = 99;
          initialObject.ColourCode = "#FFF9B1";
          initialObject.TaskDate = new Date();
        }

        if (initialObject.TaskType === RAFTaskType.Call) {
          const currentTime = new Date(); // roundToNearestInterval(new Date(), 15);
          initialObject.TaskDate = currentTime;

          // Calculate EndDate by adding 30 minutes to TaskDate
          const endDate = new Date(currentTime.getTime() + 30 * 60000); // 30 minutes in milliseconds
          initialObject.EndDate = endDate;

          // Optionally, calculate the duration in minutes
          const duration = 30; // Duration is 30 minutes
          initialObject.Duration = duration;
        }
        if (initialObject.TaskType === RAFTaskType.Appointment) {
          const currentTime = new Date(); // roundToNearestInterval(new Date(), 15);
          initialObject.TaskDate = currentTime;
          // Calculate EndDate by adding 30 minutes to TaskDate
          const endDate = new Date(currentTime.getTime() + 30 * 60000); // 30 minutes in milliseconds
          initialObject.EndDate = endDate;

          // Optionally, calculate the duration in minutes
          const duration = 30; // Duration is 30 minutes
          initialObject.Duration = duration;
        }

        resolve(initialObject);
      }
    });
  };

  const onSubmitForm = async (value: TaskRow) => {
    let progressDiv = showProgress(`#${outerDivId}`);
    const formValue = R.clone(value);
    const response = await saveTaskWithReminders(formValue);
    hideProgress(progressDiv);
    if (isNotNullAndUndefined(response)) {
      showSuccessToast(RAFActionMessage.RecordSaved);
      if (value.AddFollowUpTicket) {
        showCreateTicketDialogContent(response);
      } else if (value.AddFollowUpActivity) {
        addNewFollowUpActivity(value);
      } else {
        if (props.onSave) {
          props.onSave();
        }
      }
    } else {
      showWarningToast(RAFActionMessage.RecordNotSaved);
    }
  };

  //delete record start
  const onDeleteBtnClick = () => {
    const formTaskType = getFormValue(rafForm, propertyOf<TaskRow>("TaskType"));
    const taskType = isNotNullAndUndefined(formTaskType)
      ? formTaskType
      : "Task";
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: DeleteRecord.bind(this) },
      showCloseIcon: false,
      title: `Delete ${taskType}`,
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
    });
  };

  const DeleteRecord = () => {
    let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
    DeleteRecordRemoveFocus(state.initialFormValues.UID, moduleName).then(
      (response) => {
        deleteDialog.hide();
        hideProgress(progressDiv);
        if (isNotNullAndUndefined(response)) {
          if (props.onDelete) {
            props.onDelete();
          } else if (props.onSave) {
            props.onSave();
          }
        }
      }
    );
  };
  //delete record end

  //add ticket start
  const onClickAddFollowUpTicket = () => {
    if (isNotNullAndUndefined(rafForm) && rafForm.invalid) {
      rafForm.form.submit();
    } else {
      setFormValue(rafForm, propertyOf<TaskRow>("AddFollowUpTicket"), true);
      setTimeout(() => {
        rafForm.form.submit();
      }, 100);
    }
  };

  const showCreateTicketDialogContent = (updatedTaskUID: string) => {
    setState({
      showCreateTicketDialog: true,
      isFormUpdated: true,
      updatedTaskUID,
    });
  };

  const createTicketContent = () => {
    if (state.showCreateTicketDialog) {
      return (
        <RAFCreateFormData
          moduleName={ticketModuleName}
          onSave={onCreateTicket.bind(this)}
          onClose={closeCreateTicketDialog.bind(this)}
          initialValues={null}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const onCreateTicket = async (entityId: string, objectName: string) => {
    //SaveDataList
    setState({ showCreateTicketDialog: false });
    const { ticketEntity, updatedTaskUID } = state;
    let progressDiv = showProgress(`#${outerDivId}`);
    const newTaskRow = new TaskRow();
    newTaskRow.UID = updatedTaskUID;
    newTaskRow.SecondaryRelatedTo = objectName;
    newTaskRow.SecondaryRelatedToUID = entityId;
    newTaskRow.SecondaryRelatedToType = RAFEntityName.Ticket;

    const response = await SaveDataList(
      newTaskRow,
      ticketEntity.UID,
      ticketEntity.EntityName,
      "Task/Save"
    );

    hideProgress(progressDiv);
    if (props.onSave) {
      props.onSave();
    }
  };

  const closeCreateTicketDialog = () => {
    setState({ showCreateTicketDialog: false });
    if (props.onSave) {
      props.onSave();
    }
  };
  //add ticket end

  const onClickAddFollowUpActivity = () => {
    if (isNotNullAndUndefined(rafForm) && rafForm.invalid) {
      rafForm.form.submit();
    } else {
      setFormValue(rafForm, propertyOf<TaskRow>("AddFollowUpActivity"), true);
      setTimeout(() => {
        rafForm.form.submit();
      }, 100);
    }
  };

  const onTaskTypeChange = (value: string) => {
    const taskUID = getFormValue(rafForm, propertyOf<TaskRow>("UID"));
    if (isNullOrUndefined(taskUID)) {
      if (value === RAFTaskType.Note || value === RAFTaskType.Call) {
        setFormValue(
          rafForm,
          propertyOf<TaskRow>("TaskStatus"),
          RAFActionStatus.Completed
        );
      } else {
        setFormValue(
          rafForm,
          propertyOf<TaskRow>("TaskStatus"),
          RAFActionStatus.Planned
        );
      }
    }
    if (value === RAFTaskType.Note) {
      setFormValue(rafForm, propertyOf<TaskRow>("SortOrder"), 99);
      setFormValue(rafForm, propertyOf<TaskRow>("ColourCode"), "#FFF9B1");
      setFormValue(
        rafForm,
        propertyOf<TaskRow>("Visibility"),
        RAFActivityVisibility.Public
      );
      setFormValue(rafForm, propertyOf<TaskRow>("TaskDate"), new Date());
    } else {
      setFormValue(rafForm, propertyOf<TaskRow>("SortOrder"), null);
      setFormValue(rafForm, propertyOf<TaskRow>("ColourCode"), null);
      setFormValue(
        rafForm,
        propertyOf<TaskRow>("Visibility"),
        RAFActivityVisibility.Public
      );
      setFormValue(rafForm, propertyOf<TaskRow>("ActivityTeamMembers"), null);
      setFormValue(rafForm, propertyOf<TaskRow>("Pinned"), null);
      setFormValue(rafForm, propertyOf<TaskRow>("PostFeed"), null);
      setFormValue(rafForm, propertyOf<TaskRow>("TeamMembers"), null);
    }
  };

  const getFormContent = () => {
    return (
      <div className="row gx-0 gy-3">
        <ManageTaskActivityCommonInputs
          outerDivId={outerDivId}
          onTaskTypeChange={onTaskTypeChange}
          defaultTaskTypeValue={props.defaultTaskTypeValue}
          showDefaultTaskTypes={showDefaultTaskTypes}
          isTaskActionCenter={props.isTaskActionCenter}
        />
        <ConditionIncludes
          when={propertyOf<TaskRow>("TaskType")}
          is={[
            RAFTaskType.ToDo,
            RAFTaskType.Appointment,
            RAFTaskType.Action,
            RAFTaskType.Call,
          ]}
        >
          <MangeTaskActivityInputs />
        </ConditionIncludes>
        <Condition when={propertyOf<TaskRow>("TaskType")} is={RAFTaskType.Note}>
          <ManageTaskActivityNotesInputs />
        </Condition>
        <ManageTaskActivityRelatedToInput
          contextModuleName={props.contextModuleName}
          onUpdateRelatedTo={onUpdateRelatedTo}
          displayMode={state.displayMode}
        />
        <div className="col-md-12">
          <RAFAutoCompleteMUITagInput<TaskRow>
            field={propertyOf<TaskRow>("TagsListJson")}
            label="Tags"
            showLabel={true}
            // showLabel={true}
            placeholder="Type and press enter to add a tag"
            showAddBtn
            rowClassName="gy-0 gx-2"
            moduleName={moduleName}
          />
        </div>
      </div>
    );
  };

  //tab contnet start
  const onEditButtonClick = () => {
    setState({ displayMode: "Edit" });
  };

  const getTaskDetailsFormContent = () => {
    return (
      <ManageTaskActivityFormDetails
        initialFormValues={state.initialFormValues}
        contextModuleName={props.contextModuleName}
      />
    );
  };

  const renderCommentContent = () => {
    const { initialFormValues } = state;
    return (
      <div className="e-card border-0" id="comments">
        <div className="e-card-content py-0 px-0">
          <RAFCollapse
            arrowPosistion="title"
            title={getCollectionNameByModuleName(RAFEntityName.Activity)}
            iconName="Comments"
            contentCardClassName="p-0 manageTask_related_documents"
            headerRowClassName="custom-section-header remover-margin"
            layout={RAFLayout.OneColumnLayout}
            IsCollapsed={false}
            removeContentCardPadding
            showSectionHeader={false}
          >
            <TaskNotesActivitySummary
              onChangeRelatedTask={() => {
                if (props.relatedCommentsUpdated) {
                  props.relatedCommentsUpdated(true);
                }
              }}
              relatedToUID={initialFormValues.RelatedToUID}
              relatedTo={initialFormValues.RelatedTo}
              relatedType={initialFormValues.RelatedToType}
              parentRelatedToUID={initialFormValues.UID}
              parentRelatedTo={initialFormValues.Title}
              // secondaryRelatedToUID={taskRow.UID}
              // secondaryRelatedTo={taskRow.Title}
              // secondaryRelatedToType={RAFEntityName.Task}

              taskItemClassname="px-1"
              paddingClassName="py-3"
              excludeRelatedFilter
            />
          </RAFCollapse>
        </div>
      </div>
    );
  };

  const renderDocumentContent = () => {
    const { initialFormValues } = state;
    const contextModuleName: string = isNotNullAndUndefined(
      props.contextModuleName
    )
      ? props.contextModuleName
      : RAFModuleName.Task;
    return (
      <div className="e-card border-0" id="documents">
        <div className="e-card-content py-3 px-0">
          <DocumentSummary
            allowFiltering={false}
            isRelatedSection
            relatedUID={initialFormValues.UID}
            relatedTo={initialFormValues.Title}
            relatedToType={contextModuleName}
            moduleName={RAFModuleName.Task}
            showFilterBtn={false}
            isRelatedDocument
            gridStyle={"List"}
            // isAdaptive={true}
            isAdaptive
            collapsePanelArrowPosistion="title"
            showExpandBtn={false}
            headerRowClassName="custom-section-header remover-margin"
            contentCardClassName="manageTask_related_documents"
            // addBtnDivId="manageTask_relatedDocument_div"
            enableAddBtn={false}
            showSectionHeader={false}
            IsCollapsed={false}
            addBtnDisplayStyle="SectionFooter"
            readPermissionName={PermissionConstants.TaskViewDocuments}
            createPermissionName={PermissionConstants.TaskManageDocuments}
            updatePermissionName={PermissionConstants.TaskManageDocuments}
            deletePermissionName={PermissionConstants.TaskDeleteDocuments}
          />
        </div>
      </div>
    );
  };

  const manageTaskTabSelected = (selectedManageTaskTabIndex: string) => {
    setState({ selectedManageTaskTabIndex });
  };

  const renderTabContent = () => {
    if (state.selectedManageTaskTabIndex === "Details") {
      return (
        <div>
          {state.displayMode === "View"
            ? getTaskDetailsFormContent()
            : getFormContent()}
        </div>
      );
    } else if (state.selectedManageTaskTabIndex === "Comments") {
      return renderCommentContent();
    } else if (state.selectedManageTaskTabIndex === "Documents") {
      return renderDocumentContent();
    } else {
      return null;
    }
  };
  //tab contnet end

  const getBodyContet = () => {
    if (isNotNullAndUndefined(state.initialFormValues.UID)) {
      const taskTabItems: {
        id: number;
        text: string;
        permissionName?: string;
      }[] = [
          {
            id: 0,
            text: "Details",
          },
          {
            id: 2,
            text: "Comments",
            permissionName: PermissionConstants.CommentsRead,
          },
          {
            id: 3,
            text: "Documents",
            permissionName: PermissionConstants.TaskViewDocuments,
          },
        ];

      return (
        <>
          <div className="border-bottom ps-1">
            <div
              id="raf_tab_header_manage_task_container"
              className="raf_tab_header raf_lg secondary_tab hideScrollBar full_width_tab"
            >
              {!BrowserIsDevice && (
                <RAFButtonComponent
                  iconBtn
                  iconCss="fas fa-chevron-left"
                  className="bg-white border-0 custom-button-lg"
                  id="raf_tab_header_manage_task_left_scroll"
                  enableToolTip={false}
                ></RAFButtonComponent>
              )}
              <div
                id="raf_tab_header_manage_task"
                className="raf_tab_header raf_lg hideScrollBar"
              >
                {taskTabItems.map((x) => {
                  const permission = isNotNullAndUndefined(x.permissionName)
                    ? hasPermission(permissionValue, x.permissionName)
                    : true;

                  if (permission) {
                    return (
                      <div
                        className={`raf_tab_header_item${state.selectedManageTaskTabIndex === x.text
                          ? " active"
                          : ""
                          }`}
                        key={x.id}
                        onClick={() => manageTaskTabSelected(x.text)}
                      >
                        <span>{x.text}</span>
                      </div>
                    );
                  }
                })}
              </div>
              {!BrowserIsDevice && (
                <RAFButtonComponent
                  iconBtn
                  iconCss="fas fa-chevron-right"
                  className="bg-white border-0 custom-button-lg"
                  id="raf_tab_header_manage_task_right_scroll"
                  enableToolTip={false}
                ></RAFButtonComponent>
              )}
            </div>
          </div>
          <div className="e-dlg-body-content pt-2 pt-md-3">
            {renderTabContent()}
          </div>
        </>
      );
    } else {
      return (
        <div className="e-dlg-body-content pt-2 pt-md-3">
          {getFormContent()}
        </div>
      );
    }
  };

  const onSplitButtonClicked = (itemID: string) => {
    if (itemID === "Save") {
      rafForm && rafForm.form.submit();
    } else if (itemID === "AddFollowUp") {
      onClickAddFollowUpActivity();
    } else if (itemID === "AddTicket") {
      onClickAddFollowUpTicket();
    }
  };

  const openDrawer = () => {
    setState({ showDrawerContent: true });
  };

  const closeDrawer = () => {
    setState({ showDrawerContent: false });
  };

  const toggleDrawer = (isOpen) => {
    setState({ showDrawerContent: isOpen });
  };

  const getFooterContent = () => {
    const { initialFormValues } = state;

    const splitButtonItems: {
      text: string;
      id: string;
    }[] = [
        {
          text: "Save and Close",
          id: "Save",
        },
        {
          text: "Save and Add Follow-Up",
          id: "AddFollowUp",
        },
        {
          text: "Save and Add Ticket",
          id: "AddTicket",
        },
      ];

    return (
      <div className="w-100">
        <div className="row gx-2">
          {isNotNullAndUndefined(initialFormValues.UID) && (
            <div className="col-auto">
              <RAFPermissionRender
                permissionName={PermissionConstants.TaskDelete}
              >
                <RAFButtonComponent
                  action={RAFButtonConstant.Delete}
                  type="button"
                  className="e-danger e-outline new_style raf_sm"
                  iconBtn
                  onClick={onDeleteBtnClick}
                />
              </RAFPermissionRender>
            </div>
          )}
          {isNotNullAndUndefined(initialFormValues.UID) && (
            <ManageTaskActivityStatusActionButton
              initialFormValues={initialFormValues}
              myTeams={state.myTeams}
              onSave={props.onSave}
              onSubmitForm={onSubmitForm}
              onCompleteTaskClick={props.onCompleteTaskClick}
            />
          )}
          {state.displayMode === "View" ? (
            <>
              <div className="col-auto ms-auto">
                <RAFPermissionRender
                  permissionName={PermissionConstants.TaskEditCompletedTasks}
                >
                  <RAFButtonComponent
                    action={RAFButtonConstant.Edit}
                    type="button"
                    isPrimary
                    onClick={() => onEditButtonClick()}
                  />
                </RAFPermissionRender>
              </div>
            </>
          ) : (
            <>
              {state.isAddNewFollowUpActivityForm ? (
                <>
                  <div className="col-auto ms-auto">
                    <RAFButtonComponent
                      type="button"
                      action={RAFButtonConstant.Cancel}
                      onClick={() => {
                        if (props.onSave) {
                          props.onSave();
                        }
                      }}
                    />
                  </div>
                  <div className="col-auto">
                    <RAFButtonComponent
                      action={RAFButtonConstant.Save}
                      type="button"
                      isPrimary
                      onClick={() => rafForm && rafForm.form.submit()}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-auto ms-auto">
                    <RAFPermissionRender
                      permissionName={PermissionConstants.TaskManage}
                    >
                      {BrowserIsDevice ? (
                        <>
                          <RAFButtonComponent
                            action={RAFButtonConstant.Save}
                            isPrimary
                            onClick={openDrawer}
                          ></RAFButtonComponent>
                          <SwipeableDrawer
                            anchor={"bottom"}
                            open={state.showDrawerContent}
                            onClose={closeDrawer}
                            onOpen={openDrawer}
                          >
                            <List>
                              {splitButtonItems.map((item, index) => (
                                <ListItem
                                  key={item.id}
                                  disablePadding
                                  onClick={() => onSplitButtonClicked(item.id)}
                                >
                                  <ListItemButton>
                                    <ListItemText primary={item.text} />
                                  </ListItemButton>
                                </ListItem>
                              ))}
                            </List>
                          </SwipeableDrawer>
                        </>
                      ) : (
                        <DropDownButtonComponent
                          content={RAFButtonConstant.Save.DisplayName}
                          items={splitButtonItems}
                          select={(e) => onSplitButtonClicked(e.item.id)}
                          cssClass={
                            "text-nowrap document_dropdown_btn e-primary new_style raf_sm"
                          }
                        />
                      )}
                      {/* <DropDownButtonComponent
                        {...(!BrowserIsDevice
                          ? { content: "Save" }
                          : { iconCss: RAFButtonConstant.Save.IconCss })}
                        items={splitButtonItems}
                        select={(e) => onSplitButtonClicked(e.item.id)}
                        cssClass={
                          !BrowserIsDevice
                            ? "text-nowrap document_dropdown_btn e-primary new_style raf_sm"
                            : "text-nowrap document_dropdown_btn e-caret-hide primary-custom-button new_style raf_sm"
                        }
                      /> */}
                    </RAFPermissionRender>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      if (state.noContent === false) {
        const { initialFormValues } = state;
        return (
          <div className="h-100" id={outerDivId}>
            <RAFEntityProvider moduleName={moduleName}>
              <RAFAttributeRelatedListProvider moduleName={moduleName}>
                <RAFForm
                  initialValues={initialFormValues}
                  formRef={(g) => {
                    return (rafForm = g);
                  }}
                  layout={RAFLayout.TwoColumnLayout}
                  submitOnEnterKey={false}
                  className="h-100"
                  onSubmit={onSubmitForm}
                >
                  <div className="e-dlg-content-outer" >
                    <div className="e-dlg-headerContent justify-content-between">
                      <div className="e-dlg-header" id="_title">
                        <Field name={propertyOf<TaskRow>("TaskType")}>
                          {({ input }) => {
                            const taskType = input.value;
                            return (
                              <>
                                {isNotNullAndUndefined(
                                  state.initialFormValues.UID
                                )
                                  ? `Update ${isNotNullAndUndefined(taskType)
                                    ? taskType
                                    : "Task"
                                  }`
                                  : `Add ${isNotNullAndUndefined(taskType)
                                    ? taskType
                                    : "Task"
                                  }${state.isAddNewFollowUpActivityForm
                                    ? " - Follow-up"
                                    : ""
                                  }`}
                              </>
                            );
                          }}
                        </Field>
                      </div>
                      <RAFButtonComponent
                        displayMode="DialogCloseBtn"
                        onClick={
                          state.isFormUpdated ? props.onSave : props.onClose
                        }
                      />
                    </div>
                    {getBodyContet()}
                    <div className="e-dlg-footerContent">
                      {getFooterContent()}
                    </div>
                    {state.showCreateTicketDialog && (
                      <DialogComponent
                        header={
                          "Create " +
                          getDisplayNameByModuleName(
                            ticketModuleName,
                            state.ticketEntity.DisplayName
                          )
                        }
                        showCloseIcon
                        visible={state.showCreateTicketDialog}
                        content={createTicketContent.bind(this)}
                        //cssClass={entity.EntityType === RAFEntityType.UserForm ? "dlg-new-style centerDialog-lg createEditForm form-center-dialog" : "rightDialog createEditForm full-height form-center-dialog"}
                        cssClass={
                          "rightDialog createEditForm full-height form-center-dialog dlg-new-style"
                        }
                        isModal
                        target="body"
                        closeOnEscape={false}
                        close={closeCreateTicketDialog.bind(this)}
                        id={`dlg_create_${ticketModuleName}`}
                        zIndex={1200}
                        open={PreventFocusOnDialogOpen}
                      ></DialogComponent>
                    )}
                  </div>
                </RAFForm>
              </RAFAttributeRelatedListProvider>
            </RAFEntityProvider>
          </div>
        );
      } else if (state.noContent === true) {
        return (
          <div className="container-fluid px-0">
            <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
          </div>
        );
      } else {
        return null;
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Loading..." />
        </div>
      );
    }
  } else {
    return null;
  }
}

export default React.memo(ManageTaskActivity);
