import DOMPurify from "dompurify";
import React, { PropsWithChildren } from "react";
import RAFTaskTagInput from "../../../../../RAFComponents/Inputs/RAFTaskTagInput";
import CustomCardWidget from "../../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFDetailFieldCustom from "../../../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import { isNotNullAndUndefined, propertyOf } from "../../../../../RAFComponents/helpers/utils";
import { RAFEntityName } from "../../../../../constants/Common/EntityConstants";
import UserProfilePhoto from "../../../User/UserProfilePhoto";
import { getTaskDisplayDateAndBadge } from "../../TaskHelper";
import { TaskRow } from "../../TaskRow";
import ManageTaskActivityRelatedToInput from "../ManageTaskActivityRelatedToInput";

interface IProps {
    contextModuleName: string;
    initialFormValues: TaskRow;
}

function ManageTaskActivityFormDetails({ initialFormValues, ...props }: PropsWithChildren<IProps>) {

    const renderRelatedToContent = () => {
        if (isNotNullAndUndefined(initialFormValues.RelatedToUID)) {
            return (
                <>
                    <div className="col-12">
                        <div className="custom__card__separator custom__card__separator__light"></div>
                    </div>
                    <ManageTaskActivityRelatedToInput
                        contextModuleName={props.contextModuleName}
                        displayMode={'View'}
                        onUpdateRelatedTo={null}
                    />
                </>
            );
        } else {
            return null;
        }
    };

    if (isNotNullAndUndefined(initialFormValues)) {
        const taskDisplayDateAndBadge = getTaskDisplayDateAndBadge(
            initialFormValues.TaskDate,
            initialFormValues.TaskType,
            true
        );
        const taskDisplayDate = taskDisplayDateAndBadge.taskDueDate;

        return (
            <div className={"row gx-0 gy-3"}>
                <div className="col-12">
                    <div className="row gy-2">
                        <div className="col-12">
                            <div className="gx-2 gy-1 row">
                                <div className="col-auto">
                                    <RAFDetailFieldCustom
                                        value={
                                            isNotNullAndUndefined(initialFormValues.TaskType)
                                                ? initialFormValues.TaskType
                                                : "Not set"
                                        }
                                        displayValue={initialFormValues.TaskType}
                                        title="TaskType"
                                        moduleName={RAFEntityName.Task}
                                        isColorOption
                                        field="TaskType"
                                        mode="opacityColorView"
                                        showLabel={false}
                                        valueClassName="raf_badge raf_sm"
                                    />
                                </div>
                                <div className="col-12 subtitle_2">
                                    <span className="subtitle_2">{initialFormValues.Title}</span>
                                </div>
                            </div>
                        </div>
                        {isNotNullAndUndefined(initialFormValues.Description) ? (
                            <div className="col-12">
                                <CustomCardWidget cardContentClassName="p-2">
                                    <span
                                        className="description-text regular"
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(initialFormValues.Description),
                                        }}
                                    ></span>
                                </CustomCardWidget>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div className="col-12">
                    <div className="custom__card__separator custom__card__separator__light"></div>
                </div>
                <div className="col-12">
                    <RAFDetailFieldCustom
                        value={
                            isNotNullAndUndefined(initialFormValues.TaskStatus)
                                ? initialFormValues.TaskStatus
                                : "Not set"
                        }
                        displayValue={initialFormValues.TaskStatus}
                        title="TaskStatus"
                        moduleName={RAFEntityName.Task}
                        isColorOption
                        field="TaskStatus"
                        mode="textOnly"
                        showLabel={true}
                        // valueClassName="body_3_dark medium"
                        // labelClassName="body_3_light"
                        // labelDivClassName="body_3_light col-auto"
                        // valueDivClassName="body_3_dark col-auto"
                        rowClassName="gx-0 gy-1"
                    />
                </div>
                <div className="col-12">
                    <div className="custom__card__separator custom__card__separator__light"></div>
                </div>
                <div className="col-12">
                    <RAFDetailFieldCustom
                        value={taskDisplayDate}
                        title="Due date"
                        colClassName="col-12"
                        rowClassName="gy-1"
                    //fieldFormat={{ type: RAFDataType.Date, format: MomentFormats.DATETIME }}
                    />
                </div>
                <div className="col-12">
                    <div className="custom__card__separator custom__card__separator__light"></div>
                </div>
                {isNotNullAndUndefined(initialFormValues.AssignTeamUID) ? (
                    <div className="col-12">
                        <RAFDetailFieldCustom
                            value={initialFormValues.AssignTeam}
                            title="Assigned to"
                            colClassName="col-12"
                            rowClassName="gy-1"
                            customValue={
                                <div>
                                    <div className="row gx-2">
                                        <div className="col-auto">
                                            <UserProfilePhoto
                                                uid={initialFormValues.AssignTeamUID}
                                                relatedToType={RAFEntityName.Team}
                                                canEdit={false}
                                                profileImgCss={"profileImg-container-xxsm"}
                                                title={initialFormValues.AssignTeamUID ?? "Not Set"}
                                                iconSize="16"
                                                fontSize="8"
                                                emptyAvatarType="Initial"
                                            />
                                        </div>
                                        <div className="col">
                                            <div className="details-value">{initialFormValues.AssignTeam}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        />
                    </div>
                )
                    :
                    (
                        <div className="col-12">
                            <RAFDetailFieldCustom
                                value={initialFormValues.Assignee}
                                title="Assigned to"
                                colClassName="col-12"
                                rowClassName="gy-1"
                                customValue={
                                    <div>
                                        <div className="row gx-2">
                                            <div className="col-auto">
                                                <UserProfilePhoto
                                                    uid={initialFormValues.AssigneeUID}
                                                    relatedToType={RAFEntityName.User}
                                                    canEdit={false}
                                                    profileImgCss={"profileImg-container-xxsm"}
                                                    title={initialFormValues.Assignee ?? "Not Set"}
                                                    iconSize="16"
                                                    fontSize="8"
                                                    emptyAvatarType="Initial"
                                                />
                                            </div>
                                            <div className="col">
                                                <div className="details-value">{initialFormValues.Assignee}</div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    )}
                {renderRelatedToContent()}
                <div className="col-12">
                    <div className="custom__card__separator custom__card__separator__light"></div>
                </div>
                <div className="col-md-12">
                    <RAFTaskTagInput
                        field={propertyOf<TaskRow>("TagsListJson")}
                        label="Tags"
                        showLabel={false}
                        // showLabel={true}
                        placeholder="Type and press enter to add a tag"
                        showAddBtn
                        disabled
                    />
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default React.memo(ManageTaskActivityFormDetails);