import { MapperConfiguration, MappingPair } from '@dynamic-mapper/mapper';
import { isNotNullAndUndefined } from '../../../RAFComponents/helpers/utils';
import { RepeatProcesssItems } from './RAFRepeatProcesssDropdown';
import { getTaskDueDate } from './TaskHelper';
import { ITaskDto, TaskRow } from "./TaskRow";

export const ConvertTaskRowToTaskDto = (taskRow: TaskRow): ITaskDto => {

    const TaskRowToITaskDto = new MappingPair<TaskRow, ITaskDto>();
    const TaskRowToTaskDtoConfiguration = new MapperConfiguration(cfg => {
        cfg.createAutoMap(TaskRowToITaskDto, {})
            .forSourceMember("Purpose", opt => opt.ignore())
            .forSourceMember("DueDate", opt => opt.ignore())
            .forSourceMember("ASAPDate", opt => opt.ignore())
            .forSourceMember("Reminders", opt => opt.ignore())
            .forSourceMember("RepeatProcesss", opt => opt.ignore())
            .forSourceMember("TitleUID", opt => opt.ignore())
            .forSourceMember("ActivityTeamMembers", opt => opt.ignore())
            .forSourceMember("AddFollowUpActivity", opt => opt.ignore())
            .forSourceMember("AddFollowUpTicket", opt => opt.ignore())
            ;
    });

    const mapper = TaskRowToTaskDtoConfiguration.createMapper();
    return mapper.map(TaskRowToITaskDto, taskRow);
};

export const ConvertTaskDtoToTaskRow = (tasktDto: ITaskDto): TaskRow => {
    const ITaskDtoToTaskRow = new MappingPair<ITaskDto, TaskRow>();
    const ITaskDtoToTaskRowConfiguration = new MapperConfiguration(cfg => {
        cfg.createAutoMap(ITaskDtoToTaskRow, {
            Purpose: opt => opt.mapFrom(() => null),
            DueDate: opt => opt.mapFrom(src => getTaskDueDate(src)),
            ASAPDate: opt => opt.mapFrom(() => null),
            RepeatProcesss: opt => opt.mapFrom(src => isNotNullAndUndefined(src.CronExpression) ? RepeatProcesssItems.Custom : RepeatProcesssItems.Never),
            Reminders: opt => opt.mapFrom(() => []),
            TitleUID: opt => opt.mapFrom(() => null),
            ActivityTeamMembers: opt => opt.mapFrom(() => []),
            AddFollowUpActivity: opt => opt.mapFrom(() => false),
            AddFollowUpTicket: opt => opt.mapFrom(() => false)
        });
    });
    const mapper = ITaskDtoToTaskRowConfiguration.createMapper();
    return mapper.map(ITaskDtoToTaskRow, tasktDto);
};