import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { DropDownButtonComponent, MenuEventArgs } from "@syncfusion/ej2-react-splitbuttons";
import DOMPurify from "dompurify";
import React, { PropsWithChildren, Reducer, useContext, useReducer } from "react";
import { FormRenderProps } from "react-final-form";
import { RolePermissionsContext } from "../../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import RAFForm from "../../../../../RAFComponents/Inputs/RAFForm";
import RAFTextArea from "../../../../../RAFComponents/Inputs/RAFTextArea";
import RAFIconImage from "../../../../../RAFComponents/Navigation/RAFIconImage";
import RAFRecordInfo from "../../../../../RAFComponents/Navigation/RAFRecordInfo";
import { showWarningToast } from "../../../../../RAFComponents/Utility/RAFToastComponent";
import RAFPermissionRender, { hasPermission } from "../../../../../RAFComponents/helpers/PermissionHelper";
import { Guid, isNotNullAndUndefined, propertyOf } from "../../../../../RAFComponents/helpers/utils";
import { RAFButtonConstant } from "../../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../../../constants/Common/PermissionConstants";
import { TaskRow } from "../../TaskRow";

interface IProps {
    taskRow: TaskRow;
    editTask: (taskRow) => void;
    deleteTask: (id) => void;
    isEidtable: boolean;
    cssClassname?: string;
    uiMode?: "table";
    secondaryRelatedTo?: string;
    secondaryRelatedToUID?: string;
    secondaryRelatedToType?: string;
    showRelatedTo?: boolean;
    showSecondaryRelatedTo?: boolean;
}

interface IState {
    taskKey: number;
}

function TaskActivityView({
    taskRow,
    ...props }: PropsWithChildren<IProps>) {
    const rolePermissionsContext = useContext(RolePermissionsContext);
    const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
        ? rolePermissionsContext.permissionValue
        : null;

    const indexVal: string = Guid.newGuid();
    let rafForm: FormRenderProps | null;

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            taskKey: Math.random(),
        }
    );

    const onSubmitTask = (value) => {
        let taskToCreate: TaskRow = value as TaskRow;
        let editDeletebtn = document.querySelector(
            "#editDeletebtn_" + taskRow.UID + "_" + indexVal
        );

        if (props.editTask) {
            props.editTask(taskToCreate);
            editDeletebtn.classList.add("hidden");
        }
        onCancelClicked();
    };


    const taskDropdownMenuClicked = (e: MenuEventArgs) => {
        if (e.item.id === "edit") {
            onEditClicked();
        }
        if (e.item.id === "delete") {
            onDeleteTaskClicked();
        }
    };

    const onDeleteTaskClicked = () => {
        if (!hasPermission(permissionValue, PermissionConstants.CommentsDelete)) {
            showWarningToast('Sorry, you do not have permission to perform this action.');
            return;
        }
        if (isNotNullAndUndefined(props.deleteTask)) {
            props.deleteTask(taskRow.UID);
        }
    };

    const onEditClicked = () => {
        if (!hasPermission(permissionValue, PermissionConstants.CommentsUpdate)) {
            showWarningToast('Sorry, you do not have permission to perform this action.');
            return;
        }
        let editDiv = document.querySelector(
            "#editableTask_" + taskRow.UID + "_" + indexVal
        );
        let summaryDiv = document.querySelector(
            "#summaryTask_" + taskRow.UID + "_" + indexVal
        );
        let editDeletebtn = document.querySelector(
            "#editDeletebtn_" + taskRow.UID + "_" + indexVal
        );
        let recordInfobtn = document.querySelector(
            "#recordInfoBtn_" + taskRow.UID + "_" + indexVal
        );
        if (isNotNullAndUndefined(recordInfobtn)) {
            if (recordInfobtn.classList.contains("hidden"))
                recordInfobtn.classList.remove("hidden");
            else recordInfobtn.classList.add("hidden");
        }
        if (isNotNullAndUndefined(editDiv)) {
            if (editDiv.classList.contains("hidden"))
                editDiv.classList.remove("hidden");
            else editDiv.classList.add("hidden");
        }
        if (isNotNullAndUndefined(summaryDiv)) {
            if (editDeletebtn.classList.contains("hidden"))
                summaryDiv.classList.remove("hidden");
            else summaryDiv.classList.add("hidden");
        }
        if (isNotNullAndUndefined(editDeletebtn)) {
            if (editDeletebtn.classList.contains("hidden"))
                editDeletebtn.classList.remove("hidden");
            else editDeletebtn.classList.add("hidden");
        }
    };

    const onCancelClicked = () => {
        let editDiv = document.querySelector(
            "#editableTask_" + taskRow.UID + "_" + indexVal
        );
        let summaryDiv = document.querySelector(
            "#summaryTask_" + taskRow.UID + "_" + indexVal
        );
        let editDeletebtn = document.querySelector(
            "#editDeletebtn_" + taskRow.UID + "_" + indexVal
        );
        let recordInfobtn = document.querySelector(
            "#recordInfoBtn_" + taskRow.UID + "_" + indexVal
        );
        if (isNotNullAndUndefined(editDiv)) {
            editDiv.classList.add("hidden");
        } else {
            editDiv.classList.remove("hidden");
        }
        if (isNotNullAndUndefined(recordInfobtn)) {
            recordInfobtn.classList.remove("hidden");
        }
        if (isNotNullAndUndefined(summaryDiv)) {
            summaryDiv.classList.remove("hidden");
        } else {
            summaryDiv.classList.add("hidden");
        }
        if (isNotNullAndUndefined(editDeletebtn)) {
            editDeletebtn.classList.remove("hidden");
        } else {
            editDeletebtn.classList.add("hidden");
        }
        setState({
            taskKey: Math.random(),
        });
    };

    const getModuleIcon = (moduleName: string) => {
        let iconName = "file-alt";
        let text = "Not set";
        let avatarClassName = "default-avatar";
        switch (moduleName) {
            case RAFEntityName.Task:
                iconName = "tasks";
                text = "Action";
                avatarClassName = "task-avatar";
                break;
            case RAFEntityName.BusinessProcessStep:
                iconName = "list-alt";
                text = "Process Step";
                avatarClassName = "default-avatar";
                break;
            default:
                break;
        }
        return (
            <div>
                <TooltipComponent content={text} position="BottomCenter">
                    <div className="d-flex align-items-center">
                        <span
                            className={
                                "avatar-text-circle transparent-avatar " + avatarClassName
                            }
                            style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                minWidth: "1.5rem",
                                minHeight: "1.5rem",
                                fontSize: "0.875em",
                                marginRight: "0.3125em",
                            }}
                        >
                            <i className={"fa fa-" + iconName}></i>
                        </span>
                    </div>
                </TooltipComponent>
            </div>
        );
    };

    if (
        isNotNullAndUndefined(props.uiMode) &&
        props.uiMode === "table"
    ) {
        return (
            <li
                key={taskRow.UID}
                className={
                    isNotNullAndUndefined(props.cssClassname)
                        ? `${props.cssClassname} activitiesItem flex-nowrap align-items-start px-0 pb-0`
                        : "activitiesItem flex-nowrap align-items-start px-0 pb-0"
                }
            >
                <div className="row gx-3">
                    <div className="col-auto">
                        <RAFIconImage
                            // value={
                            //   isNotNullAndUndefined(taskRow.UserName)
                            //     ? taskRow.UserName
                            //     : "Satheesh Kumar"
                            // }
                            userIcon
                            iconSize="30"
                            fontSize="12"
                            moduleavatar="activitiesItem__header__avatar e-avatar-small"
                        ></RAFIconImage>
                    </div>
                    <div className="col overflow-hidden">
                        <div className="row g-0">
                            <div className="col activitiesItem__header__col">
                                <span className="header-text-sm me-2">
                                    {isNotNullAndUndefined(taskRow.Assignee)
                                        ? taskRow.Assignee
                                        : "NA"}
                                </span>

                                <RAFRecordInfo
                                    createdBy={taskRow.CreatedBy}
                                    createdDate={taskRow.CreatedDate}
                                    modifiedDate={taskRow.ModifiedDate}
                                    modifiedBy={taskRow.ModifiedBy}
                                    lastActivityDate={taskRow.LastActivityDate}
                                    content=""
                                    hideClockIcon
                                    dropdownBtnClassName="unset-custom-button-md p-0 remove-minWidth"
                                ></RAFRecordInfo>
                            </div>
                            {props.isEidtable === true && (
                                <div
                                    className="col-auto activitiesItem__button__col"
                                    id={
                                        "editDeletebtn_" + taskRow.UID + "_" + indexVal
                                    }
                                >
                                    <DropDownButtonComponent
                                        cssClass="e-caret-hide custom-button-sm"
                                        iconCss={RAFButtonConstant.More.IconCss}
                                        select={(e) => {
                                            taskDropdownMenuClicked(e);
                                        }}
                                        items={[
                                            { id: "edit", text: "Edit", iconCss: "fal fa-pencil" },
                                            {
                                                id: "delete",
                                                text: "Delete",
                                                iconCss: "fas fa-trash",
                                            },
                                        ]}
                                    ></DropDownButtonComponent>
                                </div>
                            )}
                        </div>
                        <div>
                            {isNotNullAndUndefined(props.showSecondaryRelatedTo) &&
                                props.showSecondaryRelatedTo === true ? (
                                <div className="d-flex align-items-center flex-nowrap">
                                    <span className="d-inline-block">
                                        {getModuleIcon(props.secondaryRelatedToType)}
                                    </span>
                                    <span
                                        className="ecllipseFirstLine"
                                        style={{
                                            fontSize: "0.8125rem",
                                            lineHeight: "1.5625",
                                            cursor: "pointer",
                                            color: "#006DFF",
                                        }}
                                    >
                                        {props.secondaryRelatedTo}
                                    </span>
                                </div>
                            ) : null}
                        </div>
                        <div
                            className="secondary-header-text"
                            id={"summaryTask_" + taskRow.UID + "_" + indexVal}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html:
                                        taskRow !== null
                                            ? DOMPurify.sanitize(taskRow.Description)
                                            : undefined,
                                }}
                            ></span>
                        </div>
                        <div
                            id={"editableTask_" + taskRow.UID + "_" + indexVal}
                            className="hidden actionTextAreaDiv"
                        >
                            <RAFForm
                                key={state.taskKey}
                                type={TaskRow}
                                initialValues={taskRow}
                                submitOnEnterKey
                                onSubmit={onSubmitTask}
                                formRef={(g) => {
                                    return (rafForm = g);
                                }}
                            >
                                <div className="createActivity" id="actionTextAreaDiv">
                                    <RAFTextArea
                                        field={propertyOf<TaskRow>('Description')}
                                        showLabel={false}
                                        required
                                        placeholder="Ask a question or post an update. Use @ to mention the user"
                                        rows={5}
                                        useMentions
                                        mentionsField={propertyOf<TaskRow>('Mentions')}
                                        rowClassName="g-0"
                                    ></RAFTextArea>
                                </div>
                                <div className="d-flex justify-content-end align-items-center mt-2 mb-3">
                                    <RAFPermissionRender
                                        permissionName={PermissionConstants.CommentsUpdate}
                                    >
                                        <ButtonComponent
                                            type="button"
                                            cssClass="e-flat custom-button-md e-info me-2"
                                            onClick={() => {
                                                rafForm && rafForm.form.submit();
                                            }}
                                        >
                                            Save
                                        </ButtonComponent>
                                    </RAFPermissionRender>
                                    <ButtonComponent
                                        cssClass="e-flat custom-button-md"
                                        type="button"
                                        onClick={() => onCancelClicked()}
                                    >
                                        Cancel
                                    </ButtonComponent>
                                </div>
                            </RAFForm>
                        </div>
                    </div>
                </div>
            </li>
        );
    } else {
        return (
            <li
                key={taskRow.UID}
                className={
                    isNotNullAndUndefined(props.cssClassname)
                        ? `${props.cssClassname} activitiesItem flex-nowrap align-items-start`
                        : "activitiesItem flex-nowrap align-items-start"
                }
            >
                <div className="activitiesItem__header">
                    <div className="row g-2 flex-nowrap align-items-center">
                        <div className="col-auto">
                            <RAFIconImage
                                // value={
                                //   isNotNullAndUndefined(taskRow.UserName)
                                //     ? taskRow.UserName
                                //     : "Satheesh Kumar"
                                // }
                                userIcon
                                iconSize="30"
                                fontSize="12"
                                moduleavatar="activitiesItem__header__avatar e-avatar-xsmall"
                            ></RAFIconImage>
                        </div>
                        <div className="col">
                            <span className="task-title">
                                {isNotNullAndUndefined(taskRow.Assignee)
                                    ? taskRow.Assignee
                                    : "NA"}
                            </span>
                            <span className="activitiesItem__header__secondaryTitle">
                                {" "}
                                added a note
                            </span>
                        </div>
                        <div
                            className="col-auto"
                            id={"recordInfoBtn_" + taskRow.UID + "_" + indexVal}
                        >
                            <RAFRecordInfo
                                createdBy={taskRow.CreatedBy}
                                createdDate={taskRow.CreatedDate}
                                modifiedDate={taskRow.ModifiedDate}
                                modifiedBy={taskRow.ModifiedBy}
                                lastActivityDate={taskRow.LastActivityDate}
                                content=""
                                hideClockIcon
                                dropdownBtnClassName="unset-custom-button-md p-0 remove-minWidth"
                            ></RAFRecordInfo>
                        </div>
                        {props.isEidtable === true && (
                            <div
                                className="col-auto"
                                id={"editDeletebtn_" + taskRow.UID + "_" + indexVal}
                            >
                                <DropDownButtonComponent
                                    cssClass="e-dropdown-btnDefault custom-button-md userProfileName e-caret-hide e-icon-btn"
                                    iconCss=""
                                    select={(e) => {
                                        taskDropdownMenuClicked(e);
                                    }}
                                    items={[
                                        { id: "edit", text: "Edit", iconCss: "fal fa-pencil" },
                                        { id: "delete", text: "Delete", iconCss: "fas fa-trash" },
                                    ]}
                                >
                                    <div className="icon-ellipsis-sm icon-ellipsis-horizontal">
                                        <span className="icon-ellipsis-dot"></span>
                                    </div>
                                </DropDownButtonComponent>
                            </div>
                        )}
                    </div>
                </div>
                <div className="activitiesItem__content">
                    <div
                        id={"summaryTask_" + taskRow.UID + "_" + indexVal}
                        className="activitiesItem__content__text"
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html:
                                    taskRow !== null
                                        ? DOMPurify.sanitize(taskRow.Description)
                                        : undefined,
                            }}
                        ></span>
                    </div>
                    <div
                        id={"editableTask_" + taskRow.UID + "_" + indexVal}
                        className="hidden actionTextAreaDiv col-12"
                    >
                        <RAFForm
                            key={state.taskKey}
                            type={TaskRow}
                            initialValues={taskRow}
                            submitOnEnterKey
                            onSubmit={onSubmitTask}
                            formRef={(g) => {
                                return (rafForm = g);
                            }}
                        >
                            <div className="createActivity" id="actionTextAreaDiv">
                                <RAFTextArea
                                    field={propertyOf<TaskRow>('Description')}
                                    showLabel={false}
                                    required
                                    placeholder="Ask a question or post an update. Use @ to mention the user"
                                    rows={5}
                                    useMentions
                                    mentionsField={propertyOf<TaskRow>('Mentions')}
                                ></RAFTextArea>
                            </div>
                            <div className="d-flex justify-content-end align-items-center mt-2">
                                <RAFPermissionRender
                                    permissionName={PermissionConstants.CommentsUpdate}
                                >
                                    <ButtonComponent
                                        type="button"
                                        cssClass="e-flat custom-button-md e-info me-2"
                                        onClick={() => {
                                            rafForm && rafForm.form.submit();
                                        }}
                                    >
                                        Save
                                    </ButtonComponent>
                                </RAFPermissionRender>
                                <ButtonComponent
                                    cssClass="e-flat custom-button-md"
                                    type="button"
                                    onClick={() => onCancelClicked()}
                                >
                                    Cancel
                                </ButtonComponent>
                            </div>
                        </RAFForm>
                    </div>
                </div>
                <div className="d-none">
                    <RAFIconImage
                        // value={
                        //   isNotNullAndUndefined(taskRow.UserName)
                        //     ? taskRow.UserName
                        //     : "Satheesh Kumar"
                        // }
                        userIcon
                        iconSize="30"
                        fontSize="12"
                    ></RAFIconImage>
                </div>
                <div className="w-100 d-none">
                    <div className="activitiesContentDiv">
                        <div className="w-100">
                            <div className="row">
                                <div className="col d-flex align-items-center">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row flex-nowrap g-2">
                                                <div className="col-auto">
                                                    <span className="mx-1 task-title">
                                                        {isNotNullAndUndefined(taskRow.Assignee)
                                                            ? taskRow.Assignee
                                                            : "Na"}
                                                    </span>
                                                </div>
                                                <div className="col-auto">
                                                    <RAFRecordInfo
                                                        createdBy={taskRow.CreatedBy}
                                                        createdDate={taskRow.CreatedDate}
                                                        modifiedDate={taskRow.ModifiedDate}
                                                        modifiedBy={taskRow.ModifiedBy}
                                                        lastActivityDate={taskRow.LastActivityDate}
                                                        content=""
                                                        hideClockIcon
                                                        dropdownBtnClassName="unset-custom-button-md p-0 remove-minWidth"
                                                    ></RAFRecordInfo>
                                                </div>
                                            </div>
                                        </div>
                                        {isNotNullAndUndefined(
                                            taskRow.SecondaryRelatedToType
                                        ) && (
                                                <div className="d-flex align-items-center flex-nowrap">
                                                    <div className="col-12 d-flex align-items-center">
                                                        <span className="d-inline-block">
                                                            {getModuleIcon(
                                                                taskRow.SecondaryRelatedToType
                                                            )}
                                                        </span>
                                                        <span
                                                            className="ecllipseFirstLine"
                                                            style={{
                                                                fontSize: "0.8125rem",
                                                                lineHeight: "1.5625",
                                                                cursor: "pointer",
                                                                color: "#006DFF",
                                                            }}
                                                        >
                                                            {taskRow.SecondaryRelatedTo}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                {props.isEidtable === true && (
                                    <div
                                        className="col-auto"
                                        id={
                                            "editDeletebtn_" + taskRow.UID + "_" + indexVal
                                        }
                                    >
                                        <div className="d-flex justify-content-end mt-2">
                                            <DropDownButtonComponent
                                                cssClass="e-dropdown-btnDefault custom-button-md userProfileName e-caret-hide e-icon-btn"
                                                iconCss=""
                                                select={(e) => {
                                                    taskDropdownMenuClicked(e);
                                                }}
                                                items={[
                                                    {
                                                        id: "edit",
                                                        text: "Edit",
                                                        iconCss: "fal fa-pencil",
                                                    },
                                                    {
                                                        id: "delete",
                                                        text: "Delete",
                                                        iconCss: "fas fa-trash",
                                                    },
                                                ]}
                                            >
                                                <div className="icon-ellipsis-sm icon-ellipsis-horizontal">
                                                    <span className="icon-ellipsis-dot"></span>
                                                </div>
                                                {/*<span> {userName} </span>*/}
                                            </DropDownButtonComponent>
                                            <div className="d-none">
                                                <ButtonComponent
                                                    type="button"
                                                    iconCss="fal fa-pencil "
                                                    cssClass="custom-button-md e-outline d-md-none"
                                                    onClick={() => onEditClicked()}
                                                ></ButtonComponent>
                                                <ButtonComponent
                                                    type="button"
                                                    cssClass="custom-button-md e-outline d-none d-md-block"
                                                    onClick={() => onEditClicked()}
                                                >
                                                    Edit
                                                </ButtonComponent>
                                                <ButtonComponent
                                                    type="button"
                                                    iconCss="fas fa-trash"
                                                    cssClass="custom-button-md e-outline ms-2 d-md-none"
                                                    onClick={() =>
                                                        onDeleteTaskClicked()
                                                    }
                                                ></ButtonComponent>
                                                <ButtonComponent
                                                    type="button"
                                                    iconCss="fas fa-trash"
                                                    cssClass="custom-button-md e-outline ms-2 d-none d-md-block"
                                                    onClick={() =>
                                                        onDeleteTaskClicked()
                                                    }
                                                >
                                                    Delete
                                                </ButtonComponent>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className="task-footer"
                        id={"summaryTask_" + taskRow.UID + "_" + indexVal}
                    >
                        {/*<span className="task-date" id="acivityRecordInfo">
                                      {moment(taskDate).format("ddd, MMM Do YYYY, h:mm:ss a")}
                                  </span>*/}
                        <div className="task-secondaryValue ps-1 pt-1">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html:
                                        taskRow !== null
                                            ? DOMPurify.sanitize(taskRow.Description)
                                            : undefined,
                                }}
                            ></span>
                        </div>
                    </div>
                    <div
                        key={state.taskKey}
                        id={"editableTask_" + taskRow.UID + "_" + indexVal}
                        className="hidden actionTextAreaDiv col-12"
                    >
                        <RAFForm
                            type={TaskRow}
                            initialValues={taskRow}
                            submitOnEnterKey
                            onSubmit={onSubmitTask}
                            formRef={(g) => {
                                return (rafForm = g);
                            }}
                        >
                            <div className="d-flex flex-column w-100 mt-2">
                                <div className="d-flex flex-column createActivity">
                                    <div className="w-100" id="actionTextAreaDiv">
                                        <RAFTextArea
                                            field={propertyOf<TaskRow>('Description')}
                                            showLabel={false}
                                            required
                                            placeholder="Ask a question or post an update. Use @ to mention the user"
                                            rows={5}
                                            useMentions
                                            mentionsField={propertyOf<TaskRow>('Mentions')}
                                        ></RAFTextArea>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-start align-items-center mb-2">
                                    <RAFPermissionRender
                                        permissionName={PermissionConstants.CommentsUpdate}
                                    >
                                        <ButtonComponent
                                            type="button"
                                            cssClass="e-flat custom-button-md e-info me-2"
                                            onClick={() => {
                                                rafForm && rafForm.form.submit();
                                            }}
                                        >
                                            Save
                                        </ButtonComponent>
                                    </RAFPermissionRender>
                                    <ButtonComponent
                                        cssClass="e-flat custom-button-md me-2"
                                        type="button"
                                        onClick={() => onCancelClicked()}
                                    >
                                        Cancel
                                    </ButtonComponent>
                                </div>
                            </div>
                        </RAFForm>
                    </div>
                </div>
            </li>
        );
    }
}

export default React.memo(TaskActivityView);