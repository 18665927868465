import { DialogUtility } from "@syncfusion/ej2-popups";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";
import { msalInstance } from "../../../..";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import RAFChoiceOption from "../../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFForm, {
  Condition,
  WhenFieldChanges,
} from "../../../../RAFComponents/Inputs/RAFForm";
import RAFRadioButtonList from "../../../../RAFComponents/Inputs/RAFRadioButtonList";
import RAFTextArea from "../../../../RAFComponents/Inputs/RAFTextArea";
import {
  RAFFormContext,
  getFormValue,
} from "../../../../RAFComponents/Inputs/RFFUtils";
import { showWarningToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender, {
  hasPermission,
} from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  Guid,
  deepEqual,
  getSaveRequest,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import {
  ContentType,
  RAFActionStatus,
  RAFButtonConstant,
  RAFLayout,
  RAFTaskType,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../../constants/Common/PermissionConstants";
import { RAFActivityAction } from "../../Activity/ActivityHelper";
import {
  ActivityRow,
  RAFActivityCommentType,
} from "../../Activity/ActivityRow";
import { ServiceTransactionRow } from "../../ServiceTransaction/ServiceTransactionRow";
import { UserGroupRow } from "../../UserGroup/UserGroupRow";
import {
  completeTaskAndRemoveFromFocusList,
  saveTaskWithReminders,
} from "../TaskHelper";
import { TaskRow } from "../TaskRow";
import {
  RetrieveProcessLibraryById,
  RetrieveProcessRecordById,
  completeTaskAndUpdateProcess,
} from "../../../../RAFMaster/RMModules/ProcessLibrary/ProcessHelper";
import { CalculatedValue, Model } from "survey-core";
import { processStepType } from "../../../../RAFMaster/RMModules/FormLibrary/components/SurveyCreator/SurveyCreatorProcess";

interface IProps {
  myTeams: UserGroupRow[];
  initialFormValues: TaskRow;
  onSubmitForm: (newFormValues: TaskRow) => void;
  onCompleteTaskClick?: (taskRow: TaskRow, taskStatus: string) => void;
  onSave: () => void;
}

interface IState {
  showCompleteTaskAlertDialogContent: boolean;
}

function ManageTaskActivityStatusActionButton({
  initialFormValues,
  ...props
}: PropsWithChildren<IProps>) {
  let rafAlertForm: FormRenderProps | null;
  const moduleName: string = RAFEntityName.Task;
  const outerDivId = `Outer_Div_${moduleName}`;
  let updateTaskStatusAlertDialog: any;

  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showCompleteTaskAlertDialogContent: false,
    }
  );

  // update task status start
  const onChangeTaskStatus = async (actionStatus: string) => {
    if (actionStatus === RAFActionStatus.Planned) {
      showReopenTaskAlert(actionStatus);
    } else if (actionStatus === RAFActionStatus.Completed) {
      let hasPermissionToComplete = true;
      if (
        initialFormValues.SecondaryRelatedToType ===
        RAFEntityName.ServiceTransaction
      ) {
        let progressDiv = showProgress(`#${outerDivId}`);
        const serviceTransactionItem: ServiceTransactionRow =
          await RetrieveRecord(
            initialFormValues.SecondaryRelatedToUID,
            RAFEntityName.ServiceTransaction
          );
        hideProgress(progressDiv);
        if (isNotNullAndUndefined(serviceTransactionItem)) {
          if (
            serviceTransactionItem.Status === RAFActionStatus.Unassigned ||
            serviceTransactionItem.Status === RAFActionStatus.Planned ||
            serviceTransactionItem.Status === RAFActionStatus.Scheduled
          ) {
            showWarningToast(
              "The task cannot be completed until the shift has started."
            );
            hasPermissionToComplete = false;
          }
        }
      }

      if (hasPermissionToComplete) {
        showCompleteTaskAlert(RAFActionStatus.Completed);
      }
    }
  };

  const showReopenTaskAlert = (taskStatus: string) => {
    updateTaskStatusAlertDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: {
        text: "No",
      },
      closeOnEscape: false,
      content: "Are you sure you want to re-open this task?",
      okButton: {
        text: "Yes",
        click: reopenCurrentTask.bind(this, taskStatus),
      },
      showCloseIcon: false,
      title: "Re-open Task",
      position: { X: "center", Y: "center" },
      cssClass: "alert-dialog",
    });
  };

  const reopenCurrentTask = (taskStatus: string) => {
    updateTaskStatusAlertDialog && updateTaskStatusAlertDialog.hide();
    const taskFormRow: TaskRow = getFormValue(rafFormContextValue);
    taskFormRow.TaskStatus = taskStatus;
    taskFormRow.CompletedDate = null;
    taskFormRow.CompletedBy = null;
    taskFormRow.CompletedByUID = null;
    if (props.onSubmitForm) {
      props.onSubmitForm(taskFormRow);
    }
  };

  const showCompleteTaskAlert = (taskStatus: string) => {
    updateTaskStatusAlertDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: {
        text: "No",
      },
      closeOnEscape: false,
      content: "Are you sure you want to complete this task?",
      okButton: {
        text: "Yes",
        click: onClickCompleteTask.bind(this, taskStatus),
      },
      showCloseIcon: false,
      title: "Complete Task",
      position: { X: "center", Y: "center" },
      cssClass: "alert-dialog",
    });
  };

  const onClickCompleteTask = async (taskStatus: string) => {
    updateTaskStatusAlertDialog && updateTaskStatusAlertDialog.hide();

    const taskFormRow: TaskRow = getFormValue(rafFormContextValue);

    if (
      isNotNullAndUndefined(taskFormRow) &&
      isNotNullAndUndefined(taskFormRow.UID)
    ) {
      const isMyTeamExist =
        isNotNullAndUndefined(taskFormRow.AssignTeamUID) &&
        isNotEmptyArray(props.myTeams)
          ? props.myTeams.find((x) => x.UID === taskFormRow.AssignTeamUID)
          : null;
      if (
        (isNotNullAndUndefined(taskFormRow.AssigneeUID) &&
          taskFormRow.AssigneeUID === msalInstance.currentUserId) ||
        (isNotNullAndUndefined(taskFormRow.AssignTeamUID) &&
          isNotNullAndUndefined(isMyTeamExist))
      ) {
        saveFormAndCompleteTask(taskStatus);
      } else {
        showCompleteTaskAlertDialog();
      }
    }
  };

  const showCompleteTaskAlertDialog = () => {
    setState({ showCompleteTaskAlertDialogContent: true });
  };

  const onSubmitCompleteTask = (value) => {
    const taskFormRow: TaskRow = getFormValue(rafFormContextValue);
    if (
      isNotNullAndUndefined(value) &&
      value.Action === "Yes" &&
      isNotNullAndUndefined(taskFormRow)
    ) {
      let progressDiv = showProgress(
        `#completeTaskAlertDialogContent_${moduleName}`
      );

      let activityRow: ActivityRow = new ActivityRow();
      activityRow.UserUID = msalInstance.currentUserId;
      activityRow.UserName = msalInstance.currentUserName;

      activityRow.RelatedToType = taskFormRow.RelatedToType;
      activityRow.IsSystem = false;
      activityRow.RelatedToUID = isNotNullAndUndefined(taskFormRow.RelatedToUID)
        ? taskFormRow.RelatedToUID
        : "";
      activityRow.RelatedTo = isNotNullAndUndefined(taskFormRow.RelatedTo)
        ? taskFormRow.RelatedTo
        : "";

      activityRow.SecondaryRelatedTo = taskFormRow.Title;
      activityRow.SecondaryRelatedToType = RAFEntityName.Task;
      activityRow.SecondaryRelatedToUID = taskFormRow.UID;

      activityRow.Mentions = value.Mentions;
      activityRow.Message = value.Message;
      activityRow.CommentType = RAFActivityCommentType.Reason;
      activityRow.Action = RAFActivityAction.Added;

      repositoryActions
        .postDataAndGetResponse(
          "Activity/Save",
          //"Activity/SaveStream",
          getSaveRequest(activityRow, null),
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            saveFormAndCompleteTask(RAFActionStatus.Completed);
            setTimeout(() => {
              showCompleteTaskAlertDialogClose();
            }, 200);
          } else {
            showWarningToast("Sorry something went wrong !");
          }
        })
        .catch((error) => error);
    } else {
      showCompleteTaskAlertDialogClose();
    }
  };

  const saveFormAndCompleteTask = (taskStatus: string) => {
    const taskFormRow: TaskRow = getFormValue(rafFormContextValue);
    const isFormUpdated = !deepEqual(initialFormValues, taskFormRow);
    if (
      isNotNullAndUndefined(taskFormRow) &&
      isNotNullAndUndefined(taskFormRow.UID)
    ) {
      if (isFormUpdated) {
        let progressDiv = showProgress(`#${outerDivId}`);
        const response = saveTaskWithReminders(taskFormRow);
        hideProgress(progressDiv);
      }
      completeTaskAndRemoveFromFocusListAPI(taskFormRow, taskStatus);
    }
  };

  const completeTaskAndRemoveFromFocusListAPI = async (
    taskRow: TaskRow,
    taskStatus: string
  ) => {
    const submitValue: TaskRow = taskRow;
    submitValue.TaskStatus = taskStatus;
    if (isNotNullAndUndefined(props.onCompleteTaskClick)) {
      props.onCompleteTaskClick(submitValue, taskStatus);
    } else {
      let progressDiv = showProgress(`#${outerDivId}`);

      //check if task have step then update step status
      if (isNotNullAndUndefined(submitValue.BPStepUID)) {
        const response = await completeTaskAndRemoveFromFocusList(submitValue);
        const taskStepProcessResponse = await completeTaskAndUpdateProcess(
          submitValue
        );
        hideProgress(progressDiv);
        if (isNotNullAndUndefined(response)) {
          if (props.onSave) {
            props.onSave();
          }
        }
      } else {
        const response = await completeTaskAndRemoveFromFocusList(submitValue);
        hideProgress(progressDiv);
        if (isNotNullAndUndefined(response)) {
          if (props.onSave) {
            props.onSave();
          }
        }
      }
    }
  };

  const completeTaskAlertContent = () => {
    if (state.showCompleteTaskAlertDialogContent === true) {
      const taskFormRow: TaskRow = getFormValue(rafFormContextValue);
      const assignedTo = isNotNullAndUndefined(taskFormRow.Assignee)
        ? taskFormRow.Assignee
        : taskFormRow.AssignTeam;

      const completeFormInitialValue = {
        Action: "No",
        Message: null,
        Mentions: [],
      };
      return (
        <div className="px-2">
          <RAFForm
            formRef={(g) => {
              return (rafAlertForm = g);
            }}
            initialValues={completeFormInitialValue}
            submitOnEnterKey={false}
            layout={RAFLayout.TwoColumnLayout}
            onSubmit={onSubmitCompleteTask}
          >
            <div className="e-dlg-body-content">
              <div className="row">
                <div className="col-auto">
                  <label>
                    This task is assigned to {assignedTo}. Do you want to
                    complete this task?
                  </label>
                </div>
                <div className="col">
                  <RAFRadioButtonList
                    field="Action"
                    label="Complete ?"
                    showLabel={false}
                    labelClassName="col-auto"
                    inputFieldClassName="col radio-btn-m-0"
                    formGroupClassName="mb-0"
                    uitype="customButton"
                    radioBtnClassName="col-auto"
                  >
                    <RAFChoiceOption
                      label="Yes"
                      value={"Yes"}
                    ></RAFChoiceOption>
                    <RAFChoiceOption label="No" value={"No"}></RAFChoiceOption>
                  </RAFRadioButtonList>
                </div>
                <WhenFieldChanges field={"Action"} set={"Message"} to={null} />
                <WhenFieldChanges field={"Action"} set={"Mentions"} to={null} />
                <Condition when="Action" is="Yes">
                  <div className="w-100" id="actionTextAreaDiv">
                    <RAFTextArea<ActivityRow>
                      field="Message"
                      label="Add Reason"
                      required
                      placeholder="Add notes, comments, or @ to mention a person"
                      rows={5}
                      onInputs={(e) => {}}
                      onChanged={(e) => {}}
                      useMentions
                      mentionsField="Mentions"
                    ></RAFTextArea>
                  </div>
                </Condition>
              </div>
            </div>
            <div className="e-dlg-footerContent ">
              <ButtonComponent
                type="button"
                className="me-2"
                cssClass="e-flat e-info"
                onClick={() => rafAlertForm && rafAlertForm.form.submit()}
              >
                OK
              </ButtonComponent>
              <ButtonComponent
                type="button"
                cssClass="e-flat"
                onClick={() => showCompleteTaskAlertDialogClose()}
              >
                Cancel
              </ButtonComponent>
            </div>
          </RAFForm>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const showCompleteTaskAlertDialogClose = () => {
    setState({ showCompleteTaskAlertDialogContent: false });
  };
  //update task status end

  const getButtonContent = () => {
    if (
      isNotNullAndUndefined(initialFormValues.UID) &&
      initialFormValues.TaskStatus === RAFActionStatus.Planned
    ) {
      return (
        <div className="col-auto">
          <ButtonComponent
            type="button"
            className="btn_state_success semi_dark"
            onClick={() => onChangeTaskStatus(RAFActionStatus.Completed)}
            content={RAFButtonConstant.MarkAsDone.DisplayName}
          />
        </div>
      );
    } else if (
      isNotNullAndUndefined(initialFormValues.UID) &&
      initialFormValues.TaskStatus === RAFActionStatus.Completed &&
      initialFormValues.TaskType !== RAFTaskType.Note &&
      initialFormValues.TaskType !== RAFTaskType.Call
    ) {
      return (
        <div className="col-auto">
          <ButtonComponent
            type="button"
            className="btn_state_success semi_dark"
            onClick={() => onChangeTaskStatus(RAFActionStatus.Planned)}
            content={"Re-open"}
          />
        </div>
      );
    }
  };

  if (
    hasPermission(permissionValue, PermissionConstants.TaskManage) ||
    initialFormValues.AssigneeUID === msalInstance.currentUserId
  ) {
    return (
      <>
        <RAFPermissionRender permissionName={PermissionConstants.TaskComplete}>
          {getButtonContent()}
          {state.showCompleteTaskAlertDialogContent && (
            <DialogComponent
              id={`completeTaskAlertDialogContent_${moduleName}`}
              header="Complete Task"
              showCloseIcon
              visible={state.showCompleteTaskAlertDialogContent}
              cssClass="centerDialog-sm createEditForm full-height form-center-dialog"
              content={completeTaskAlertContent.bind(this)}
              isModal
              target="body"
              closeOnEscape={false}
              close={showCompleteTaskAlertDialogClose.bind(this)}
              zIndex={1200}
              open={PreventFocusOnDialogOpen}
            ></DialogComponent>
          )}
        </RAFPermissionRender>
      </>
    );
  } else {
    return null;
  }
}

export default React.memo(ManageTaskActivityStatusActionButton);
