import React, { PropsWithChildren, Reducer, useEffect, useReducer } from "react";
import { RAFEventName, subscribeRAFEvent, unsubscribeRAFEvent } from "../../../../../RAFComponents/helpers/RAFDocumentEvents";
import { isNotNullAndUndefined } from "../../../../../RAFComponents/helpers/utils";
import CreateTaskActivityNotes from "./CreateTaskActivityNotes";
import TaskActivitySummary from "./TaskActivitySummary";

interface IProps {
  relatedType?: string;
  relatedToUID?: string;
  relatedTo?: string;
  onChangeRelatedTask?: () => void;
  showEmptyState?: boolean;
  showEmptyStateIcon?: boolean;
  displayStyle?: 'ImageOnly' | 'TextOnly' | 'Default';

  parentRelatedToUID?: string;
  parentRelatedTo?: string;

  secondaryRelatedToUID?: string;
  secondaryRelatedTo?: string;
  secondaryRelatedToType?: string;
  taskItemClassname?: string;
  excludeRelatedFilter?: boolean;
  showSecondaryRelatedTo?: boolean;
  paddingClassName?: string;
  contentPaddingClassName?: string;
}

interface IState {
  taskNotesTaskSummaryKey?: number;

}

function TaskNotesActivitySummary({
  showEmptyState = true,
  showEmptyStateIcon = true,
  displayStyle = "Default",
  ...props }: PropsWithChildren<IProps>) {

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      taskNotesTaskSummaryKey: Math.random(),
    }
  );

  useEffect(() => {
    let eventID = isNotNullAndUndefined(props.parentRelatedToUID) ? props.parentRelatedToUID :
      isNotNullAndUndefined(props.secondaryRelatedToUID)
        ? props.secondaryRelatedToUID
        : props.relatedToUID;
    subscribeRAFEvent(
      `${RAFEventName.ReloadCommentsContent}_${eventID}`,
      reloadCommentsContent
    );
    return () => {
      unsubscribeRAFEvent(
        `${RAFEventName.ReloadCommentsContent}_${eventID}`,
        reloadCommentsContent
      );
    };
  }, []);

  function reloadCommentsContent(args) {
    let entity = isNotNullAndUndefined(props.secondaryRelatedToType)
      ? props.secondaryRelatedToType
      : props.relatedType;
    let objmoduleName = args.detail.moduleName;
    if (objmoduleName === entity) {
      setState({ taskNotesTaskSummaryKey: Math.random() });
    }
  }

  const relatedTaskUpdated = () => {
    if (props.onChangeRelatedTask) {
      props.onChangeRelatedTask();
    }
    setState({ taskNotesTaskSummaryKey: Math.random() });
  };

  const relatedCommentsUpdated = () => {
    if (props.onChangeRelatedTask) {
      props.onChangeRelatedTask();
    }
  };

  return (
    <div>
      <div className="row gy-2 gx-0">
        <div className='col-12'>
          <div className={`${isNotNullAndUndefined(props.paddingClassName) ? props.paddingClassName : "pt-3 px-3"}`} style={{ background: "#fff" }}>
            <CreateTaskActivityNotes
              onUpdate={() => {
                relatedTaskUpdated();
              }}
              relatedToUID={props.relatedToUID}
              relatedTo={props.relatedTo}
              relatedType={props.relatedType}
              parentRelatedToUID={props.parentRelatedToUID}
              parentRelatedTo={props.parentRelatedTo}
              secondaryRelatedToUID={props.secondaryRelatedToUID}
              secondaryRelatedTo={props.secondaryRelatedTo}
              secondaryRelatedToType={props.secondaryRelatedToType}
              isActive
            />
          </div>
        </div>
        <div className='col-12'>
          <div className={`${isNotNullAndUndefined(props.contentPaddingClassName) ? props.contentPaddingClassName : "px-3"}`}>
            <TaskActivitySummary
              key={state.taskNotesTaskSummaryKey}
              relatedCommentsUpdated={() => relatedCommentsUpdated()}
              relatedToUID={props.relatedToUID}
              relatedType={props.relatedType}
              showEmptyStateIcon={showEmptyStateIcon}
              showEmptyState={showEmptyState}
              displayStyle={displayStyle}
              parentRelatedToUID={props.parentRelatedToUID}
              parentRelatedTo={props.parentRelatedTo}
              secondaryRelatedToUID={props.secondaryRelatedToUID}
              secondaryRelatedTo={props.secondaryRelatedTo}
              secondaryRelatedToType={props.secondaryRelatedToType}
              taskItemClassname={props.taskItemClassname}
              excludeRelatedFilter={props.excludeRelatedFilter}
              showSecondaryRelatedTo={props.showSecondaryRelatedTo}
              uiMode="table"
            />
          </div>
        </div>
      </div>

    </div>
  );
}

export default React.memo(TaskNotesActivitySummary);